import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeNavSelected from '../../../../redux/actions/changeNavSelected'
import PageBanner from '../../../common/PageBanner'

import Accordion from '../../../common/Accordion'
import CraneAndRiggingSpecialist from './CraneAndRiggingSpecialist'
import DevelopingProcedures from './DevelopingProcedures'
import EngineeredLiftPlans from './EngineeredLiftPlans'
import OnSitePartyLiaison from './OnSitePartyLiaison'

const Consulting = props => {

    useEffect(() => {
        props.changeNavSelected(1)
    }, [])

    const panelsData = [{
        id: 'collapse01',
        title: 'Crane & Rigging Specialist',
        component: CraneAndRiggingSpecialist
    },
    /*{
        id: 'collapse02',
        title: 'Developing C&R Procedures',
        component: DevelopingProcedures
    },*/ {
        id: 'collapse03',
        title: 'Engineered Lift Plans',
        component: EngineeredLiftPlans
    }, {
        id: 'collapse04',
        title: 'On-site 3rd Party Liaison',
        component: OnSitePartyLiaison
    }]

    return (
        <div className='bg-white'>
            <PageBanner title='Consulting & SME Services' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
            <br></br>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h2 className="title">Consulting Services for Industry</h2>
                        <p class="m-b0">We can provide you with expert advice regarding all aspects of your industry production process and logistics operations. Our goal is to enable your firm by delivering tools to avoid problems and conflicts before they appear, and to solve the ones that show up as soon they can be tackled. If your industrial enterprise is in need of solutions, and you feel bogged down by problems in management or implementation, let us assist you with the struggles that can arise to reach a successful conclusion.</p>
                        <br></br>
                        <h2 className="title">Consulting Services for Construction Projects </h2>
                        <p class="m-b0">
                            We can provide you with expert advice regarding all aspects of your industry production process and logistics operations. Our goal is to enable your firm by delivering tools to avoid problems and conflicts before they appear, and to solve the ones that show up as soon they can be tackled. If your industrial enterprise is in need of solutions, and you feel bogged down by problems in management or implementation, let us assist you with the struggles that can arise to reach a successful conclusion.
                        </p>
                        <br></br>
                        <h2 className="title">Small to Medium Enterprise Services</h2>
                        <p class="m-b0">
                            Improving results and maximizing your investment’s returns is among every SME’s priorities. Our experience working with firms of all sizes, allowed us to understand the importance of obtaining the maximum from often scarce financial resources and infrastructure. We know this is key for the success of most small firm’s projects. We can provide your small business with highly qualified safety and risk assessment consulting, to prevent problems down the line.
                        </p>
                    </div>
                    <div className="col-lg-6">
                    <img alt='' src='images\capabilities\consulting\Consulting_SME_Services.jpg'></img>
                    </div>
                </div>
            </div>
            {/*
            <div class="bg-white tabs-box-area">
                <div class="section-full bg-white content-inner">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="section-content box-sort-in button-example p-t10 p-b30 tabs-site-button">
                                    <div class="dlab-tabs">
                                        <ul class="nav nav-tabs">
                                            <li><a data-toggle="tab" href="#web-design-1" class="active"><i class="ti-world"></i><span class="title-head"><h2>Consulting Services for Industry </h2></span></a></li>
                                            <li><a data-toggle="tab" href="#graphic-design-1"><i class="ti-image"></i> <span class="title-head"><h2> Consulting Services for Construction Projects </h2></span></a></li>
                                            <li><a data-toggle="tab" href="#developement-1"><i class="fa fa-cog"></i><span class="title-head"><h2>Small to Medium Enterprise Services </h2></span></a></li>
                                        </ul>
                                        <div class="tab-content">
                                            <div id="web-design-1" class="tab-pane active">
                                                <p class="m-b0">We can provide you with expert advice regarding all aspects of your industry production process and logistics operations. Our goal is to enable your firm by delivering tools to avoid problems and conflicts before they appear, and to solve the ones that show up as soon they can be tackled. If your industrial enterprise is in need of solutions, and you feel bogged down by problems in management or implementation, let us assist you with the struggles that can arise to reach a successful conclusion.</p>
                                            </div>
                                            <div id="graphic-design-1" class="tab-pane">
                                                <p class="m-b0">
                                                    We can provide you with expert advice regarding all aspects of your industry production process and logistics operations. Our goal is to enable your firm by delivering tools to avoid problems and conflicts before they appear, and to solve the ones that show up as soon they can be tackled. If your industrial enterprise is in need of solutions, and you feel bogged down by problems in management or implementation, let us assist you with the struggles that can arise to reach a successful conclusion.
                                                </p>
                                            </div>
                                            <div id="developement-1" class="tab-pane">
                                                <p class="m-b0">
                                                Improving results and maximizing your investment’s returns is among every SME’s priorities. Our experience working with firms of all sizes, allowed us to understand the importance of obtaining the maximum from often scarce financial resources and infrastructure. We know this is key for the success of most small firm’s projects. We can provide your small business with highly qualified safety and risk assessment consulting, to prevent problems down the line.</p>
                                            </div>
                                        </div>
                                    </div>
							    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <Accordion panelsData={ panelsData }></Accordion>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(Consulting)
