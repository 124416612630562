import React from 'react'
import PageBanner from '../../../common/PageBanner'

const EngineeredLiftPlans = () => {
    return (
        <div class="container max-w900">
            <div class="blog-post blog-single">
                <div class="dlab-post-text">
                    <img src="images\capabilities\consulting\engineered_lift_plans.jpg" class="alignleft" width="500"></img>
                    <p style={{ textAlign:'justify'}}>TECHSOURCE in-house engineering personnel can provide highly detailed, engineered lift plans as well as rigging diagrams, trucking diagrams, and other project plans. These plans are built with precision and accuracy. Our engineering department’s mission is to ensure the customer and our field personnel that we have planned for the safest and most efficient options available. We also offer the option for P.E. review and stamp of approval. Our engineering team utilizes professional standard software such as AutoCAD, Lift Planner, MatchCAD, LICCON Work Planner, Cranimation 3D, and 3D Liftplan for planning and drafting purposes.</p>
                    <p style={{ textAlign:'justify'}}>We also provide supporting documents with our lift plans such as rigging certs, rigging cut sheets, crane charts, soil reports, outrigger pad cut sheets, personnel certifications, and equipment annual inspections.</p>
                    <div className="row">
                        <div className=" col-md-6">
                            <h4>PLANNING PROCESS:</h4>
                            <ul class="list-circle primary m-a0">
                                <li>Project Overview with Customer</li>
                                <li>Site Walks & Measurements</li>
                                <li>Detailed Rigging Diagrams & Lift Plans</li>
                                <li>Ground Bearing Pressure Study</li>
                                <li>Max Allowable Wind Speed Study</li>
                                <li>P.E. Review & Stamp Available</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EngineeredLiftPlans