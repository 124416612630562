import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../redux/actions/changeIndexAccordion'
import ScrollTopFunction from '../common/ScrollTopFunction'
import Constants from '../common/Constants'

const UsefulLinks = props => {
    const { navs } = Constants()
    const nav = navs[props.indexMenu].items[props.indexSubMenu]
    let length = 0
    if(props.indexSubMenu === '0') {
        nav.items = nav.items.filter(item => !item.leftMenu)
    }

    const handlerClick = (key = 0) => {
        props.changeIndexAccordion(key)
        ScrollTopFunction()
    }

    return (
        <div className='col-md-3 col-5 col-xl-2 col-lg-2 col-sm-4 footer-col-4'>
            <div className='widget widget_services border-0'>
                <h5 className='m-b30 text-white'>{ nav.name.slice(0,nav.name.indexOf(' ', length)) }</h5>
                <ul> {
                    nav.items.map((menuOption,key) => {
                        if(menuOption.footerMenu) {
                            return(
                                <li key = { key }><Link to={ menuOption.path } onClick={ e => { e.stopPropagation(); handlerClick(menuOption.keyAccordion) }}>{ menuOption.name }</Link></li>
                            )
                        }
                        return ''
                    })
                }  </ul>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(UsefulLinks)