import React from 'react'
import Helmet from 'react-helmet'
import Constants from './Constants'

const Head = props => {
    const { tagsHead } = Constants()
    const metaTag = tagsHead.common.metaTag.concat(tagsHead[props.page || 'home'].metaTag);
    const socialMedia = tagsHead.common.socialMedia.concat(tagsHead[props.page || 'home'].socialMedia);

    return (
        <Helmet>
            <title>{ tagsHead.title + ' | ' + props.title }</title>
            {
                metaTag.map((tag, key) => {
                    return(<meta key={ key } name={ tag.name } content={ tag.content }/>)
                })
            } {
                socialMedia.map((tag, key) => {
                    return(<meta key={ key } property={ tag.property } content={ tag.content }/>)
                })
            }
        </Helmet>
    )
}

export default Head