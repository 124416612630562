import React from 'react'
import FormInfo from './FormInfo'
import ContactInfo from './ContactInfo'
import OurCompanies from '../../mainContent/OurCompanies'

const Contact = () => {

    return(
        <div style={ { paddingBottom: '50px' } }>
            <div className='section-full content-inner contact-page-9 overlay-black-dark' style={{ backgroundImage: 'url(images/contact-background.jpg)', backgroundPosition: '30% 100%' }}>
                <div className='container'>
                    <div className='row'>
                        <ContactInfo></ContactInfo>
                        <FormInfo></FormInfo>
                    </div>
                </div>
            </div>
            <OurCompanies></OurCompanies>
        </div>
    )
}

export default Contact