import React, { useEffect, useContext } from 'react'
import PageBanner from '../../../common/PageBanner'

const Refining = () => {

    return (
		<section>
			<PageBanner title='Refining Industry' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
			<div className='content-block'>
				<div className='section-full content-inner bg-white'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-6 m-b30'>

								<div className='col-lg-12 m-b30 mostrar-img-cell'>
									<img alt='' src='images\capabilities\fabrication\refining_industry_2.jpg'></img>
								</div>

								<p style={{ textAlign:'justify'}}>The need for high capacity refineries has ballooned in recent years as the oil and gas sector has experienced a massive boom in production. TECHSOURCE recognizes this need and is dedicated to supporting the refining industry with a full spectrum of industrial services.</p>	
                                <h6>Fabrication for the Refining Industry</h6>
                                <p style={{ textAlign:'justify'}}>One of the main ways we serve the refining industry is by providing high quality, turnkey fabrication of the products and equipment refineries need to operate. Our professional fabrication facilitates a quick and easy installation and minimizes downtime and disruption at the refinery.</p>		                                  
                                <p style={{ textAlign:'justify'}}>Modular and skid-based infrastructure can increase production and decrease typical timelines for refining processes. This translates into completing the same job with more output in less time. TECHSOURCE utilizes NCCER certified craftsmen and welders who are highly experienced and well trained, producing consistently high quality results.</p>
                                <h6>Maintenance, Upgrades, and Retrofits</h6>
                                <p style={{ textAlign:'justify'}}>TECHSOURCE also provides maintenance and service options for existing machinery and equipment. When a unit needs to be completely replaced or upgraded we are also up to the task. Our goal is to help our refining customers keep their facilities running smoothly and efficiently so that they can minimize downtime and maximize productivity.</p>

								<div className='col-lg-12 m-b30 mostrar-img-cell'>
									<img alt='' src='images\capabilities\fabrication\refining_industry_1.jpg'></img>
								</div>

								<h6>TECHSOURCE Turnaround Services</h6>
                                <p style={{ textAlign:'justify'}}>Turnarounds are crucial in the refining industry because they allow the opportunity to do major maintenance, upgrades, and system checks that simply aren’t possible while the refinery is operating. However, because turnarounds require a full shutdown of all production and output they completely interrupt the facility’s profitability. At the same time the need for new parts and equipment as well as the significant increase in labor and man hours creates an environment of swelling costs. For this reason a turnaround is typically the biggest factor impacting a refinery’s yearly maintenance budget.</p>
                                <p style={{ textAlign:'justify'}}>TECHSOURCE recognizes the need to make turnarounds as productive and cost efficient as possible. Our project management, scheduling, and planning services are designed to keep your turnaround on schedule and on budget. This is accomplished through a system of careful budgeting and cost management strategies as well as frequent, consistent communication of progress updates and any new developments that might occur. We set clear benchmarks and thorough plans at the outset of the project so that results can be easily and effectively evaluated. We also keep meticulous records and reports so that all new information can be incorporated into the next round of decision making.</p>

							</div>
							<div className='col-lg-6'>
								<div className='row'>
									<div className='col-lg-12 m-b30 ocultar-img-pc'>
										<img alt='' src='images\capabilities\fabrication\refining_industry_2.jpg'></img>
									</div>

									<div className='col-lg-12 m-b30 ocultar-img-pc'>
										<img alt='' src='images\capabilities\fabrication\refining_industry_1.jpg'></img>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}

export default Refining