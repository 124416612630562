import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeNavSelected from '../../../redux/actions/changeNavSelected'
import PageBanner from '../../common/PageBanner'
import OurCompanies from '../../mainContent/OurCompanies'

const Safety = props => {

    useEffect(() => {
        props.changeNavSelected(4)
    }, [])

    return (
        <section>
            <PageBanner title='Safety' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
            <div className='content-block bg-white' style={{ paddingBottom: '6%' }}>
                <div className='section-full content-inner bg-white'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-12'>
                                <div className='section-head text-black'>
                                  {/*  <h4 className='text-gray-dark font-weight-300 m-b10'>We are</h4>  */}
                                    <h2 className='title'>Our safety oriented practices and values</h2>
                                    <p>We know that a single failure on one machine may not end with a small cut on one worker´s hand, but in serious harm. We are fully aware of the huge responsibility that safety implies in an industrial setting, and that ensuring the proper and safe operation of machinery, leads to a better performance from part of workers.</p>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-12'>
                                <div className='col-lg-12 m-b30'>
                                    <img alt='' src='images/safety/Safety_Top.jpg'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section-full content-inner overlay-black-dark about-8-service bg-img-fix' style={{ backgroundImage: 'url(images/safety/Safety_Bottom.jpg)' }}>
                    <div className='container'>
                        <div className='section-head text-white text-center icon-bx-wraper'>
                            <div className='p-a25'>
                                <h2 className='title-box max-w800 m-auto'>Safety and Risk-Assessment Inspections<span className='bg-primary'></span></h2>
                                <p>To increase your company’s safety standards, we make a comprehensive analysis of your plant’s situation and opportunities for improvement. With that in mind, we may elaborate proposals and eventually move forward to implementation.</p>
                                <p>Inspecting the plant and meeting with operators is oftentimes unavoidable, if we need to maintain a consistently smooth and safe operation in our industrial facilities: warehouses, offices, silos, and so on. After identifying available avenues for improvement we make sure to correct any potential shortcomings.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section-full about-8-service bg-primary p-t40 p-b5'>
                    <div className='container'>
                        <div className='section-head text-white text-center icon-bx-wraper'>
                            <div className='p-a25'>
                                <h2 className='title-box max-w800 m-auto'>HR support: A safe and productive work environment<span className='bg-primary'></span></h2>
                                <p>We have three points that will help you develop your human resources, to empower them to take care of safety standards as a shared responsibility:
                                Managing the culture of your staff in relation to safety and quality standards.
                                Ensuring that a safety-friendly work environment develops by working on the HR selection and training process.
                                And keeping a close eye, by regular re-training and constant improvement policies.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <OurCompanies></OurCompanies>
            </div>
        </section>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(Safety)