import React from 'react'
import PageBanner from '../../../common/PageBanner'

const UndergroundConstruction = () => {
    return (
        <section>
            <PageBanner title='Underground Construction' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
        </section>
    )
}

export default UndergroundConstruction
