import React from 'react'
import PageBanner from '../../../common/PageBanner'

const BelowHookLiftingDevices = () => {

    return (
        <div className="container max-w900">
            <div className="blog-post blog-single">
                <div className="dlab-post-text">
                    <img src="images\capabilities\inspection\below_the_hook_lifting_devices.jpg" className="alignleft" width="300"></img>
                    <p className="justificar">The desire to build up—the very same pursuit that results in buildings ranging from multi-story structures to cloud-hugging obelisks—is tied to a desire to expand our known society, but, in a way, it defies the earth’s natural order. If you think about it, any process that pushes materials or components sky-bound must go against the 1 g pull of the earth. In addition to resisting any wind forces and enhancing safety and efficiency for personnel, construction devices and processes for lifting revolve around the means to resist sending the lifted object plummeting to the ground at the global acceleration of 9.8 m/s/s.  </p>
                    <p className="justificar">There’s a lot that construction processes and components need to grapple with, even including grapples. For the past century, the overarching ASME B30 standard has offered provisions for the construction, installation, operation, and maintenance of cranes and related lifting equipment. For convenience, B30 is split up into numerous volumes.</p>
                    <div className="row">
                    <h4>In addition to revising the scope, here are some of the changes made:</h4>
                        <ul className="list-circle primary m-a0">
                            <li>Definitions for abnormal operating conditions, maintenance parts, severe service, and special or infrequent service were added.</li>
                            <li>Revisions to product safety labeling were added throughout.</li>
                            <li>Rated load marking guidelines were added for remotely operated magnets. </li>
                            <li>Magnet testing guidelines were clarified. </li>
                            <li>The permanent magnet rating factor was revised.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        /*
        <div className="container max-w900">
            <div className="blog-post blog-single">
                <div className="dlab-post-text">
                    <div className="row">
                        <div className="col-md-5">
                            <img src="images\capabilities\inspection\below_the_hook_lifting_devices.jpg" class="alignleft" width="300"></img>
                        </div>

                        <div className="col-md-7">
                            <p className="justificar">The desire to build up—the very same pursuit that results in buildings ranging from multi-story structures to cloud-hugging obelisks—is tied to a desire to expand our known society, but, in a way, it defies the earth’s natural order. If you think about it, any process that pushes materials or components sky-bound must go against the 1 g pull of the earth. In addition to resisting any wind forces and enhancing safety and efficiency for personnel, construction devices and processes for lifting revolve around the means to resist sending the lifted object plummeting to the ground at the global acceleration of 9.8 m/s/s.  </p>
                            <p className="justificar">There’s a lot that construction processes and components need to grapple with, even including grapples. For the past century, the overarching ASME B30 standard has offered provisions for the construction, installation, operation, and maintenance of cranes and related lifting equipment. For convenience, B30 is split up into numerous volumes.</p>
                            <h4>In addition to revising the scope, here are some of the changes made:</h4>
                            <ul class="list-circle primary m-a0">
                                <li>Definitions for abnormal operating conditions, maintenance parts, severe service, and special or infrequent service were added.</li>
                                <li>Revisions to product safety labeling were added throughout.</li>
                                <li>Rated load marking guidelines were added for remotely operated magnets. </li>
                                <li>Magnet testing guidelines were clarified. </li>
                                <li>The permanent magnet rating factor was revised.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>*/
    )
}

export default BelowHookLiftingDevices