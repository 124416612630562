/*global jQuery */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import searchText from '../../redux/actions/searchText'
import { useHistory } from'react-router-dom'
import ScrollTopFunction from '../common/ScrollTopFunction'

const SearchPopUp = props => {
    const history = useHistory()
    const [ textSearh, setTextSearh  ] = useState('')

    const onChange = e => {
        setTextSearh(e.target.value)
    }

    const search = () => {
        props.searchText(textSearh)
        setTextSearh('')
        history.push('/search-results')
        jQuery('#quik-search-remove').trigger('click')
        ScrollTopFunction()
    }

    const handlerSubmit = e => {
        e.preventDefault();
        search()
    }

    return (
        <div className='dlab-quik-search'>
            <form onSubmit={ handlerSubmit } autoComplete='off'>
                <input name='search' value={ textSearh } onChange={ onChange } type='text' className='form-control' placeholder='Type to search'/>
                <span id='quik-search-remove'><i className='ti-close'></i></span>
            </form>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { searchText }

export default connect(mapStateToProps,mapDispatchToProps)(SearchPopUp)