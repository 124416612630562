import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeNavSelected from '../../../../redux/actions/changeNavSelected'
import PageBanner from '../../../common/PageBanner'

const InsulationAndSheetMetal = props => {

	useEffect(() => {
		props.changeNavSelected(1)
	}, [])

    return (
        <section>
			<PageBanner title='Industrial Insulation' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
			<div className='content-block'>
				<div className='section-full content-inner bg-white'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-6 m-b30'>

							<	div className='col-lg-12 m-b30 mostrar-img-cell'>
									<img alt='' src='images\capabilities\industrial\Industrial_Insulation_1.jpg'></img>
								</div>
								<h4>Protection from Hot or Cold</h4>
								<p className='justificar'>
									In order to protect your raw materials, machinery and workers from either hot or cold temperatures, TECHSOURCE has an array of high-level experts to take care of every one of your insulation needs. 
								</p>
								<p className='justificar'>
									Be it machinery insulation, refrigerated chambers, insulated piping or something else, you can rely on TECHSOURCE to solve your insulation problems and provide the level of security required to prevent any kind of malfunctions or potential injuries.
								</p>
                                {/* <div className='row'>
                                    <div className='col-md-5'>
                                        <ul className='list-star red list-box customPaddinglist'>
                                            <li>Refineries</li>
                                            <li>Natural gas plants</li>
                                            <li>Ethanol</li>
                                            <li>Chemical</li>
                                        </ul>
                                    </div>
                                    <div className='col-md-7'>
                                        <ul className='list-star red list-box customPaddinglist'>
                                            <li>Tank farms</li>
                                            <li>Pipe racks</li>
                                            <li>Pharmaceutical facilities</li>
                                            <li>Aircraft plants</li>
                                        </ul>
                                    </div>
                                </div> */}

								<div className='col-lg-12 m-b30 mostrar-img-cell'>
									<img alt='' src='images\capabilities\industrial\Industrial_Insulation_2.jpg'></img>
								</div>
								<h4>Vessel Insulation</h4>
								<p className='justificar'>
									We know that your facilities storage vessels may require insulation. Raw materials, intermediate products or even wholesale finished products, for example in the milk and dairy industry may require to be kept under strict temperature conditions to prevent losses. 
								</p>

								{/* <p className='justificar'>Low Temp Solutions include</p>
								<div className='col-md-7'>
									<ul className='list-star red list-box customPaddinglist'>
										<li>Glass Fiber</li>
										<li>Polyurethane Foam</li>
										<li>Elastomeric</li>
										<li>Open/Closed Cellular Insulation</li>
									</ul>
								</div> */}
								<h4>Heat Conservation and Energy Savings</h4>
								<p className='justificar'>
									Temperature control systems for vessels can be energy-expensive but with proper insulation, vessels can be made energy efficient, lowering costs and making the plant more environmentally friendly. 
								</p>
								<p className='justificar'>
									TECHSOURCE works very hard to improve our vessel insulation services everyday, making use of the latest technology and providing profitable and cost saving solutions to our clients.
								</p>

								<h4>Piping Insulation in Cold Climates</h4>
								<p className='justificar'>
									In cold climates, pipes freeze and can stop working. With fluid delivery halted, the whole line of production of a factory can be stopped in its tracks, or worse. Pipe freezing can also generate malfunctions in expensive machinery. Water cooled machinery and equipment may overheat with often costly consequences. 
								</p>
								<p className='justificar'>
									In TECHSOURCE we have deep knowledge about piping insulation that lets us prevent with a high degree of certainty any such occurrences. 
								</p>

								<h4>High Performance Insulation Materials</h4>
								<p className='justificar'>
									TECHSOURCE knows that for good insulation work, you will need high performance materials. This is the reason why we work very hard to be at the top of the market in what an upper technology machinery matters, and to be your first choice.
								</p>

								<h4>Equipment and Machinery Insulation</h4>
								<p className='justificar'>
									TECHSOURCE understands that equipment and machinery insulation drives performance. Better temperature control in work environments can even improve work safety and efficiency, Nevertheless, the most important element in machinery insulation is how it may reduce the energy consumption of your production process,
									impacting your bottom line drastically, with a low maintenance improvement that lowers costs from then on.
								</p>

								<h4>Operation, Management and Consulting for Industrial Insulation Projects</h4>

								<ul className='list-star red list-box' style={{ padding:'5px'}}>
									<li className="justificar">Construction: TECHSOURCE can build insulation projects from the ground up, according to your plans or assisting you with your insulation design.</li>
									<li className="justificar">Maintenance: Insulation is generally low maintenance, but it can also, from time to time, be a source of headaches when it fails. TECHSOURCE can provide your insulation with the proper maintenance to avoid these situations as well as to amend a faulty insulation. </li>
									<li className="justificar">Consulting: At the design and planning stage, TECHSOURCE can provide you with expert consulting advice to make the best use of insulation techniques and technology.</li> 
                    			</ul>

								{/* <p className='justificar'>Intermediate Temp Solutions include</p> */}
								{/* <div className='col-md-7'>
									<ul className='list-star red list-box customPaddinglist'>
										<li>Mineral Wool</li>
										<li>Glass Fiber</li>
										<li>Calcium Silicate</li>
										<li>Cellular Glass</li>
										<li>Polyisocyanurate</li>
										<li>Phenolic Foam</li>
										<li>Polystyrene</li>
										<li>Elastomeric Rubber</li>
									</ul>
								</div> */}



								<div className='col-lg-12 m-b30 mostrar-img-cell'>
									<img alt='' src='images\capabilities\industrial\industrialInsulation5.jpg'></img>
								</div>
							</div>
							<div className='col-lg-6'>
								<div className='row'>
									<div className='col-lg-12 m-b30 ocultar-img-pc'>
										<img alt='' src='images\capabilities\industrial\Industrial_Insulation_2.jpg'></img>
									</div>

									<div className='col-lg-12 m-b30 ocultar-img-pc'>
										<img alt='' src='images\capabilities\industrial\Industrial_Insulation_1.jpg'></img>
									</div>

									<div className='col-lg-12 m-b30 ocultar-img-pc'>
										<img alt='' src='images\capabilities\industrial\industrialInsulation5.jpg'></img>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(InsulationAndSheetMetal)