import React from 'react'
import PageBanner from '../../../common/PageBanner'

const MobileCranes = () => {

    return (
        <div className="container max-w900">
            <div className="blog-post blog-single">
                <div className="dlab-post-text">
                <h2>Why Hire a Mobile Crane?</h2>
                    <img src="images\capabilities\inspection\mobile_cranes.jpg" className="alignleft" width="300"></img>
                    <p style={{ textAlign:'justify'}}>Unlike tower cranes that take time to install and involve many procedures to operate, mobile cranes are rapidly and easily set up. Moreover, they are on wheels and can be easily displaced on the construction site to tend different lifting jobs.</p>
                    <p style={{ textAlign:'justify'}}>TECHSOURCE offers a 24/7 mobile crane hiring service within the eastern suburbs of Melbourne. The company also provides experienced operators to assist with the job at hand.</p>
                    <h4>Other Advantages of Mobile Cranes</h4>
                    <p style={{ textAlign:'justify'}}>Mobile cranes take up significantly less space than tower and crawler cranes. Mounted on wheels, they have multiple turning axles, making them the ideal option for construction sites with limited operating space. While smaller than other cranes, their advanced hydraulic systems enable them to lift heavy objects and materials to great heights.</p>
                    <p style={{ textAlign:'justify'}}>The management and staff of TECHSOURCE have over 30 years of industry experience. Whatever the project, we have the required expertise to complete it on time.</p>
                    <div className="row">
                        <div className=" col-md-6">
                            <h4>Why Choose Us?</h4>
                            <ul className="list-circle primary m-a0">
                                <li>Market leader with a high industrial profile</li>
                                <li>Able, happy and dedicated workforce</li>
                                <li>Always give top priority to safety</li>
                                <li>Honesty, integrity and professionalism</li>
                                <li>Caring and mindful of social and environmental factors</li>
                                <li>Total customer satisfaction</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
    )
}

export default MobileCranes