import React from 'react'
import Constants from '../common/Constants'
import ScrollTopFunction from '../common/ScrollTopFunction'
import { Link } from 'react-router-dom'

const Companies = () => {

    const { companies } = Constants()
    const appearFrom = ['fadeInLeft','fadeInDown','fadeInRight']

    return (
        <div className='section-full content-inner bg-white'>
            <div className='container'>
                <div className='section-head text-black text-center'>
					<h2 className='title'>Our Companies</h2>
				</div>
                <div className='row container'>
                    {
                        companies.map((item,key) => {
                            return(
                                <div key={ key } className={ 'col-lg-6 col-md-6 col-sm-12 m-b30 wow ' + appearFrom[key] }  data-wow-duration='2s' data-wow-delay='0.3s'>
                                    <div className='icon-bx-wraper bx-style-1 p-a30 center fly-box-ho'>
                                        <div className='icon-sm m-b20'>
                                            <a className='icon-cell text-primary'>
                                                <img src={ item.companyLogo } className='radius-sm' alt=''/>
                                            </a>
                                        </div>
                                        <div className='icon-content'>
                                            <h5 className='dlab-tilte text-uppercase'>{ item.name }</h5>
                                            <p>{ item.contentHome }</p>
                                            { key===2 ? <br/> : '' }
                                            <a href={item.buttonPath} className='site-button'>Read More</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Companies
