import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import FabricationContext from './FabricationContext'

const PipelineAndIndustrial = props => {
    let context = useContext(FabricationContext)

    useEffect(() => {
        props.changeIndexAccordion(0)
        context.setBanner({ ...context.banner, title: 'Fabrication Services' })
    }, [])

    return (
        <div className='row bg-white' >
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                    <img className='alignright' src='images\capabilities\fabrication\pipe_fabrication_1.jpg' className='radius-sm' alt=''></img>
                    </div>
					<div className='dlab-info m-t30 '>
                        {/* <h4 className='title'>Pipe Fabrication</h4> */}
                        <h4>Pipe Fabrication</h4>
                        <p style={{ textAlign:'justify'}}>
                            When it comes to pipe fabrication, TECHSOURCE employs high tech machinery and appropriately trained workers. This is the reason we offer you a broad range of personalized services that assist our clients in the production of almost all types of piping. We focus on satisfying your personal needs, and and positioning TECHSOURCE as the best company in the market.
                        </p>

                        <h4>Flexible Pipes</h4>
                        <p style={{ textAlign:'justify'}}>
                            TECHSOURCE offers you a perfect solution in terms of flexible pipes. We cover your needs to fabricate, transport and install your flexible pipes. When it comes to the safe and controlled delivery of small or large amounts of fluids through flexible pipes in industrial or construction settings, we offer a complete and dependable service.
                        </p>

                        <h4>Insulated Pipes</h4>
                        <p style={{ textAlign:'justify'}}>
                            TECHSOURCE has deep knowledge about the impact cold weather and below freezing temperatures may have on pipes. Extreme conditions can cause blockages, pressure buildups and ruptures in all kinds of piping, with a strong, very negative impact on the company’s bottom line and putting the safety of operators at risk. 
                        </p>
                        
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                    <img className='alignright' src='images\capabilities\fabrication\pipe_fabrication_2.jpg' className='radius-sm' alt=''></img>
                    </div>
					<div className='dlab-info m-t30 '>
                        <p style={{ textAlign:'justify'}}>
                            In TECHSOURCE, we can take care of any potential issues in relation to temperature control in pipes. We may conduct safety assessments and provide recommendations to prevent any kind of accident or malfunctions, without incurring in excessive cost. TECHSOURCE and also Implement modifications, insulate piping and install temperature control systems. 
                        </p>
                        <p style={{ textAlign:'justify'}}>
                            TECHSOURCE has worked very hard to provide one of the best pipe insulation services.
                        </p>

                        <h4>Pipe Installation</h4>
                        <p style={{ textAlign:'justify'}}>
                            TECHSOURCE experience in fabricating and installing pipes in industrial and construction settings is virtually unmatched. This vertical integration brings about an extra level of expertise in pipe installation that is handy when the need to personalize or adapt the installation process arises. 
                        </p>

                        <p className="justificar">
                            We will:
                        </p>

                        <ul className='list-star red list-box' style={{ padding:'5px'}}>
                            <li className="justificar">Listen carefully to our client’s needs and instructions.</li>
                            <li className="justificar">Provide a timely and precise pipe installation service.</li>
                            <li className="justificar">Deliver the materials to the construction or installation site in perfect condition and in a timely manner.</li>
                        </ul>  

                        {/* <h4>Our Pipiline & Industrial fabrication </h4> */}
                        {/* <p >TECHSOURCE is highly qualified to produce piping spools and assemblies for all grades of:</p> */}
                        {/* <ul className='list-star red list-box customPaddinglist'>
                            <li>Carbon steel</li>
                            <li>Stainless steel</li>
                            <li>Chrome-moly</li>
                            <li>Chrome alloys (Including p91)</li>
                            <li>High alloy</li>
                            <li>Nickel base alloy</li>
                            <li>Hastelloy</li>
                            <li>Low temperature</li>
                            <li>Duplex grade</li>
                            <li>Aluminum</li>
                            <li>And many other alloy materials</li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(PipelineAndIndustrial)