import React from 'react'
import TopBar from './TopBar'
import Logo from './Logo'
import MainNav from './MainNav'
import SearchPopUp from './SearchPopUp'
import ToggleButton from './ToggleButton'
import ExtraNav from './ExtraNav'
import ContactHeader from './ContactHeader'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <div className='site-header mo-left header header-curve ext-header dark'>
            <TopBar/>
            <div className='middle-bar bg-white'>
                <div className='container-fluid'>
                    <div className='middle-area'>
                        <Logo class='logo-header' src='images/LogoTSS1.png'/>
                        <ContactHeader/>
                        <Link to='/contact' className='site-button radius-no btnhover8 blackHover'>GET A QUOTE</Link>
                    </div>
                </div>
            </div>
            <div className='sticky-header main-bar-wraper navbar-expand-lg'>
                <div className='main-bar clearfix'>
                    <div className='container-fluid clearfix'>
                    <Logo class='logo-header mostion' classLogo='logo-mobile' src='images/LogoTSS1 Mobile.png'/>
                        <ToggleButton/>
                        <ExtraNav/>
                        <SearchPopUp/>
                        <MainNav/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header