import React , { useEffect } from 'react';
import { connect } from 'react-redux'
import changeNavSelected from '../../redux/actions/changeNavSelected'
import SliderMain from './SliderMain'
import Init from '../common/Init'
import CallAction from './CallAction'
import OurProjects from './OurProjects'
import OurServices from './OurServices'
import OurCompanies from './OurCompanies'

const MainContent = props => {

    useEffect(() => {
        props.changeNavSelected(0)
        Init.initRevSlider()
    }, [])

    return(
        <div className='page-content bg-white'>
            <SliderMain></SliderMain>
            <CallAction></CallAction>
            <OurProjects></OurProjects>
            <OurServices></OurServices>
            <OurCompanies></OurCompanies>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps, mapDispatchToProps)(MainContent)