import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import FabricationContext from './FabricationContext'

const Maintenance = props => {

    let context = useContext(FabricationContext)

    useEffect(() => {
        props.changeIndexAccordion(9)
        context.setBanner({ ...context.banner, title: 'Industrial Services' })
    }, [])

    return (
        <div className='row bg-white' >
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                        <img className='alignright' src='images\capabilities\fabrication\maintenance_1.jpg' className='radius-sm' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4 className='title'>Maintenance</h4>
                        <p style={{ textAlign:'justify'}}>Maintenance is one of the most fundamental aspects of any industrial plant or facility. It is what keeps the plant running smoothly and efficiently as well as what prevents dangerous or costly accidents from occurring. However, industrial and refinery maintenance can be a very challenging, expensive, and time-consuming undertaking for many companies. We provide efficient, thorough contract maintenance and repairs. We want to help facilitate with all maintenance needs as your service provider of choice.</p>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='dlab-box'>
                    <div className='dlab-media'>

                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4>Full Service</h4>
                        <p style={{ textAlign:'justify'}}>TECHSOURCE rovides full, comprehensive services for all of your maintenance needs. This integrated approach ensures that nothing is left out, missed, or neglected. It also allows us to develop a better understanding and familiarity with your facility that can be crucial for preventing accidents, fixing problems, and maximizing efficiency.</p>          
                        <h4>Single Point Of Contact</h4>
                        <p style={{ textAlign:'justify'}}>Clear communication is a crucial component to any successful project. Our ability to manage each phase of work providing benchmarks and a clear understanding of timeline makes operations run smoothly. This improves the workflow making the process more efficient and creates a better working relationship.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(Maintenance)