import React  from 'react'
import PageBanner from '../../../common/PageBanner'

const ForkliftsAndManlifts = () => {

    return (
            <div className="container max-w900">
                <div className="blog-post blog-single">
                    <div className="dlab-post-text">
                    <h2>Why Hire a Mobile Crane?</h2>
                        <img src="images\capabilities\inspection\forklifts_&_Manlifts.jpg" className="alignleft" width="300"></img>
                        <p style={{ textAlign:'justify'}}>TECHSOURCE is a company that started as a mobile access rental company, that helps its clients reach heights where scaffolding was infeasible, and for those places that’s too difficult to reach.</p>
                        <p style={{ textAlign:'justify'}}>The company has grown its fleet quite substantially over the past few years, catering for growing customer needs that vary from demand to height. After growing demand for both used and new mobile access platforms, TECHSOURCE now sells used and new access platforms.</p>
                        
                        <p style={{ textAlign:'justify'}}>Other services include the servicing, repairs and parts sales of mobile access platforms, Telehandlers and Skid Steers.</p>
                        
                    </div>
                </div>
            </div>
    )
}

export default ForkliftsAndManlifts