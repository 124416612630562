import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import FabricationContext from './FabricationContext'

const HeatExchangersServices = props => {

    let context = useContext(FabricationContext)

    useEffect(() => {
        props.changeIndexAccordion(10)
        context.setBanner({ ...context.banner, title: 'Industrial Services' })
    }, [])

    return (
        <div className='row bg-white' >
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                        <img className='alignright radius-sm' src='images\capabilities\fabrication\heat_exchanger_services_1.jpg' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '>                    
                        <h4 className='title'>Heat Exchangers in Industries</h4>
                        <p style={{ textAlign:'justify'}}>
                            Fluids like liquids, vapors and gases are useful for transferring heat through devices like heat exchangers. TECHSOURCE has heat exchangers of the ultímate technology. 
                            We listen carefully to our client’s needs, to know exactly the type of heat exchanger you may be needing: Liquid to liquid, shell and tube, gas to gas, double pipe, liquid to gas, plate exchangers, evaporators, boilers, condensers. 
                        </p>
                        <p style={{ textAlign:'justify'}}>
                            After the heat exchanger type and layout is defined we can proceed to the fabrication or customization of these vital industrial components for temperature control systems.
                        </p>
                        <p style={{ textAlign:'justify'}}>
                            Once the heat exchanger is installed, it might periodically require maintenance. TECHSOURCE can also provide this service, so you can count on your heat exchangers to function properly and reliably moving forward.
                        </p>

                        <h4 className='title'>Industrial Heat Exchanger Fabrication</h4>
                        <p style={{ textAlign:'justify'}}>
                            TECHSOURCE knows not only the workings but also the fundamental science underlying the thermodynamics of heat exchangers, this provides us with the knowledge required to customize and repurpose all kinds of heat exchangers, developing personalized solutions when needed.
                        </p>
                          
                                                                            
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                        <img className='alignright' src='images\capabilities\fabrication\HeatExchangers.jpg' className='radius-sm' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '> 
                    <p style={{ textAlign:'justify'}}>
                            Different kinds of heat exchangers may be employed depending on the intricacies of each project:
                        </p>
                        
                        <ul className='list-star red list-box' style={{ padding:'5px'}}>
                            <li className="justificar">Convection heat exchangers: Use heat to make a gas or liquid become less dense, The expanding, ascending hot liquid will collide with the cold one falling down.</li>
                            <li className="justificar">Conduction heat exchangers: Use the conduction of thermal energy between materials that are in close contact to reduce or increase temperature.</li>
                            <li className="justificar">Radiation heat exchangers: Cool a piece of equipment by radiating electromagnetic waves.</li> 
                        </ul>            
                                   
                        <h4>Industrial Heat Exchanger Maintenance</h4>
                        <p style={{ textAlign:'justify'}}>
                            Proper maintenance of industrial heat exchangers can save money, mostly by reducing energy consumption. Costs incurred due to downtime and repairs can also be reduced. TECHSOURCE knows that there are a very large number of heat exchangers, and also knows that most may requires a specific maintenance protocol.
                        </p>

                        <h4>Industrial Heat Exchanger Cleaning Service</h4>
                        <p style={{ textAlign:'justify'}}>
                            Cleaning of industrial heat exchangers is a subset of maintenance tasks that industrial establishments may outsource to TECHSOURCE. Our firm can provide our clients with technicians specifically trained in heat exchanger cleanup.
                        </p>

                        {/* <h4>The TECHSOURCE Advantage</h4>
                        <p style={{ textAlign:'justify'}}>We provide the initial construction and installation of the exchanger itself; however, we are also able to repair and retube as needed in addition to other maintenance services. TECHSOURCE is setting the bar for specialty exchanger services. Our goal is to provide you with industry leading service and solutions in the following areas:
                        </p>           */}
                        {/* <ul className='list-star red list-box customPaddinglist'>
                            <li>Complete Exchanger Re-Tube and Repair</li>
                            <li>TLX</li>
                            <li>Fin Fans</li>
                            <li>Condensers</li>
                            <li>Chillers</li>
                            <li>Ferrule Installation</li>
                            <li>Bundle Extracting</li>
                            <li>Bolt Torquing</li>
                            <li>Stud Tensioning</li>
                        </ul> */}
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(HeatExchangersServices)