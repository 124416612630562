import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import FabricationContext from './FabricationContext'

const CustomPressureVessels = props => {

    let context = useContext(FabricationContext)

    useEffect(() => {
        props.changeIndexAccordion(5)
        context.setBanner({ ...context.banner, title: 'Fabrication Services' })
    }, [])

    return (
        <div className='row bg-white'>
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                        <img className='alignright' src='images\capabilities\fabrication\custompressurevessels.jpg' className='radius-sm' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4 className='title'>Custom Pressure Vessels</h4>
                        <h6>Built to your exact specifications</h6>
                        <p style={{ textAlign:'justify'}}> 
                        Pressure Vessel fabrication, manufacture, and repair.  The TECHSOURCE offers comprehensive pressure vessel fabrication services to our customers. We manufacture to ASME Section VIII, Div. I, certified and holding ASME U,S and NBIC R stamps. The TECHSOURCE produce design, shop drawings and utilize advanced pressure vessel software for all vessel design and manufacturing.Our niche is vessels 8’ diameter or smaller, in all grades of carbon steel, stainless steel and many other alloy materials.                                </p>	
                        
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                        <img className='alignright' src='images\capabilities\fabrication\HeatExchangers.jpg' className='radius-sm' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '>
                    <p style={{ textAlign:'justify'}}>The ASME Boiler and Pressure Vessel Code is recognized world wide. However, we are not limited to only ASME but also have the capability to build vessels to the requirements of the US Coastguard, American Bureau of Shipping, Lloyd’s of London, Det Norske Veritas and other national and international jurisdictions.</p>		  
                        <h4>TECHSOURCE-Fabrication serves the following industries:</h4>
                        <ul className='list-star red list-box customPaddinglist'>
                            <li>Petrochemical</li>
                            <li>Chemical</li>
                            <li>Refining</li>
                            <li>Oil & Gas Production</li>
                            <li>Drilling</li>
                            <li>Energy</li>
                            <li>Power Generation</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(CustomPressureVessels)
