import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import FabricationContext from './FabricationContext'

const SpecialInsulation = props => {
    let context = useContext(FabricationContext)

    useEffect(() => {
        props.changeIndexAccordion(4)
        context.setBanner({ ...context.banner, title: 'Fabrication Services' })
    }, [])

    return (
        <div className='row bg-white' >
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                    <img className='alignright' src='images\capabilities\fabrication\SpecialInsulation.jpg' className='radius-sm' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4 className='title'>Special Insulation</h4>
                        <p style={{ textAlign:'justify'}}>TECHSOURCE insulation craft experts are skilled in industry wide applications of insulation materials and weatherproofing/metal jacketing methods for hot, cold and cryogenic applications.  We combine experience with insulation material science, proper engineering practices and only high quality materials to develop innovative solutions for customers. Our craftsmen are well known for their quality of work and our in-house apprenticeship program insures continued excellence.</p>	
                        <p style={{ textAlign:'justify'}}>TECHSOURCE has successfully executed major plant turnarounds. Our crews are cross trained to provide insulation, scaffold, and other specialty and general plant services allowing better project management, improve response time and efficiency, and greater flexibility from the plant perspective.</p>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='dlab-box'>
                    <div className='dlab-media'>

                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4>Insulation services include:</h4>
                        <ul className='list-star red list-box customPaddinglist'>
                            <li>Maintenance, outages, turnarounds, and new construction</li>
                            <li>Insulation of boilers, ducting, piping and equipment</li>
                            <li>Weatherproofing/cladding/jacketing</li>
                            <li>Hot, cold & cryogenic applications</li>
                            <li>Corrosion under insulation (CUI)</li>
                            <li>Removable reusable insulation covers/pads</li>
                            <li>Heat tracing (steam and electric)</li>
                            <li>Coatings</li>
                            <li>Aerogel flexible blankets</li>
                            <li>Furnace penetration seals</li>
                            <li>Shop & filed installations</li>
                            <li>Personnel protection</li>
                            <li>Sound attenuation and heat conservation systems</li>
                            <li>FDA approved systems for food manufacturing</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(SpecialInsulation)