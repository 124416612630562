/*global jQuery owlAnimateFilter */
const mapInit = {
	initRevSlider: () => {
		if(window.$("#rev_slider_11_1").revolution == undefined){
			window.revslider_showDoubleJqueryError("#rev_slider_11_1");
		}else{
			window.$("#rev_slider_11_1").show().revolution({
				ssliderType:"standard",
				sliderLayout:"fullscreen",
				dottedOverlay:"none",
				delay:90000,
				navigation: {
					keyboardNavigation:"off",
					keyboard_direction: "horizontal",
					mouseScrollNavigation:"off",
					mouseScrollReverse:"default",
					onHoverStop:"off",
					touch:{
						touchenabled:"on",
						swipe_threshold: 75,
						swipe_min_touches: 1,
						swipe_direction: "horizontal",
						drag_block_vertical: false
					},
					bullets: {
						enable:true,
						hide_onmobile:true,
						hide_under:960,
						style:"zeus",
						hide_onleave:false,
						direction:"horizontal",
						h_align:"right",
						v_align:"bottom",
						h_offset:80,
						v_offset:50,
						space:5,
						tmp:'<span class="tp-bullet-image"></span><span class="tp-bullet-imageoverlay"></span><span class="tp-bullet-title">{{title}}</span>'
					}
				},
				responsiveLevels:[1240,1240,778,778],
				visibilityLevels:[1240,1240,778,778],
				gridwidth:[1640,1640,778,778],
				gridheight:[768,768,960,960],
				lazyType:"none",
				parallax: {
					type:"mouse",
					origo:"enterpoint",
					speed:400,
					speedbg:0,
					speedls:0,
					levels:[2,4,6,8,10,12,14,16,45,50,47,48,49,50,0,50],
				},
				shadow:0,
				spinner:"off",
				stopLoop:"off",
				stopAfterLoops:-1,
				stopAtSlide:-1,
				shuffle:"off",
				autoHeight:"off",
				fullScreenOffsetContainer: ".header",
				disableProgressBar:"on",
				hideThumbsOnMobile:"off",
				hideSliderAtLimit:0,
				hideCaptionAtLimit:0,
				hideAllCaptionAtLilmit:0,
				debugMode:false,
				fallbacks: {
					simplifyAll:"off",
					nextSlideOnWindowFocus:"off",
					disableFocusListener:false,
				}
			});
		}
	},
	initCompaniesSlider: () => {
		var sync1 = window.$("#sync1");
		var sync2 = window.$("#sync2");
		var slidesPerPage = 2;
		var syncedSecondary = false;

		sync1.owlCarousel({
			items : 1,
			nav: true,
			autoplaySpeed: 3000,
			navSpeed: 3000,
			paginationSpeed: 3000,
			slideSpeed: 3000,
			smartSpeed: 3000,
			autoplay: 3000,
			dots: false,
			loop: true,
			responsiveRefreshRate : 200,
			navText: ['<i class="la la-angle-left"></i>', '<i class="la la-angle-right"></i>'],
		}).on('changed.owl.carousel', syncPosition);

		sync2.on('initialized.owl.carousel', function () {
			sync2.find(".owl-item").eq(0).addClass("current");
			}).owlCarousel({
			items : slidesPerPage,
			dots: false,
			nav: false,
			margin:20,
			autoplaySpeed: 3000,
			navSpeed: 3000,
			paginationSpeed: 3000,
			slideSpeed: 3000,
			smartSpeed: 3000,
			autoplay: 3000,
			slideBy: slidesPerPage,
			responsiveRefreshRate : 100,
			responsive:{
				0:{ items:2 },
				480:{ items:2 },
				768:{ items:3 },
				1024:{ items:4 },
				1400:{ items:4 }
			}
		}).on('changed.owl.carousel', syncPosition2);

		function syncPosition(el) {
			var count = el.item.count-1;
			var current = Math.round(el.item.index - (el.item.count/2)-1);
			console.log(current);
			if(current < 0) { current = count; }
			if(current > count) { current = 0; }

			sync2
				.find(".owl-item")
				.removeClass("current")
				.eq(current)
				.addClass("current");
			var onscreen = sync2.find('.owl-item.active').length - 1;
			var start = sync2.find('.owl-item.active').first().index();
			var end = sync2.find('.owl-item.active').last().index();

			var currencyImg = window.$('.owl-item.active.current .logo-company');
			if(currencyImg && currencyImg[0]){
				currencyImg[0].style.display = 'none'
			}
			if(currencyImg && currencyImg[1]){
				currencyImg[1].style.display = 'block'
			}

			var imgswhite = window.$('.owl-item.active .logo-company').not('.owl-item.active.current .logo-company');
			var totalImg = imgswhite.length
			imgswhite.map((index,elem) => {
				if(index < totalImg) {
					elem.style.display = (index % 2 !== 0) ? 'block' : 'none';
				}
			});

			if (current > end) {
				sync2.data('owl.carousel').to(current, 100, true);
			}
			if (current < start) {
				sync2.data('owl.carousel').to(current - onscreen, 100, true);
			}
		}

		function syncPosition2(el) {
			if(syncedSecondary) {
				var number = el.item.index;
				sync1.data('owl.carousel').to(number, 100, true);
			}
		}

		sync2.on("click", ".owl-item", function(e){
			e.preventDefault();
			var number = window.$(this).index();
			sync1.data('owl.carousel').to(number, 300, true);
		})
	},
	initGallery: () => {
		'use strict';
		if(jQuery('#masonry, .masonry').length)
		{
			var self = window.$("#masonry, .masonry");
			if(jQuery('.card-container').length)
			{
				self.imagesLoaded(function () {
					self.masonry({
						gutterWidth: 15,
						isAnimated: true,
						itemSelector: ".card-container"
					});
				});
			}
		}
		if(jQuery('.filters').length)
		{
			jQuery(".filters").on('click','li',function(e) {
				e.preventDefault();
				var filter = window.$(this).attr("data-filter");
				self.masonryFilter({
					filter: function () {
						if (!filter) return true;
						return window.$(this).hasClass(filter);
					}
				});
			});
		}
		var lightGallery = function (){
			if((window.$('#lightgallery, .lightgallery').length > 0)){
				window.$('#lightgallery, .lightgallery').lightGallery({
					selector : '.check-km',
					loop:true,
					thumbnail:true,
					exThumbImage: 'data-exthumbimage'
				});
			}
		}
		lightGallery()
	},
	initScrollTop: () => {
		'use strict';
		var scrollTop = jQuery("button.scroltop");
		/* page scroll top on click function */
		scrollTop.on('click',function() {
			jQuery("html, body").animate({
				scrollTop: 0
			}, 1000);
			return false;
		})

		jQuery(window).bind("scroll", function() {
			var scroll = jQuery(window).scrollTop();
			if (scroll > 900) {
				jQuery("button.scroltop").fadeIn(1000);
			} else {
				jQuery("button.scroltop").fadeOut(1000);
			}
		});
	}
}

export default mapInit
