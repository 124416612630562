import React from 'react'

const ToggleButton = () => {
    return (
        <button className='navbar-toggler collapsed navicon justify-content-end' type='button' data-toggle='collapse' data-target='#navbarNavDropdown' aria-controls='navbarNavDropdown' aria-expanded='false' aria-label='Toggle navigation'>
            <span></span>
            <span></span>
            <span></span>
        </button>
    )
}

export default ToggleButton
