const constants = () => {
  const constants = {};

  /* <!------- CONTACT INFORMATION ------> */
  constants.contactInformation = {
    email: "contact@techsource-group.com",
    phone: "(855) 822-4648",
    cel: "",
    address: " 201 Holly Springs Rd Holly Springs, NC 27540",
    addressPart1: "201 Holly Springs Rd ",
    addressPart2: "Holly Springs, NC 27540",
    schedule: "08:00 AM - 17:00 PM",
  };

  /* <!---------- NAVS - MENU - ROUTER ----------> */
  constants.navs = [
    {
      name: "Home",
      display: true,
      items: [],
      displayItems: false,
      path: "/",
      active: true,
    },{
      name: "About TechSource",
      display: false,
      topBar: true,
      items: [],
      displayItems: false,
      path: "/about",
      active: false,
    },{
      name: "Capabilities",
      display: true,
      items: [
        {
          name: "Industrial Services",
          display: true,
          isSubMenu: true,
          topBar: true,
          items: [
            {
              name: "Power Generation",
              display: true,
              items: [],
              footerMenu: true,
              displayItems: false,
              topBar: true,
              path: "/power-generation",
            },
            {
              name: "Underground Construction",
              display: false,
              items: [],
              footerMenu: true,
              displayItems: false,
              topBar: true,
              // path: '/underground-construction',
              path: "/companies",
              keyAccordion: 2,
            },
            {
              name: "Specialty Welding",
              display: true,
              items: [],
              footerMenu: true,
              displayItems: false,
              topBar: true,
              path: "/specialty-welding",
            },
            {
              name: "Industrial Insulation",
              display: true,
              items: [],
              footerMenu: true,
              displayItems: false,
              topBar: true,
              path: "/industrial-insulation",
            },
            {
              name: "Industrial Construction",
              display: true,
              items: [],
              displayItems: false,
              topBar: true,
              path: "/industrial-construction",
              keyAccordion: 0,
            },
            //left menu
            {
              name: "Maintenance",
              display: true,
              leftMenu: true,
              items: [],
              displayItems: false,
              path: "/maintenance",
              keyAccordion: 9,
            },
            {
              name: "Heat exchangers services",
              display: true,
              leftMenu: true,
              items: [],
              displayItems: false,
              path: "/heat-exchangers-services",
              keyAccordion: 10,
            },
            {
              name: "Tower & Vessels",
              display: true,
              leftMenu: true,
              items: [],
              displayItems: false,
              path: "/tower-And-Vessels",
              keyAccordion: 11,
            },
            {
              name: "Project Management",
              display: true,
              leftMenu: true,
              items: [],
              displayItems: false,
              path: "/project-management",
              keyAccordion: 12,
            },
            {
              name: "Boiler Services",
              display: true,
              leftMenu: true,
              items: [],
              displayItems: false,
              path: "/boiler-services",
              keyAccordion: 13,
            },
            {
              name: "Refining",
              display: true,
              leftMenu: false,
              items: [],
              displayItems: false,
              path: "/refining",
              keyAccordion: 14,
            },
          ],
          displayItems: true,
          path: "/industries",
        },
        {
          name: "Fabrication Services",
          display: true,
          isSubMenu: true,
          items: [
            {
              name: "Pipe Fabrication",
              display: true,
              items: [],
              footerMenu: true,
              displayItems: false,
              path: "/pipe-fabrication",
              keyAccordion: 0,
            },
            {
              name: "Mechanical",
              display: true,
              items: [],
              footerMenu: true,
              displayItems: false,
              path: "/mechanical",
              keyAccordion: 1,
            },
            // {
            //     name:'Strong Budgeting & Cost Monitoring',
            //     display: true,
            //     items: [],
            //     footerMenu: false,
            //     displayItems: false,
            //     path: '/strong-budgeting-and-costMonitoring',
            //     keyAccordion: 2
            // },
            {
              name: "Sheet Metal",
              display: true,
              items: [],
              footerMenu: true,
              displayItems: false,
              path: "/full-spectrum-of-sheet-metal-services",
              keyAccordion: 2,
            },
            {
              name: "Turnaround Planning & execution",
              display: true,
              items: [],
              footerMenu: true,
              displayItems: false,
              path: "/turnaround-planning-and-execution",
              keyAccordion: 3,
            },
            {
              name: "Special Insulation",
              display: true,
              items: [],
              displayItems: false,
              path: "/special-insulation",
              keyAccordion: 4,
            },
            {
              name: "Custom Pressure Vessels",
              display: true,
              items: [],
              displayItems: false,
              path: "/custom-pressure-vessels",
              keyAccordion: 5,
            },
            {
              name: "Structural Steel",
              display: true,
              items: [],
              displayItems: false,
              path: "/structural-steel",
              keyAccordion: 6,
            },
            {
              name: "Process Skid – Modular Fabrication",
              display: true,
              items: [],
              displayItems: false,
              path: "/process-skid–modular-fabrication",
              keyAccordion: 7,
            },
            {
              name: "Material Processing",
              display: true,
              items: [],
              displayItems: false,
              path: "/material-processing",
              keyAccordion: 8,
            },
          ],
          displayItems: true,
          path: "/fabrication",
        },
        {
          name: "Consulting & SME Services",
          display: true,
          isSubMenu: true,
          items: [
            {
              name: "Crane & Rigging Specialist",
              display: true,
              items: [],
              displayItems: false,
              path: "/consulting",
              keyAccordion: 0,
            },
            // {
            //     name:'Developing C&R Procedures',
            //     display: true,
            //     items: [],
            //     displayItems: false,
            //     path: '/consulting',
            //     keyAccordion: 1
            // },
            {
              name: "Engineered Lift Plans",
              display: true,
              items: [],
              displayItems: false,
              path: "/consulting",
              keyAccordion: 1,
            },
            {
              name: "On-site 3rd Party Liaison",
              display: true,
              items: [],
              displayItems: false,
              path: "/consulting",
              keyAccordion: 2,
            },
          ],
          displayItems: true,
          path: "/consulting",
        },
        {
          name: "Inspection & Testing",
          display: true,
          isSubMenu: true,
          items: [
            {
              name: "Mobile Cranes",
              display: true,
              items: [],
              displayItems: false,
              path: "/inspection-and-testing",
              keyAccordion: 0,
            },
            {
              name: "Overhead Cranes",
              display: true,
              items: [],
              displayItems: false,
              path: "/inspection-and-testing",
              keyAccordion: 1,
            },
            {
              name: "Pedestal Cranes",
              display: true,
              items: [],
              displayItems: false,
              path: "/inspection-and-testing",
              keyAccordion: 2,
            },
            {
              name: "Derrick Cranes",
              display: true,
              items: [],
              displayItems: false,
              path: "/inspection-and-testing",
              keyAccordion: 3,
            },
            {
              name: "Slings",
              display: true,
              items: [],
              displayItems: false,
              path: "/inspection-and-testing",
              keyAccordion: 4,
            },
            {
              name: "Rigging Hardware",
              display: true,
              items: [],
              displayItems: false,
              path: "/inspection-and-testing",
              keyAccordion: 5,
            },
            {
              name: "Fall Protection",
              display: true,
              items: [],
              displayItems: false,
              path: "/inspection-and-testing",
              keyAccordion: 6,
            },
            {
              name: "Below the Hook Lifting Devices",
              display: true,
              items: [],
              displayItems: false,
              path: "/inspection-and-testing",
              keyAccordion: 7,
            },
            {
              name: "Hoists & Come-a-longs",
              display: true,
              items: [],
              displayItems: false,
              path: "/inspection-and-testing",
              keyAccordion: 8,
            },
            {
              name: "Forklifts & Manlifts",
              display: true,
              items: [],
              displayItems: false,
              path: "/inspection-and-testing",
              keyAccordion: 9,
            },
          ],
          displayItems: true,
          path: "/inspection-and-testing",
          active: false,
        },
      ],
      displayItems: true,
      path: "/capabilities",
      active: false,
    },{
      name: "Projects",
      display: true,
      items: [],
      displayItems: false,
      path: "/projects",
      active: false,
    },{
      name: "DAS",
      display: true,
      topBar: true,
      items: [],
      displayItems: false,
      path: "/das",
      active: false,
    },{
      name: "Safety",
      display: true,
      items: [],
      displayItems: false,
      path: "/safety",
      active: false,
    },{
      name: "Training",
      display: true,
      items: [],
      displayItems: false,
      path: "/training",
      active: false,
    },{
      name: "Companies",
      display: false,
      topBar: true,
      items: [],
      displayItems: false,
      path: "/companies",
      active: false,
      keyAccordion: 0,
    },{
      name: "Contact",
      display: false,
      topBar: true,
      items: [],
      displayItems: false,
      path: "/contact",
      active: false,
    },
  ];

  constants.navIndustry = {
    name: "Industries",
    display: true,
    isSubMenu: true,
    topBar: true,
    items: [
      {
        name: "Power Generation",
        display: true,
        items: [],
        displayItems: false,
        topBar: true,
        path: "/power-generation",
      },
      {
        name: "Industrial Construction",
        display: true,
        items: [],
        displayItems: false,
        topBar: true,
        path: "/industrial-construction",
      },
      {
        name: "Insulation",
        display: true,
        items: [],
        displayItems: false,
        topBar: true,
        path: "/industrial-insulation",
      },
      {
        name: "Fabrication",
        display: true,
        isSubMenu: true,
        items: [],
        displayItems: false,
        topBar: true,
        path: "/fabrication",
      },
      {
        name: "Underground Construction",
        display: false,
        items: [],
        displayItems: false,
        topBar: true,
        // path: '/underground-construction'
        path: "/companies",
        keyAccordion: 1,
      },
      {
        name: "Refining Industry",
        display: true,
        leftMenu: true,
        items: [],
        displayItems: false,
        topBar: true,
        path: "/refining",
        keyAccordion: 14,
      },
    ],
    displayItems: true,
    path: "/industries",
  };

  /* <!-------  SLIDER  ------> */
  constants.sliders = [
    {
      index: "900",
      imgName: "video",
      typeSlide: "Slide Title",
      params: ["", "", "", "", "", "", "", "", "", ""],
      description: "",
      topTitle: "TechSource",
      title: "Get a quality",
      titlePart2: "service",
      subTitle: "Surpassing Expectations",
      type: "video",
      format: "mp4",
      display: true,
    },
    {
      index: "901",
      imgName: "slide01",
      typeSlide: "Slide",
      params: ["", "", "", "", "", "", "", "", "", ""],
      description: "",
      topTitle: "TechSource",
      title: "Industrial",
      titlePart2: "Services",
      subTitle: "Surpassing Expectations",
      type: "img",
      format: "jpg",
      display: true,
    },
    {
      index: "902",
      imgName: "slide02",
      typeSlide: "Slide",
      params: ["", "", "", "", "", "", "", "", "", ""],
      description: "",
      topTitle: "TechSource",
      title: "Safety-First",
      titlePart2: "Mentality",
      subTitle: "we are dedicated to perfection",
      type: "img",
      format: "jpg",
      display: true,
    },
    {
      index: "903",
      imgName: "slide03",
      typeSlide: "Slide",
      params: ["", "", "", "", "", "", "", "", "", ""],
      description: "",
      topTitle: "TechSource",
      title: "Get a quality",
      titlePart2: "service",
      subTitle: "Surpassing Expectations.",
      type: "img",
      format: "jpg",
      display: true,
    },
  ];

  /* <!------- CARROUSEL ------> */
  constants.carrouselProjects = [
    {
      title: "Power Generation",
      description: "Techsource Specialized Services - Industrial Services, provides high specification fabrication services for many power plant components like Boilers, Piping, Ducting and Turbines.",
      pathImg: "images/capabilities/industrial/electricPower.jpg",
      path: "/power-generation",
      icon: "flaticon-worker",
    },
    {
      title: "Pipe Fabrication",
      description: "This is one of our strengths. We produce more than six thousand pipe spools every month. We also fabricate more than ten carbon and alloy piping systems.",
      pathImg: "images/capabilities/fabrication/Pipeline.jpg",
      path: "/pipe-fabrication",
      icon: "flaticon-settings",
    },
    {
      title: "Industrial Insulation",
      description: "Our crew of craftsmen can perform over 25 special insulation activities to the highest specifications to ensure the excellence of all and each one of them.",
      pathImg: "images/capabilities/industrial/industrialInsulationHome.jpg",
      path: "/industrial-insulation",
      icon: "flaticon-engineer-1",
    },
    {
      title: "Tower & Vessels",
      description: "For towers and vessels we have 6 tipes of services, which we can adapt to your specific needs: Retiring entire towers, fabrication of vessels, general repairs, installation,  upgrades and modifications.",
      pathImg: "images/capabilities/fabrication/TowerAndVessel.jpg",
      path: "/tower-And-Vessels",
      icon: "flaticon-factory-1",
    },
    {
      title: "Heat exchangers services",
      description: "Without the proper installation and maintenance of heat exchangers that can manage the heating or cooling of industrial fluids and substances, the whole production process might drive to a halt.",
      pathImg: "images/capabilities/fabrication/HeatExchangers.jpg",
      path: "/heat-exchangers-services",
      icon: "flaticon-worker",
    },
    {
      title: "Specialty Welding",
      description: "Techsource provides premium specialty welding and field services. Our skilled welders have the metallurgical as well as field expertise it takes to weld on a wide range of materials.",
      pathImg: "images/capabilities/fabrication/SpecialtyWelding.jpg",
      path: "/specialty-welding",
      icon: "flaticon-settings",
    },
    {
      title: "Project Management",
      description: "To ensure a successful project, we focus on providing clear communication and strong leadership. In this way, we can confidently say that any possible problem or inconvenience will be either prevented.",
      pathImg: "images/capabilities/fabrication/ProjectManagement700x500.jpg",
      path: "/power-generation",
      icon: "flaticon-worker",
    },
    {
      title: "Boiler Services",
      description: "Techsource Boilers installation and maintenance services offer an all encompassing solution to any industrial installation incorporating boilers and associated piping.",
      pathImg: "images/capabilities/fabrication/Boilers700x500.jpg",
      path: "/boiler-services",
      icon: "flaticon-worker",
    },
    {
      title: "Maintenance",
      description: "To prevent accidents, maximize efficiency and fix problems with existing facilities we offer maintenance services with a clear communication system.",
      pathImg: "images/capabilities/fabrication/Maintenance700x500.jpg",
      path: "/maintenance",
      icon: "flaticon-worker",
    },
    {
      title: "Sheet Metal",
      description: "We have a full spectrum in sheet metal fabrication services, that include 9 types of different products. We specialize in carbon steel, stainless, aluminum, and other common alloy material fabrications.",
      pathImg: "images/capabilities/fabrication/SheelMetal700x500.jpg",
      path: "/full-spectrum-of-sheet-metal-services",
      icon: "flaticon-factory-1",
    },
    {
      title: "Material Processing",
      description: "Without a minimum order required, we provide material processing fabrication services on time and for much less than the average costs, because of our use of modernized state-of-the-art equipment.",
      pathImg: "images/capabilities/fabrication/MaterialProcessing700x500.jpg",
      path: "/material-processing",
      icon: "flaticon-worker",
    },
    {
      title: "Turnaround planning",
      description: "We know all the losses that can come because of a bad management of the capital or of a mistake respect at the times to make a closure, among others.",
      pathImg: "images/capabilities/fabrication/TurnaroundPlanningExecution700x500.jpg",
      path: "/turnaround-planning-and-execution",
      icon: "flaticon-settings",
    },
    {
      title: "DAS",
      description: "Whith over a decade of combined experience in providing technology-based solutions and exceeding our customer expectations. Our company stands alone by not only providing high value services but also quialify relationships.",
      pathImg: "images/capabilities/das/AntenaNetwork700x500.jpg",
      path: "/das",
      icon: "flaticon-internet",
    },
    {
      title: "Structured Cable Services",
      description: "Technological solutions such as a structured wiring system and communications construction are the foundation of a reliable IT infrastructure. An integral part of modern networking, a structured cabling system design helps to scale your business toward growth and development.",
      pathImg: "images/capabilities/das/StructuredCable700x500.jpg",
      path: "/das",
      icon: "flaticon-helmet",
    },
    {
      title: "Power Plant and Batery",
      description: "Techsource Specialized services team understands that the power for your network is crucial to your success. We are experts with deep experience in designing, deploying and supporting integrated power solutions that deliver optimal results.",
      pathImg: "images/capabilities/das/PowerPlant700x500.jpg",
      path: "/das",
      icon: "flaticon-voltmeter",
    }
  ];

  constants.socialNetworks = [
    {
      classNameButton: "facebook",
      classNameIcon: "fa fa-facebook",
      path: "https://www.facebook.com/Techsource-Specialized-Services-103157507928239",
      active: true,
    },
    {
      classNameButton: "instagram",
      classNameIcon: "fa fa-instagram",
      path: "https://www.instagram.com/",
      active: true,
    },
    {
      classNameButton: "google-plus",
      classNameIcon: "fa fa-google-plus",
      path: "",
      active: false,
    },
    {
      classNameButton: "linkedin",
      classNameIcon: "fa fa-linkedin",
      path: "",
      active: false,
    },
    {
      classNameButton: "twitter",
      classNameIcon: "fa fa-twitter",
      path: "",
      active: false,
    },
  ];

  constants.companies = [
    {
      icon: "flaticon-fuel-station",
      companyLogo: "images/companies-logos/logo TSS.png",
      companyLogoWhite: "images/companies-logos/logo TSS white.png",
      name: "TWS",
      topTitle: "TECHSOURCE",
      titlePart1: "Amazing things",
      titlePart2: "happen to your",
      titleColor: "Business",
      subTitle: "But high-quality companies aren´t just lucky, they make their own luck.",
      content: "Techsource is an industry committed to the service of precise projects; We are committed to taking our people home every night requiring this to be prepared and trained in world class security, leading us to be better every day, every project.",
      contentHome: "Techsource Workforce Solutions is a full service residential and commercial electrical contractor operating in North Carolina, Texas and Louisiana. TWS Electric brings you quality electrical work at reasonable prices.",
      buttonText: "Read More",
      buttonPath: "http://tsourcews.com",
      img: "images/about/techsource.jpg",
    },
    {
      icon: "flaticon-robot-arm",
      companyLogo: "images/companies-logos/logo nextgen.png",
      companyLogoWhite: "images/companies-logos/logo nextgen white.png",
      name: "NextGen",
      topTitle: "NextGen",
      titlePart1: "We are Industrial",
      titlePart2: "Communications & Construction",
      titleColor: "solutions",
      subTitle: "We aim to be the premier global provider of added-value optical fiber based solutions.",
      content: "As the excitement about fiber optic cables in residential and business applications continues to grow, it is easy to forget that this is not an entirely new development in communication technology.",
      contentHome: "NextGen Communications Construction, operating in Houston, Texas, we offer a wide variety of underground and aerial fiber optic services for communities all along the eastern seaboard.",
      buttonText: "Read More",
      buttonPath: "http://nextgenncc.com",
      img: "images/about/nextgen.jpg",
    }
  ];

  // ,
  //   {
  //     icon: "flaticon-factory-1",
  //     companyLogo: "images/companies-logos/logo galpa.png",
  //     companyLogoWhite: "images/companies-logos/logo galpa white.png",
  //     name: "Galpa",
  //     topTitle: "Galpa",
  //     titlePart1: "Do It Safe and Do ",
  //     titlePart2: "It Right the",
  //     titleColor: "First Time",
  //     subTitle: "We provide a wide range of marine repair and services.",
  //     content: "We offer a single vendor and contractor solution to all your industrial and marine needs – including on site construction, pipe and steel fabrication, marine repair, construction management, metal fabrication and modules – in the United States and around the world.",
  //     contentHome: "GALPA Construction LLC - Shipyard Services, provides a full range of shipyard services, including ship maintenance, repairs and refits for barges, tugboats, commercial marine.",
  //     buttonText: "Read More",
  //     buttonPath: "/",
  //     img: "images/about/galpa.jpg",
  //   },

  constants.Users = [
    {
      name: "user01",
      pass: "admin01",
    },
    {
      name: "user02",
      pass: "admin02",
    },
    {
      name: "user03",
      pass: "admin03",
    },
    {
      name: "user1",
      pass: "tss2020",
    },
  ];

  /* <!------- TAGS HEAD ------> */
  constants.tagsHead = {
    title: "Techsource",
    common: {
      metaTag: [
        {
          name: "robots",
          content: "robots.txt",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.",
        },
      ],
      socialMedia: [],
    },
    home: {
      metaTag: [
        {
          name: "description",
          content: "Techsource Specialized Services",
        },
        {
          name: "keywords",
          content: "Industrial & Commercial, Electrical Contracting, TECHHSOURCE, North Caroline, Houston",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Techsource Specialized Services",
        },
      ],
    },
    powerGeneration: {
      metaTag: [
        {
          name: "description",
          content: "Power Generation TECHSOURCE features multiple state-of-the-art fabrication facilities located across the United States",
        },
        {
          name: "keywords",
          content: "eléctric, generation, power, industry, Renewable Sources, Boilers, Turbines, Ducting, power, plants, services",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "TECHSOURCE features multiple state-of-the-art fabrication facilities located across the United States",
        },
      ],
    },
    refining: {
      metaTag: [
        {
          name: "description",
          content: "The need for high capacity refineries has ballooned in recent years as the oil and gas sector has experienced a massive boom in production",
        },
        {
          name: "keywords",
          content: "refineries, Industry, Fabrication, platform, machinery, equipment, services",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "The need for high capacity refineries has ballooned in recent years as the oil and gas sector has experienced a massive boom in production",
        },
      ],
    },
    industrialInsulation: {
      metaTag: [
        {
          name: "description",
          content: "TECHSOURCE is here to help your facility operate at maximum efficiency.",
        },
        {
          name: "keywords",
          content: "Industrial, Insulation, CUI, professionals, experience, capacity, Refineries, Chemical, Ethanol, problems",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "TECHSOURCE is here to help your facility operate at maximum efficiency",
        },
      ],
    },
    commercialConstruction: {
      metaTag: [
        {
          name: "description",
          content: "TECHSOURCE specialises in tilt-up panel construction for commercial buildings, such as industrial factories, warehouses, offices, schools, churches and workshops",
        },
        {
          name: "keywords",
          content: "Commercial, Construction, Company, specialises, warehouses, schools, workshops, completing, handpicked, complement, professionals, project",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "TECHSOURCE specialises in tilt-up panel construction for commercial buildings, such as industrial factories, warehouses, offices, schools, churches and workshops",
        },
      ],
    },
    industrialConstruction: {
      metaTag: [
        {
          name: "description",
          content: "As a provider of construction services to the heavy process industries, TECHSOURCE expert construction team understands how to reduce risk and deliver results",
        },
        {
          name: "keywords",
          content: "Industrial, Construction, services, Service, General, assignment, Management, lean, efficient, flexible, solution, project",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "As a provider of construction services to the heavy process industries, TECHSOURCE expert construction team understands how to reduce risk and deliver results",
        },
      ],
    },
    specialtyWelding: {
      metaTag: [
        {
          name: "description",
          content: "TECHSOURCE provides premium specialty welding and field services. Our highly skilled specialty welders have the metallurgical expertise and field experience it takes to weld on a full range of materials and industrial equipment",
        },
        {
          name: "keywords",
          content: "Specialty, Welding, scheduling, Range, quality, planning, project, management, Carbon, Titanium, Austenitic, Nickel, Alloys, Manuarite",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "TECHSOURCE provides premium specialty welding and field services. Our highly skilled specialty welders have the metallurgical expertise and field experience it takes to weld on a full range of materials and industrial equipment",
        },
      ],
    },
    pipeFabrication: {
      metaTag: [
        {
          name: "description",
          content: "Pipe Fabrication is one of our core capabilities. Fabricating carbon and alloy piping systems, we have the consistent ability to produce 6000+ pipe spools monthly",
        },
        {
          name: "keywords",
          content: "Pipe, Fabrication, carbon, alloy, projects, Stainless, Hastelloy, Aluminum, Duplex, grade, Low, temperature, Hastelloy",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Pipe Fabrication is one of our core capabilities. Fabricating carbon and alloy piping systems, we have the consistent ability to produce 6000+ pipe spools monthly",
        },
      ],
    },
    mechanical: {
      metaTag: [
        {
          name: "description",
          content: "TECHSOURCE are experts in the design and installation of all building and mechanical services including Heating, Plumbing, Ventilation, Air-Conditioning & Building Management Systems",
        },
        {
          name: "keywords",
          content: "Mechanical, installation, services, Heating, Plumbing, Ventilation, Air-Conditioning, Building, sectors, Pharmaceutical, Food, Processing, Medical, Offices, Retail, Leisure, Hotel",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "TECHSOURCE are experts in the design and installation of all building and mechanical services including Heating, Plumbing, Ventilation, Air-Conditioning & Building Management Systems",
        },
      ],
    },
    sheetMetalServices: {
      metaTag: [
        {
          name: "description",
          content: "TECHSOURCE offers Sheet Metal Design, Fabrication, Manufacturing, Construction , Assembly and Field Installation. We provide comprehensive Metal Working Solutions for all your custom sheet metal fabrication needs",
        },
        {
          name: "keywords",
          content: "Spectrum, Sheet, Metal, Services, Services, Design, Manufacturing, Construction, Fabrication, Aluminum, Belt-guards",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "TECHSOURCE offers Sheet Metal Design, Fabrication, Manufacturing, Construction , Assembly and Field Installation. We provide comprehensive Metal Working Solutions for all your custom sheet metal fabrication needs",
        },
      ],
    },
    turnaroundPlanningAndExecution: {
      metaTag: [
        {
          name: "description",
          content: "Delivery times are complex and difficult to manage, require large capital outlays and years of planning, and even a small failure can put operators at risk",
        },
        {
          name: "keywords",
          content: "Turnaround, Planning, Execution, Automate, productive, time, reduce, complexity, audit, collaboration, change",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Delivery times are complex and difficult to manage, require large capital outlays and years of planning, and even a small failure can put operators at risk",
        },
      ],
    },
    specialInsulation: {
      metaTag: [
        {
          name: "description",
          content: "TECHSOURCE insulation craft experts are skilled in industry wide applications of insulation materials and weatherproofing/metal jacketing methods for hot, cold and cryogenic applications",
        },
        {
          name: "keywords",
          content: "services, Maintenance, outages, turnarounds, new, construction, Insulation, boilers, ducting, piping, equipment, Weatherproofing, Hot, cold, Corrosion, Coatings, systems",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "TECHSOURCE insulation craft experts are skilled in industry wide applications of insulation materials and weatherproofing/metal jacketing methods for hot, cold and cryogenic applications",
        },
      ],
    },
    customPressureVessels: {
      metaTag: [
        {
          name: "description",
          content: "Pressure Vessel fabrication, manufacture, and repair. The TECHSOURCE offers comprehensive pressure vessel fabrication services to our customers",
        },
        {
          name: "keywords",
          content: "Custom, Pressure, Vessels, fabrication, manufacture, repair, serves, industries, Petrochemical, Chemical, Refining, Oil, Gas-Production, Drilling, Energy",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Pressure Vessel fabrication, manufacture, and repair. The TECHSOURCE offers comprehensive pressure vessel fabrication services to our customers",
        },
      ],
    },
    structuralSteel: {
      metaTag: [
        {
          name: "description",
          content: "TECHSOURCE provides comprehensive structural steel and plate fabrication services. From our early beginnings as a sheet metal fabricator, grew the largest segment of our business model",
        },
        {
          name: "keywords",
          content: "Structural, Steel, plate, fabrication, services, capabilities, fabrication, process, construction, manufacturing",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "TECHSOURCE provides comprehensive structural steel and plate fabrication services. From our early beginnings as a sheet metal fabricator, grew the largest segment of our business model",
        },
      ],
    },
    processSkidModularFabrication: {
      metaTag: [
        {
          name: "description",
          content: "TECHSOURCE can deliver turnkey services including design, engineering, modeling, fabrication, assembly, painting, insulation, heat tracing, electrical and instrumentation",
        },
        {
          name: "keywords",
          content: "Providing, Process, Equipment, Skid, Modular, Fabrication, Internationally, Petrochemica, Chemical, Refining, Drilling, Energy, project",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "TECHSOURCE can deliver turnkey services including design, engineering, modeling, fabrication, assembly, painting, insulation, heat tracing, electrical and instrumentation",
        },
      ],
    },
    materialProcessing: {
      metaTag: [
        {
          name: "description",
          content: "We provide complete contract manufacturing services, specializing in small to large run production work. Plasma cutting is often a cost -effective alternative for high volume production jobs",
        },
        {
          name: "keywords",
          content: "Material, Processin, contract, manufacturing, services, specializing, small, large, production, work, Plasma, cutting, workshop, services, plate burning, beveling, shearing, drilling, rolling, bending, forming",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "We provide complete contract manufacturing services, specializing in small to large run production work. Plasma cutting is often a cost -effective alternative for high volume production jobs",
        },
      ],
    },
    projectManagement: {
      metaTag: [
        {
          name: "description",
          content: "Industrial Project Management | TECHSOURCE GroupProject management is one of the most fundamental aspects of any major project in the petrochemical, refinery, and construction industries",
        },
        {
          name: "keywords",
          content: "Industrial, Project Management, GroupProject, petrochemical, refinery, construction, industries, scheduling, work, Logistics, Execution",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Industrial Project Management | TECHSOURCE GroupProject management is one of the most fundamental aspects of any major project in the petrochemical, refinery, and construction industries",
        },
      ],
    },
    towerAndVessels: {
      metaTag: [
        {
          name: "description",
          content: "Tower & Vessell TECHSOURCE of Southeast Texas Group provides industrial tower, column, vessel, and tray installation and repair services",
        },
        {
          name: "keywords",
          content: "Tower, Vessell, Group, industrial, column, vessel, installation, repair, services, Modifications, Upgrades",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Tower & Vessell TECHSOURCE of Southeast Texas Group provides industrial tower, column, vessel, and tray installation and repair services",
        },
      ],
    },
    heatExchangersServices: {
      metaTag: [
        {
          name: "description",
          content: "Heat Exchangers Services | TECHSOURCE provides a myriad of industrial exchanger services. Heat exchangers play a vital role in a wide array of industrial applications",
        },
        {
          name: "keywords",
          content: "responsible, heating, industrial, fluids, substances, equipment, service, areas, TLX, Fin Fans, Condensers, Chillers, Ferrule, Bundle, Bolt Torquing, Stud Tensioning",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Heat Exchangers Services | TECHSOURCE provides a myriad of industrial exchanger services. Heat exchangers play a vital role in a wide array of industrial applications",
        },
      ],
    },
    maintenance: {
      metaTag: [
        {
          name: "description",
          content: "Maintenance is one of the most fundamental aspects of any industrial plant or facility. It is what keeps the plant running smoothly and efficiently as well as what prevents dangerous or costly accidents from occurring",
        },
        {
          name: "keywords",
          content: "Maintenance, fundamental, aspects, industrial, plant, facility, needs, integrated, maximizing, efficiency, providing",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Maintenance is one of the most fundamental aspects of any industrial plant or facility. It is what keeps the plant running smoothly and efficiently as well as what prevents dangerous or costly accidents from occurring",
        },
      ],
    },
    boilerServices: {
      metaTag: [
        {
          name: "description",
          content: "Boiler ServicesWe are your total solutions general contractor for Industrial Boiler Repair, Maintenance and Emergency Services. TECHSOURCE is a boiler retrofitting, fabricating, installation, and shutdown specialist with 24/7 call out",
        },
        {
          name: "keywords",
          content: "Boiler, Services, Industrial, Repair, Maintenance, Emergency, Services, repair, Tube, replacements, Boiler-Heater, Headers",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Boiler ServicesWe are your total solutions general contractor for Industrial Boiler Repair, Maintenance and Emergency Services. TECHSOURCE is a boiler retrofitting, fabricating, installation, and shutdown specialist with 24/7 call out",
        },
      ],
    },
    consulting: {
      metaTag: [
        {
          name: "description",
          content: "We can provide you with expert advice regarding all aspects of your industry production process and logistics operations.",
        },
        {
          name: "keywords",
          content: "Consulting, industry, process, tools, modern, experienced, reinstatement, Deconstruction",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "We can provide you with expert advice regarding all aspects of your industry production process and logistics operations.",
        },
      ],
    },
    inspectionandtesting: {
      metaTag: [
        {
          name: "description",
          content: "To ensure the safety of your plant, preventing robberies, from intrusions, from the company’s own workers, customers or strangers, we can provide you with a list of security protocols and procedures that minimize the percentage of each and every kind of loss.",
        },
        {
          name: "keywords",
          content: "Inspection, Testing, Mobile, Security, Crane, procedures, service, operator, procedures, installation, rapidly, easily, industrial, customer, professionalism, security",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "To ensure the safety of your plant, preventing robberies, from intrusions, from the company’s own workers, customers or strangers, we can provide you with a list of security protocols and procedures that minimize the percentage of each and every kind of loss.",
        },
      ],
    },
    Safety: {
      metaTag: [
        {
          name: "description",
          content: "Safety Always TechSource is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees",
        },
        {
          name: "keywords",
          content: "Safety, Always, employees, customers, contractors, prevention, plan, execute, work, forefront",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Safety Always TechSource is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees",
        },
      ],
    },
    Training: {
      metaTag: [
        {
          name: "description",
          content: "Training We are fully committed to providing the resources it takes to ensure their safety and invest in our employees’ future, with the latest education and training available",
        },
        {
          name: "keywords",
          content: "people, core, success, employees, future, education , training, available, Crane, Operators, Forklift, Aerial, Lift, Preventive, Maintenance",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Training We are fully committed to providing the resources it takes to ensure their safety and invest in our employees’ future, with the latest education and training available",
        },
      ],
    },
    about: {
      metaTag: [
        {
          name: "description",
          content: "Industrial Process Plant Services | TECHSOURCE Group Services is your Total Solutions Provider for General Contracting, Maintenance, Construction, Mechanical, Piping and Capital Projects",
        },
        {
          name: "keywords",
          content: "industrial, installation, maintenance, machinery, services, power generation, specialty welding, industrial insulation, industrial construction, maintenance, fabrication, Pipes, sheet metal, turnaround planning and execution, Consulting",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Industrial Process Plant Services | TECHSOURCE Group Services is your Total Solutions Provider for General Contracting, Maintenance, Construction, Mechanical, Piping and Capital Projects",
        },
      ],
    },
    contact: {
      metaTag: [
        {
          name: "description",
          content: "Lets Convert Your Idea into Reality",
        },
        {
          name: "keywords",
          content: "Company, Address, first name, phone, industry, service, E-mail, Numbers, menssage, call",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Lets Convert Your Idea into Reality",
        },
      ],
    },
    search: {
      metaTag: [
        {
          name: "description",
          content: "",
        },
        {
          name: "keywords",
          content: "",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "",
        },
      ],
    },
    companies: {
      metaTag: [
        {
          name: "description",
          content: "Techsource is an industry committed to the service of precise projects; We are committed to taking our people home every night requiring this to be prepared and trained in world class security, leading us to be better every day, every project.",
        },
        {
          name: "keywords",
          content: "Companies, TWS, NextGen, industrial, construction, projects",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Techsource is an industry committed to the service of precise projects; We are committed to taking our people home every night requiring this to be prepared and trained in world class security, leading us to be better every day, every project.",
        },
      ],
    },
    industries: {
      metaTag: [
        {
          name: "description",
          content: "Power Generation  Specializing, Industrial Insulation company with the experience and expertise, Specialty Welding Our highly skilled, construction  specialises in tilt-up panel construction for commercial buildings such as industrial factories, Underground Construction As the excitement about fiber optic cables in residential and business applications continues to grow",
        },
        {
          name: "keywords",
          content: "Power Generation, Industrial Insulation, Specialty Welding, construction, Underground Construction,",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Power Generation  Specializing, Industrial Insulation company with the experience and expertise, Specialty Welding Our highly skilled, construction  specialises in tilt-up panel construction for commercial buildings such as industrial factories, Underground Construction As the excitement about fiber optic cables in residential and business applications continues to grow",
        },
      ],
    },
    projects: {
      metaTag: [
        {
          name: "description",
          content: "Industrial, Fabrication, Construction, Safety, Power Generation",
        },
        {
          name: "keywords",
          content: "Industrial, Fabrication, Construction, Safety, Power Generation",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Industrial, Fabrication, Construction, Safety, Power Generation",
        },
      ],
    },
    capabilities: {
      metaTag: [
        {
          name: "description",
          content: "Industrial Services, Fabrication Services, Consulting & SME Services, Inspection & Testing",
        },
        {
          name: "keywords",
          content: "Industrial, Services, Fabrication, Consulting, Inspection, Testing",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Industrial Services, Fabrication Services, Consulting & SME Services, Inspection & Testing",
        },
      ],
    },
    fabrication: {
      metaTag: [
        {
          name: "description",
          content: "TECHSOURCE provides a personalized outsourcing service for industrial fabrication, with an extensive range of options that will gladly adapt to the specific needs and desires of your company.",
        },
        {
          name: "keywords",
          content: "Fabrication, Outsourcing, services, Sheet Metal, personalized, expertise, machinery, specific needs",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "TECHSOURCE provides a personalized outsourcing service for industrial fabrication, with an extensive range of options that will gladly adapt to the specific needs and desires of your company.",
        },
      ],
    },
    recruiting: {
      metaTag: [
        {
          name: "description",
          content: "Industrial Process Plant Services | TECHSOURCE Group Services is your Total Solutions Provider for General Contracting, Maintenance, Construction, Mechanical, Piping and Capital Projects",
        },
        {
          name: "keywords",
          content: "industrial, installation, maintenance, machinery, services, power generation, specialty welding, industrial insulation, industrial construction, maintenance, fabrication, Pipes, sheet metal, turnaround planning and execution, Consulting",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Industrial Process Plant Services | TECHSOURCE Group Services is your Total Solutions Provider for General Contracting, Maintenance, Construction, Mechanical, Piping and Capital Projects",
        },
      ],
    },
    das: {
      metaTag: [
        {
          name: "description",
          content: "Industrial Process Plant Services | TECHSOURCE Group Services is your Total Solutions Provider for General Contracting, Maintenance, Construction, Mechanical, Piping and Capital Projects",
        },
        {
          name: "keywords",
          content: "industrial, installation, maintenance, machinery, services, power generation, specialty welding, industrial insulation, industrial construction, maintenance, fabrication, Pipes, sheet metal, turnaround planning and execution, Consulting",
        },
      ],
      socialMedia: [
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: "Techsource Specialized Services",
        },
        {
          property: "og:description",
          content: "Industrial Process Plant Services | TECHSOURCE Group Services is your Total Solutions Provider for General Contracting, Maintenance, Construction, Mechanical, Piping and Capital Projects",
        },
      ],
    },
  };

  return constants;
};

export default constants;
