import React from 'react'
import Constants from '../common/Constants'

const ExtraNav = () => {
    const { socialNetworks } = Constants()
    return (
        <div className='extra-nav'>
            <div className='extra-cell'>
                <ul className='list-inline'>
                <li className='buttonSearch'>
                    <button id='quik-search-btn' type='button' className='site-button-link'>
                        <i className='la la-search'></i>
                    </button>
                </li>
                {
                    socialNetworks.map((item,key) => {
                        if(item.active) {
                            return <li key={ key }><a href={ item.path } className={ 'site-button-link ' + item.classNameButton + ' hover' }><i className={ item.classNameIcon }></i></a></li>
                        }
                    })
                }
                </ul>
            </div>
        </div>
    )
}

export default ExtraNav