import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Constants from '../../common/Constants'
import changeIndexAccordion from '../../../redux/actions/changeIndexAccordion'
import PageBanner from '../../common/PageBanner'
import Init from '../../common/Init'

const TechSourceCompanies = props => {

	const { companies } = Constants()

	const { indexAccordion } = props.state

	useEffect(() => {
		Init.initCompaniesSlider()
		window.$("#sync1").data('owl.carousel').to(indexAccordion, 300, true)
	}, [indexAccordion])

	const getSlide = (slide, key) => {
		if(key % 2 !== 0) {
			return [divImg(slide,key), divSlideContent(slide,key + 10)]
		} else {
			return [divSlideContent(slide,key + 10), divImg(slide,key)]
		}
	}

	const divImg = (slide,key) => {
		return(
			<div key={ key } className='col-lg-6 col-md-6 m-b30'>
				<img src={ slide.img } className='radius-sm' alt=''/>
			</div>
		)
	}

	const divSlideContent = (slide,key) => {
		return(
			<div key={ key } className='col-lg-6 col-md-6'>
				<div className={ key % 2 !== 0 ? 'our-story p-r20' : 'our-story' }>
					<span>{ slide.topTitle }</span>
					<h2 className='title'>{ slide.titlePart1 }  <br/>{ slide.titlePart2 } <span className='text-primary'>{ slide.titleColor }</span></h2>
					<h4 className='title'>{ slide.subTitle }</h4>
					<p>{ slide.content }</p>
					<a href={slide.buttonPath} target="_blank" className='site-button btnhover16'>{ slide.buttonText }</a>
				</div>
			</div>
		)
	}

    return (
        <section>
            <PageBanner title='TECHSOURCE Companies' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
            <div className='section-full content-inner bg-white wow fadeIn' data-wow-duration='2s' data-wow-delay='0.4s'>
				<div className='container'>
					<div className='on-show-slider'>
						<div id='sync2' className='owl-carousel owl-theme owl-none owl-dots-none project-list'> {
							companies.map((slide,key) => {
								return(<div key={ key } className='item'>
									<div className='project-owbx'>	
										<img src={ slide.companyLogo } className='logo-company' alt='' style={{ display: key ? 'block' : 'none' }}/>
										<img src={ slide.companyLogo } className='logo-company white' alt='' style={{ display: !key ? 'block' : 'none' }}/>
										<h4 className='title'>{ slide.name }</h4>
									</div>
								</div>)
							})
						} </div>
						<div id='sync1' className='owl-carousel owl-theme owl-btn-center-lr m-b5 owl-dots-none owl-btn-3 primary'> {
							companies.map((slide,key) => {
								return(
									<div key={ key } className='item'>
										<div className='row align-items-center'> {
											getSlide(slide,key)
										} </div>
									</div>
								)
							})
						} </div>
					</div>
				</div>
			</div>
        </section>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(TechSourceCompanies)