import React from 'react'
import PageBanner from '../../../common/PageBanner'

const PedestalCranes = () => {

    return (
        <div class="container max-w900">
            <div class="blog-post blog-single">
                <div class="dlab-post-text">
                    <img src="images\capabilities\inspection\PedestalCranes.jpg" class="alignleft" width="300"></img>
                    <p style={{ textAlign:'justify'}}>TECHSOURCE delivers a wide range of Pedestal Mounted Cranes which can be used for various tasks including unloading of supply vessels, offshore installation work, pipe transfer, deck handling and subsea installation. TECHSOURCE ’s Pedestal Mounted Cranes are characterised by their small tail swing (optimising free deck space), and the fact that all drivers are installed inside the enclosed crane house. </p>
                    <p style={{ textAlign:'justify'}}>The TECHSOURCE Pedestal Mounted Crane comprises a steel crane house, bolted to the pedestal via the slew bearing, a lattice or box girder type boom and various hoist tackles to control the boom and the lower blocks. All main equipment - winches, electrical cabinets and/or hydraulic power units - is located inside the crane house to protect it from the harsh marine environment, to increase reliability and to reduce required maintenance. The boom hoist runs from the top of the crane house to the boom tip and controls the radius of the lower blocks. </p>
                    <div className="row">
                        <div className=" col-md-6">
                            <h4>Features</h4>
                            <ul class="list-circle primary m-a0">
                                <li>Lifting capacities up to 1,200mt</li>
                                <li>Protection of equipment inside the fully enclosed crane house;</li>
                                <li>Small tail swing</li>
                                <li>Special attention for fatigue in heavy duty applications</li>
                                <li>Ultra-deep water installation system</li>
                                <li>Active & passive heave compensation system</li>
                                <li>Full electro-hydraulically driven or full electrically driven</li>
                                <li>Two independent power supplies or an emergency supply (optional)</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PedestalCranes