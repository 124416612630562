import React from 'react'
import Slide from './Slide';
import Constants from '../common/Constants'

const SliderMainVideo = () => {
    let { sliders } = Constants()

    return (
        <div id='rev_slider_11_1_wrapper' className='rev_slider_wrapper fullwidthbanner-container' data-alias='home10' data-source='gallery' style={ { margin:'0px auto', background: 'transparent', padding:'0px', marginTop:'0px', marginBottom: '0px' } }>
            <div id='rev_slider_11_1' className='rev_slider fullwidthabanner' style={{ display: 'none' }} data-version='5.4.7'>
                <ul>
                    {
                        sliders.filter(item => item.display).map((slide,i) => {
                            return(
                                <li key={ i }
                                data-index={ 'rs-' + slide.index }
                                data-transition='fade'
                                data-slotamount='default'
                                data-hideafterloop='0'
                                data-hideslideonmobile='off'
                                data-easein='default'
                                data-easeout='default'
                                data-masterspeed='300'
                                data-rotate='0'
                                data-saveperformance='off'
                                data-title=''
                                data-muted='true'
                                data-paused='false'
                                data-param1={ slide.params[0] }
                                data-param2={ slide.params[1] }
                                data-param3={ slide.params[2] }
                                data-param4={ slide.params[3] }
                                data-param5={ slide.params[4] }
                                data-param6={ slide.params[5] }
                                data-param7={ slide.params[6] }
                                data-param8={ slide.params[7] }
                                data-param9={ slide.params[8] }
                                data-param10={ slide.params[9] }
                                data-description={ slide.params.description }>
                                    <img src={ 'images/slider/' + slide.imgName + '.jpg' } alt='' data-bgposition='center center'
                                    data-bgfit='cover' data-bgrepeat='no-repeat' data-bgparallax='9'
                                    className='rev-slidebg' data-no-retina/>
                                    <Slide slide={ slide } ></Slide>
                                </li>
                            )
                        })
                    }
                </ul>
                <div className='tp-bannertimer tp-bottom' style={{ visibility: 'hidden !important' }}></div>
            </div>
        </div>
    )
}

export default SliderMainVideo
