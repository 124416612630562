import React, { useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import changeNavSelected from '../../../../redux/actions/changeNavSelected'
import PageBanner from '../../../common/PageBanner'
import Head from '../../../common/Head'
import LeftMenu from '../../../common/LeftMenu'

import FabricationContext from './FabricationContext'
import PipelineAndIndustrial from './PipelineAndIndustrial'
import Mechanical from './Mechanical'
// import StrongBudgetingAndCostMonitoring from './StrongBudgetingAndCostMonitoring'
import FullSpectrumOfSheetMetalServices from './FullSpectrumOfSheetMetalServices'
import TurnaroundPlanningAndExecution from './TurnaroundPlanningAndExecution'
import SpecialInsulation from './SpecialInsulation'
import CustomPressureVessels from './CustomPressureVessels'
import StructuralSteel from './StructuralSteel'
import ProcessSkidModularFabrication from './ProcessSkidModularFabrication'
import MaterialProcessing from './MaterialProcessing'

import Maintenance from './Maintenance'
import HeatExchangersServices from './HeatExchangersServices'
import TowerAndVessels from './TowerAndVessels'
import ProjectManagement from './ProjectManagement'
import BoilerServices from './BoilerServices'

const Fabrication = props => {

    const { indexAccordion, menuOptions } = props.state
    const [ banner, setBanner ] = useState({ title: 'Fabrication Services', img: { name: 'commonBanner', type: 'jpg' }})

    useEffect(() => {
        props.changeNavSelected(1)
    }, [])

    useEffect(() => {
        let isDesktop = window.matchMedia("(min-width: 992px)").matches;
        if(!isDesktop) {
            window.$('html,body').animate({
                scrollTop: window.$('#contentFabrication').position().top + 100
            }, 500)
        }
    }, [indexAccordion])

    return (
        <FabricationContext.Provider value={{ banner, setBanner }}>
            <PageBanner title={ banner.title } img={ banner.img }></PageBanner>
            <div className='content-block bg-white'>
                <div className='section-full content-inner'>
                    <div className='container'>
                        <div className='row' style={{ paddingBottom: '8%' }}>
                            <LeftMenu menuOptions={ [ ...menuOptions[1].items[1].items, ...menuOptions[1].items[0].items.filter(item => item.leftMenu) ]  }></LeftMenu>
                            <div id='contentFabrication' className="col-xl-9 col-lg-8 col-md-7 m-b30">
                                <Switch>
                                    <Route exact path={['/pipe-fabrication','/fabrication']}>
                                        <Head title='Pipeline And Industrial' page='pipeFabrication'></Head>
                                        <PipelineAndIndustrial></PipelineAndIndustrial>
                                    </Route>

                                    <Route exact path='/mechanical'>
                                        <Head title='Mechanical' page='mechanical'></Head>
                                        <Mechanical></Mechanical>
                                    </Route>

                                    {/* <Route exact path='/strong-budgeting-and-costMonitoring'>
                                        <Head title='Strong Budgeting AndCost Monitoring' page='strongBudgetingAndCostMonitoring'></Head>
                                        <StrongBudgetingAndCostMonitoring></StrongBudgetingAndCostMonitoring>
                                    </Route> */}

                                    <Route exact path='/full-spectrum-of-sheet-metal-services'>
                                        <Head title='Full Spectrum of Sheet Metal services' page='sheetMetalServices'></Head>
                                        <FullSpectrumOfSheetMetalServices></FullSpectrumOfSheetMetalServices>
                                    </Route>

                                    <Route exact path='/turnaround-planning-and-execution'>
                                        <Head title='Turnaround Planning And Execution' page='turnaroundPlanningAndExecution'></Head>
                                        <TurnaroundPlanningAndExecution></TurnaroundPlanningAndExecution>
                                    </Route>

                                    <Route exact path='/special-insulation'>
                                        <Head title='Special Insulation' page='specialInsulation'></Head>
                                        <SpecialInsulation></SpecialInsulation>
                                    </Route>

                                    <Route exact path='/custom-pressure-vessels'>
                                        <Head title='Custom Pressure Vessels' page='customPressureVessels'></Head>
                                        <CustomPressureVessels></CustomPressureVessels>
                                    </Route>

                                    <Route exact path='/structural-steel'>
                                        <Head title='Structural Steel' page='structuralSteel'></Head>
                                        <StructuralSteel></StructuralSteel>
                                    </Route>

                                    <Route exact path='/process-skid–modular-fabrication'>
                                        <Head title='Process Skid Modular Fabrication' page='processSkidModularFabrication'></Head>
                                        <ProcessSkidModularFabrication></ProcessSkidModularFabrication>
                                    </Route>

                                    <Route exact path='/material-processing'>
                                        <Head title='Material Processing' page='materialProcessing'></Head>
                                        <MaterialProcessing></MaterialProcessing>
                                    </Route>

                                    {/* IDUSTRIAL SERVICES */}
                                    <Route exact path='/maintenance'>
                                        <Head title='Maintenance' page='maintenance'></Head>
                                        <Maintenance></Maintenance>
                                    </Route>

                                    <Route exact path='/heat-exchangers-services'>
                                        <Head title='Heat Exchangers Services' page='heatExchangersServices'></Head>
                                        <HeatExchangersServices></HeatExchangersServices>
                                    </Route>

                                    <Route exact path='/tower-And-Vessels'>
                                        <Head title='Tower An dVessels' page='towerAndVessels'></Head>
                                        <TowerAndVessels></TowerAndVessels>
                                    </Route>

                                    <Route exact path='/project-management'>
                                        <Head title='Project Management' page='projectManagement'></Head>
                                        <ProjectManagement></ProjectManagement>
                                    </Route>

                                    <Route exact path='/boiler-services'>
                                        <Head title='Boiler Services' page='boilerServices'></Head>
                                        <BoilerServices></BoilerServices>
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FabricationContext.Provider>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(Fabrication)



