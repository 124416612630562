import React, { useEffect } from 'react'
import PageBanner from '../../../common/PageBanner'
import { connect } from 'react-redux'
import changeNavSelected from '../../../../redux/actions/changeNavSelected'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
// import { Link } from 'react-router-dom'

const Services = props => {

	// const { menuOptions } = props.state

	useEffect(() => {
        props.changeNavSelected(1)
    }, [])

	// const handler = (indexAccordion) => {
	// 	props.changeIndexAccordion(indexAccordion)
	// 	ScrollTopFunction()
    // }

    return (
		<section>
			<PageBanner title='Fabrication Services' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
			<div className="content-block">
				<div className="section-full content-inner bg-white">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 m-b30">
							<h4 className="text-black font-weight-600 m-b15">Outsourcing Fabrication</h4>
							<p className="justificar">
								TECHSOURCE provides a personalized outsourcing service for industrial fabrication, with an extensive
								range of options that will gladly adapt to the specific needs and desires of your company.
							</p>

							<h4 className="text-black font-weight-600 m-b15">Aluminium Fabrication Services</h4>
								<p className="justificar">
									TECHSOURCE can provide the expertise of the most qualified and accomplished aluminium fabrication
									machinery operators in the market. Be it shaped, rolled, drawn, extruded, welded, it doesn’t matter. All
									ways in which aluminium can be molded are covered.
								</p>
								<p className="justificar">
									In relation to materials, we provide fabrication services employing most of its alloys (1100, 2011,
									2024, 3003, 5052, 6061, 6063, 7075, and others). Aluminium is widely considered an eco-friendly,
									recyclable material. Its light density when compared to other metals, makes it suitable for many
									applications. Surface treatments can provide corrosion, as well as heat and humidity resistance. Most
									alloys used in fabrication are reusable and can be integrated into a no-waste production cycle.
								</p>

							<h4 className="text-black font-weight-600 m-b15">Sheet Metal Fabrication Services</h4>
								<p className="justificar">
								TECHSOURCE provides you with different kinds of sheet metal fabrication services (bending, cutting,
								stamping, punching, piercing, blanking, shearing) to satisfy your needs while providing top quality
								customer service.
								</p>
								<p className="justificar">
								Our goal is to be the best sheet metal fabrication providers we can. That's why we strive everyday to improve our 
								equipment and to develop our workforce skills and competence, adjusting to the requirements of the market.
								</p>

							<h4 className="text-black font-weight-600 m-b15">Stainless Steel Fabrication Services</h4>
								<p className="justificar">
									TECHSOURCE listens carefully to all your requirements, needs and demands when it comes to stainless steel fabrication services. 
									In this way, using CAM and CAD technology, our engineers produce different prototypes and let you choose the one that is best adjusted to your needs for its construction. 
								</p>
								<p className="justificar">
								In addition to this fabrication services, we provide custom spun metal fabrication services, metal hydroforming, CNC turning and milling, metal polishing, and custom welding. 
								</p>

                                {/* <h5 className="text-black font-weight-600 m-b15">Service Areas and Related Links</h5>
								<ul className='list-star red list-box customPaddinglist'> {
									menuOptions[1].items[1].items.map((nav,key) => {
										return(
											<li key={ key } onClick={ () => { handler(nav.keyAccordion) } }>
												<Link to={ nav.path } style={{ color: 'black' }}>{ nav.name }</Link>
											</li>
										)
									})
								} </ul> */}
							</div>
							<div className="col-lg-6">
								<div className="row">
									<div className="col-lg-12 m-b30">
										<img alt="" src="images\capabilities\industrial\sheetMetalFabrication.jpg"></img>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 m-b30">
										<img alt="" src="images\capabilities\industrial\industrialInsulation2.jpg"></img>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 m-b30">
										<img alt="" src="images\capabilities\industrial\industrialInsulation.jpg"></img>
									</div>
									<div className="col-lg-12 m-b30">
										<img alt="" src="images\capabilities\fabrication\SpecialtyWelding700x500.jpg"></img>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion, changeNavSelected }

export default connect(mapStateToProps, mapDispatchToProps)(Services)