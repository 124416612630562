import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import FabricationContext from './FabricationContext'

const Mechanical = props => {
    let context = useContext(FabricationContext)

    useEffect(() => {
        props.changeIndexAccordion(1)
        context.setBanner({ ...context.banner, title: 'Fabrication Services' })
    }, [])

    return (
        <div className='row bg-white' >
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                    <img className='alignright' src='images\capabilities\fabrication\Mechanical_Services_1.jpg' className='radius-sm' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4 className='title'>Mechanical </h4>
                        <p style={{ textAlign:'justify'}}>TECHSOURCE are experts in the design and installation of all building and mechanical services including Heating, Plumbing, Ventilation, Air-Conditioning & Building Management Systems.
                            Our track record spans over 20 years with major large scale projects completed across many sectors including, Pharmaceutical, Food Processing, Medical,  Offices, Retail, Leisure, Hotel, Government Buildings and more….. It is our commitment to ongoing improvement that keeps our knowledge of what we do unchallenged and unsurpassed. All of our designs are completed using the latest in software technology creating 3D models of everything we install.
                            Together with our maintenance division, AMV Systems, TECHSOURCE offers a first class all round service from design, manufacturing, build, commissioning and maintenance.
                        </p>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='dlab-box'>
                    <div className='dlab-media'>

                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4>Our Mechanical Services Expertise Includes:</h4>
                        <ul className='list-star red list-box customPaddinglist'>
                            <li>Heating, Ventilation, Air conditioning and Air Handling requirements</li>
                            <li>Process pipework & high spec hygienic piping</li>
                            <li>Water services such as L.P.H.W., domestic, mains & chilled water</li>
                            <li>Building Management Systems</li>
                            <li>Fire Fighting (Alarms & Sprinkler systems)</li>
                            <li>Medical Gases</li>
                            <li>Sanitary ware and soils & waste systems</li>
                            <li>Air Filtration</li>
                            <li>Under-Floor/ Trench Heating</li>
                            <li>Rainwater & grey water systems</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(Mechanical)