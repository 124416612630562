import React from 'react'
import PageBanner from '../../../common/PageBanner'

const DerrickCranes = () => {

    return (
        <div className="container max-w900">
            <div className="blog-post blog-single">
                <div className="dlab-post-text">
                    <img src="images\capabilities\inspection\derrick_cranes.jpg" className="alignleft" width="300"></img>
                    <p style={{ textAlign:'justify'}}>Derrick cranes are docking facilities mounted on vessels or dockside.  It is designed with 2 hooks with capacity 2*330t. The arm length is 70m with luffing 21.5-54m, mast diameter is 1.6m.</p>
                    <p style={{ textAlign:'justify'}}>This crane is employed to lift 22,000m3 LPG tanks, which consists of platform/base, lifting arm and A-frame mast. It is designed with 2 hooks with capacity 2*330t. The arm length is 70m with luffing 21.5-54m, mast diameter is 1.6m.</p>
                    <div className="row">
                    <h4>Features:</h4>
                        <ul className="list-circle primary m-a0">
                            <li>There are 4 driving system for hoisting mechanism, 2 driving system for luffing mechanism.</li>
                            <li>The entire derrick crane chassis adopt basin support on concrete platform, which can effectively ease the crane horizontal and vertical deformation.</li>
                            <li>Multi-layer winding line reel drum. Hoisting winding drum wrapped with 4 layers of wire rope, and luffing drum is wrapped with 5 layers of wire rope.</li>
                            <li>Complete frequency, lifting half load speed is 2 times of the full load speed.</li>
                            <li>Using inverter, encoder and a series of electronic control system to ensure the 4 lifting motors keep synchronous running.</li>
                        </ul>
                    </div>                           
                </div>
            </div>
        </div>
    )
}

export default DerrickCranes