import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeNavSelected from '../../../../redux/actions/changeNavSelected'
import PageBanner from '../../../common/PageBanner'

import Accordion from '../../../common/Accordion'
import MobileCranes from '../../../pages/capabilities/inspectionAndTesting/MobileCranes';
import OverheadCranes from '../../../pages/capabilities/inspectionAndTesting/OverheadCranes';
import PedestalCranes from '../../../pages/capabilities/inspectionAndTesting/PedestalCranes';
import DerrickCranes from '../../../pages/capabilities/inspectionAndTesting/DerrickCranes';
import Slings from '../../../pages/capabilities/inspectionAndTesting/Slings';
import RiggingHardware from '../../../pages/capabilities/inspectionAndTesting/RiggingHardware';
import FallProtection from '../../../pages/capabilities/inspectionAndTesting/FallProtection';
import BelowHookLiftingDevices from '../../../pages/capabilities/inspectionAndTesting/BelowHookLiftingDevices';
import HoistsAndComeLongs from '../../../pages/capabilities/inspectionAndTesting/HoistsAndComeLongs';
import ForkliftsAndManlifts from '../../../pages/capabilities/inspectionAndTesting/ForkliftsAndManlifts';

const InspectionAndTesting = () => {

    const panelsData = [{
        id: 'collapse01',
        title: 'Mobile Cranes',
        component: MobileCranes
    }, {
        id: 'collapse02',
        title: 'Overhead Cranes',
        component: OverheadCranes
    }, {
        id: 'collapse03',
        title: 'Pedestal Cranes',
        component: PedestalCranes
    }, {
        id: 'collapse04',
        title: 'Derrick Cranes',
        component: DerrickCranes
    }, /*{
        id: 'collapse05',
        title: 'Slings',
        component: Slings
    },*/ {
        id: 'collapse06',
        title: 'Rigging Hardware',
        component: RiggingHardware
    }, {
        id: 'collapse07',
        title: 'Fall Protection',
        component: FallProtection
    }, {
        id: 'collapse08',
        title: 'Below the Hook Lifting Devices',
        component: BelowHookLiftingDevices
    }, {
        id: 'collapse09',
        title: 'Hoists & Come-a-longs',
        component: HoistsAndComeLongs
    }, {
        id: 'collapse10',
        title: 'Forklifts & Manlifts',
        component: ForkliftsAndManlifts
    }]

    return (
        <div className='bg-white'>
            <PageBanner title='Inspection & Testing' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
            <br></br>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <h2>Security Inspections</h2>
                            <p class="m-b0">To ensure the safety of your plant, preventing robberies, from intrusions, from the company’s own workers, customers or strangers, we can provide you with a list of security protocols and procedures that minimize the percentage of each and every kind of loss. We have experience in loss prevention, we can think like they think, looking and finding all the weaknesses that may appear. Our protocols build an invisible barrier against all the threats against the security of your business, your employees, and your customers.</p>
                        </div> 
                        <br></br>
                        <div className="row">
                            <h2 assName="title">Safety and Risk-Assessment Inspections</h2>
                            <p class="m-b0">
                                Because we know that failures in the fabrication process carry great risks, including but not limited to the loss of life for industrial machinery operators, they are a serious concern for us as much as for every industrial plant manager, as they should be. Although not as tragic, malfunctions will most often derive in loss of product quality and eventually compromise customer and public relations, discontent by customers, and even legal implications that have a potential of greatly affecting the firm’s bottom line.
                            </p>
                        </div>
                        <br></br>
                        <div className="row">
                            <h2>Material testing and essays</h2>
                            <p class="m-b0">
                                To avoid risking the safety of your employees in their workplace, and the quality of finished products and your enterprise efficiency, performing material testing is a must. Material failures amount to a non-negligible percentage of accidents in industrial environments, take the safe route and perform security and quality control on construction and raw materials that feed your production process. 
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <img alt='' src='images\capabilities\inspection\Inspection_Testing.jpg'></img>
                    </div>
                </div>
            </div>

            <Accordion panelsData={ panelsData }></Accordion>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(InspectionAndTesting)