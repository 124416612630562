import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'

const CommercialConstruction = props => {

    useEffect(() => {
        props.changeIndexAccordion(1)
    }, [])

    return (
    <div className='row bg-white' >
        <div className='col-lg-6 col-md-12 m-b30'>
            <div className='dlab-box'>
                <div className='dlab-media'>
                    <img className="alignright radius-sm" src="images\capabilities\industrial\commercial_construction_1.jpg" width="300" alt=""></img>
                </div>

                <div className='dlab-info m-t30 '>
                    <h4>Commercial Building Company</h4>
                    <p style={{ textAlign:'justify'}}>TECHSOURCE specialises in tilt-up panel construction for commercial buildings, such as industrial factories, warehouses, offices, schools, churches and workshops. The quality of our services sets us apart from competing commercial building contractors. </p>	
                    <p style={{textAlign:'justify'}}>We provide an all-inclusive service that includes drafting and design, engineering, and construction, conveniently giving you access to the diverse range of services you need to make project success a reality, all in one location.</p>
                </div>
            </div>
        </div>
        <div className='col-lg-6 col-md-12'>
            <div className='dlab-box'>
                <div className='dlab-media'>
                    <img className="alignright radius-sm" src="images\capabilities\industrial\commercial_construction_2.jpg" width="300" alt=""></img>
                </div>
                <div className='dlab-info m-t30 '>
                    <h4>Our Services:</h4>
                    <ul className="list-star red list-box customPaddinglist">
                        <li>Church Construction</li>
                        <li>Industrial Construction</li>
                        <li>Warehouse Construction</li>
                    </ul>
                    <p className="justificar">We take pride in completing all our projects to the highest standard, which is why we have handpicked each team member in our commercial building company. This ensures that we both meet and exceed client expectations for every build. The complement of professionals behind each project are also familiar with the Building Code of Australia, relevant Australian standards, and Local Authority requirements. This familiarity with relevant legislation and requirements facilitates a much faster and hassle-free process of design and approval, in addition to timely construction.</p>
                </div>
            </div>
        </div>
    </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(CommercialConstruction)