import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import axios from 'axios'
import Constants from '../../common/Constants'

const FormInfo = () => {

    const { navs } = Constants()

    const [ name, setName] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ message, setMessage ] = useState('')
    const [ industry, setIndustry ] = useState('')

    const mapSetState = {
        'name': setName,
        'email': setEmail,
        'phone': setPhone,
        'message': setMessage,
        'industry': setIndustry
    }

    const handleChange = e => {
        mapSetState[e.target.name](e.target.value)
    }

    const handleChangePhone = e => {
        if(e.target.name === 'phone') {
            const { value, maxLength } = e.target;
            if (maxLength < value.length) {
                setPhone(value.slice(0,maxLength))
                return
            }
        }
        mapSetState[e.target.name](e.target.value)
    }

    const handleSubmit = () => {
        let industry = window.$('#industry').val()
        console.log({ name, email, msg: message, phone, industry })
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API}`,
            data: { name, email, msg: message, phone, industry }
        })
        .then(result => {
            let data = result.data
            // console.log(data)
            resetField()
        })
        .catch( error => console.log(error.message) )
    }

    const resetField = () => {
        Object.keys(mapSetState).forEach( key => {
            mapSetState[key]('')
        })
    }

    const getOptions = () => {
        let options = navs[2].items[0].items.filter(item => item.topBar === true)
        return options
    }

    return (
        <div className='col-lg-8 col-md-12 m-b30'>
            <form className='inquiry-form wow box-shadow bg-white fadeInUp' data-wow-delay='0.2s' onSubmit={ e => { e.preventDefault() } }>
                <h3 className='title-box font-weight-300 m-t0 m-b10'>Let's Convert Your Idea into Reality <span className='bg-primary'></span></h3>
                <p></p>
                <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                        <div className='form-group'>
                            <div className='input-group'>
                                <span className='input-group-addon'><i className='ti-user text-primary'></i></span>
                                <input name='name' value={ name } onChange={ handleChange } type='text' required className='form-control' placeholder='First Name'/>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <div className='form-group'>
                            <div className='input-group'>
                                <span className='input-group-addon'><i className='ti-mobile text-primary'></i></span>
                                <input name='phone' value={ phone } onChange={ handleChangePhone } type='number' maxLength='11' required className='form-control' placeholder='Phone'/>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                        <div className='form-group'>
                            <div className='input-group'>
                                <span className='input-group-addon'><i className='ti-email text-primary'></i></span>
                                <input name='email' value={ email } onChange={ handleChange } type='email' className='form-control' required  placeholder='Your Email Id'/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className='form-group'>
                            <div className='input-group'>
                                <span className="input-group-addon">
                                    <i className="ti-check-box text-primary"></i>
                                </span>
                                <Form.Control as='select' id='industry' name='industry' value={ industry } onChange={ handleChange } className='select-custom'>
                                    <option value=''>Select Industry Service</option>
                                    {
                                        getOptions().map((option,key) => {
                                            return <option value={ option.name } key={ key }>{ option.name }</option>
                                        })
                                    }
                                    <option value='10'>Others</option>
                                </Form.Control>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                        <div className='form-group'>
                            <div className='input-group'>
                                <span className='input-group-addon'><i className='ti-agenda text-primary'></i></span>
                                <textarea name='message' value={ message } onChange={ handleChange } rows='4' className='form-control' required placeholder='Tell us about your project or idea'></textarea>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                        <button name='submit' onClick={ handleSubmit } type='submit' value='Submit' className='site-button button-md'> <span>Send Message</span> </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default FormInfo