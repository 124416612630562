import React from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../redux/actions/changeIndexAccordion'
import { Link } from 'react-router-dom'

const LeftMenu = ({ state, menuOptions, changeIndexAccordion }) => {

    const { indexAccordion } = state

    const handler = (key) => {
        changeIndexAccordion(key)
        window.$('html,body').animate({
            scrollTop: window.$('#contentFabrication').position().top + 100
        }, 500);
    }

    return (
        <div className='col-xl-3 col-lg-4 col-md-5'>
            <div className='widget sidebar-widget ext-sidebar-menu widget_nav_menu'>
                <ul className='menu'> {
                    menuOptions.map((nav,key) => {
                        if(nav.name !== 'See All') {
                            return(
                                <li id={ key } key={ key } className={  key === indexAccordion ? 'active' : '' } onClick={ () => { handler(key) } }>
                                    <Link to={ nav.path }>{ nav.name }</Link>
                                </li>
                            )
                        }
                    })
                } </ul>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu)
