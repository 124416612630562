import React from 'react'
import { Link } from 'react-router-dom'
import ScrollTopFunction from '../common/ScrollTopFunction'

const OurProject = () => {
    return (
        <div className='section-full'>
            <div className='row m-lr0'>
                <div className='col-xl-6 col-lg-12 p-lr0 d-flex dis-tbl latest-project-info bg-primary wow fadeInLeft' data-wow-duration='2s' data-wow-delay='0.3s'>
                    <div className='text-white align-self-center'>
                        <h2>Our Services</h2>
                        <p>TECHSOURCE Group - Industrial Services provides a wide range of high quality services for industrial firms and facilities as well as industrial production and processing plants and construction sites with a focus on safety and efficiency. TECHSOURCE is a firm fully dedicated to the personalized installation and maintenance of industrial machinery as well as outsourcing of a wide range of industrial and construction services like power generation, specialty welding, industrial insulation, industrial construction, maintenance, heat exchangers services, tower and vessels, project management, boilers, and refining.</p>
                        <Link to='/capabilities' onClick={ () => ScrollTopFunction() } className='site-button btnhover8 white'>View Services</Link>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-12 p-lr0 wow fadeInRight' data-wow-duration='2s' data-wow-delay='0.3s'>
                    <div className='row m-a0 lightgallery'>
                        <div className='col-lg-12 col-md-12 col-sm-12 p-lr0'>
                            <div className='dlab-box overlay-gallery-bx1'>
                                <div className='dlab-thum dlab-img-overlay1 primary'>
                                    <Link to='/'> <img className='img-cover' src='images/gallery/pic7.jpg'  alt=''/> </Link>
                                    <div className='overlay-bx'>
                                        <div className='overlay-icon'>
                                            {/* <Link to='/'> <i className='fa fa-play icon-bx-xs'></i> </Link> */}
                                            <span data-exthumbimage='images/gallery/pic7.jpg' data-src='images/gallery/pic7.jpg' className='check-km' title='Power Generation'>
                                                    <i className='fa fa-search icon-bx-xs'></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 p-lr0'>
                            <div className='dlab-box overlay-gallery-bx1'>
                                <div className='dlab-thum dlab-img-overlay1 primary'>
                                    <Link to='/'> <img className='img-cover' src='images/gallery/pic5.jpg'  alt=''/> </Link>
                                    <div className='overlay-bx'>
                                        <div className='overlay-icon'>
                                            {/* <Link to='/'> <i className='fa fa-play icon-bx-xs'></i> </Link> */}
                                            <span data-exthumbimage='images/gallery/pic5.jpg' data-src='images/gallery/pic5.jpg' className='check-km' title='Construction'>
                                                <i className='fa fa-search icon-bx-xs'></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 p-lr0'>
                            <div className='dlab-box overlay-gallery-bx1'>
                                <div className='dlab-thum dlab-img-overlay1 primary'>
                                    <Link to='/'> <img className='img-cover' src='images/gallery/pic6.jpg'  alt=''/> </Link>
                                    <div className='overlay-bx'>
                                        <div className='overlay-icon'>
                                            {/* <Link to='/'> <i className='fa fa-play icon-bx-xs'></i> </Link> */}
                                            <span data-exthumbimage='images/gallery/pic6.jpg' data-src='images/gallery/pic6.jpg' className='check-km' title='Pipeline'>
                                                <i className='fa fa-search icon-bx-xs'></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurProject
