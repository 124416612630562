import React from 'react'
import Constants from '../common/Constants'

const DivFollowUs = () => {
    const { socialNetworks } = Constants()
    return (
        <div className='social-curve wow fadeInLeft' data-wow-duration='2s' data-wow-delay='0.3s'>
            <div className='container'>
                <ul className='social-icon list-inline m-b0'>
                    <li><span className='title'>Follow Us</span></li>
                    {
                        socialNetworks.map((item,key) => {
                            if(item.active){
                                return(
                                    <li key={ key }>
                                        <a href={ item.path } className={ 'site-button-link ' + item.classNameButton + ' hover'}>
                                        <i className={ item.classNameIcon }></i></a>
                                    </li>
                                )
                            }
                            return
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default DivFollowUs
