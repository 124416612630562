import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeNavSelected from '../../../redux/actions/changeNavSelected'
import PageBanner from '../../common/PageBanner'
import { Link } from 'react-router-dom'
import Init from '../../common/Init'

const Projects = props => {

    useEffect(() => {
        Init.initGallery()
        props.changeNavSelected(2)
	}, [])

    let categories = {
        category1:{ name: 'All', filter: '' },
        category2:{ name: 'Industrial', filter: 'industrial' },
        category3:{ name: 'Fabrication', filter: 'fabrication' },
        category4:{ name: 'Construction', filter: 'construction' },
        category5:{ name: 'Safety', filter: 'safety' },
        category6:{ name: 'DAS', filter: 'das' }
    }

    let gallery = [
        {
            categories: ['industrial'],
            path: 'images/projects/power_generation_1.jpg',
            title: 'Power Generation',
            subTitle: 'Industrial',
            pathDetail: '/'
        }, {
            categories: ['industrial'],
            path: 'images/projects/boilier_1.jpg',
            title: 'Boiler services',
            subTitle: 'Industrial',
            pathDetail: '/'
        }, {
            categories: ['construction'],
            path: 'images/projects/commercial_construction2.jpg',
            title: 'Commercial Construction',
            subTitle: 'Construction',
            pathDetail: '/'
        }, {
            categories: ['industrial'],
            path: 'images/projects/insulation.jpg',
            title: 'Industrial Insulation',
            subTitle: 'Industrial',
            pathDetail: '/'
        }, {
            categories: ['industrial'],
            path: 'images/projects/pipe_fabrication_p1.jpg',
            title: 'Pipeline',
            subTitle: 'Industrial',
            pathDetail: '/'
        }, {
            categories: ['safety'],
            path: 'images/projects/construction3.jpg',
            title: 'Safety Bottom',
            subTitle: 'Safety',
            pathDetail: '/'
        }, {
            categories: ['industrial'],
            path: 'images/projects/Welding_1.jpg',
            title: 'Specialty Welding',
            subTitle: 'Industrial',
            pathDetail: '/'
        }, {
            categories: ['fabrication'],
            path: 'images/projects/structural_steel.jpg',
            title: 'Structural Steel',
            subTitle: 'Fabrication',
            pathDetail: '/'
        }, {
            categories: ['fabrication'],
            path: 'images/projects/pipe_fabrication_3.jpg',
            title: 'Pipe Fabrication',
            subTitle: 'Fabrication',
            pathDetail: '/'
        }, {
            categories: ['fabrication'],
            path: 'images/projects/pipe_fittings.jpg',
            title: 'Pipe Fittings',
            subTitle: 'Fabrication',
            pathDetail: '/'
        }, {
            categories: ['fabrication'],
            path: 'images/projects/sheet_metal.jpg',
            title: 'Sheet Metal',
            subTitle: 'Fabrication',
            pathDetail: '/'
        }, {
            categories: ['construction'],
            path: 'images/projects/commercial_construction.jpg',
            title: 'Construction',
            subTitle: 'Construction',
            pathDetail: '/'
        }, {
            categories: ['industrial'],
            path: 'images/projects/petrochemical_plant.jpg',
            title: 'Petrochemical Plant',
            subTitle: 'Industrial',
            pathDetail: '/'
        }, {
            categories: ['industrial'],
            path: 'images/projects/indsutrial_pipe.jpg',
            title: 'indsutrial Pipe',
            subTitle: 'Industrial',
            pathDetail: '/'
        }, {
            categories: ['das'],
            path: 'images/capabilities/das/projects_network.jpg',
            title: 'Network and Audio',
            subTitle: 'DAS',
            pathDetail: '/das'
        },, {
            categories: ['das'],
            path: 'images/capabilities/das/antena_network.jpg',
            title: 'Antena Install',
            subTitle: 'DAS',
            pathDetail: '/das'
        }
    ]

    return (
        <div className='bg-white'>
            <PageBanner title='Projects' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>

            <div className='content-block'>
                <div className='section-full content-inner-2 portfolio text-uppercase bg-white' id='portfolio'>
                    <div className='container'>
                        <div className='site-filters clearfix center  m-b40'>
                            <ul className='filters' data-toggle='buttons'>{
                                Object.keys(categories).map((category,key) => {
                                    return(
                                        <li key={ key } data-filter={ categories[category].filter } className={ key === 0 ?'btn active' : 'btn' }>
                                            <input type='radio'/>
                                            <a href='#' className='site-button-secondry button-sm radius-xl'>
                                                <span>{ categories[category].name }</span>
                                            </a>
                                        </li>
                                    )
                                })
                            }</ul>
                        </div>
                        <div className='clearfix' id='lightgallery'>
                            <ul id='masonry' className='portfolio-ic dlab-gallery-listing gallery-grid-4 lightgallery text-center'> {
                                gallery.map((img,key) => {
                                    let categories = img.categories.reduce((acc,valAnt) => { return acc + ' ' + valAnt },'')
                                    return(
                                        <li key={ key } className={ categories + ' card-container col-lg-6 col-md-6 col-sm-6 p-a0' }>
                                            <div className='dlab-box dlab-gallery-box'>
                                                <div className='dlab-media dlab-img-overlay1 dlab-img-effect'>
                                                    <img src={ img.path } alt='' style={{ minWidth: '571px', minHeight: '460px' }} />
                                                    <div className='overlay-bx'>
                                                        <div className='overlay-icon'>
                                                            <div className='text-white'>
                                                                <Link to={ img.pathDetail }><i className='fa fa-link icon-bx-xs'></i></Link>
                                                                <span data-exthumbimage={ img.path } data-src={ img.path } className='check-km' title='Factory Managment'>
                                                                    <i className='fa fa-picture-o icon-bx-xs'></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='dez-info p-a30 bg-white'>
                                                    <p className='dez-title m-t0'><Link to={ img.pathDetail }>{ img.title }</Link></p>
                                                    <p><small>{ img.subTitle }</small></p>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }</ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(Projects)
