import React from 'react'
import PageBanner from '../../../common/PageBanner'

const FallProtection = () => {

    return (
        <div className="container max-w900">
            <div className="blog-post blog-single">
                <div className="dlab-post-text">
                    <img src="images\capabilities\inspection\fall_protection.jpg" className="alignleft" width="300"></img>
                    <p style={{ textAlign:'justify'}}>When looking for top-of-the-line fall arrest and protection services, you will find it at TECHSOURCE. Our fall protection professionals can train your team at your location on safe height execution. Our skilled technicians administer personal protective equipment workshops designed around your specific requirements.</p>
                    <p style={{ textAlign:'justify'}}>All fall protection devices must be regularly inspected and equipment visually inspected before each and every use. To ensure that your fall protection meets safety and compliance regulation requirements, TECHSOURCE inspection services include comprehensive testing and inspection of all your fall protection gear. TECHSOURCE also trains users on how to visually check equipment for signs of wear and tear before each use.</p>
                    <p style={{ textAlign:'justify'}}>Plus, our custom engineered fall protection systems offer the industry’s largest array of fall arrest equipment from top vendors, all manufactured with the highest quality materials to deliver safety, comfort and ease of use.</p>
                    <p style={{ textAlign:'justify'}}>TECHSOURCE gathers applicable information to assess your site and your workers’ needs. By gathering this information, our experts can determine what fall arrest equipment is needed for your particular site. Services may vary by region. Please contact your salesperson to discuss offerings in your area.</p>
                </div>
            </div>
        </div>
    )
}

export default FallProtection