import React from 'react'
import { Link } from 'react-router-dom'

const Percentages = () => {
    return (
        <section>
            <div className='section-full content-inner bg-white'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-7 col-md-12 p-r30'>
							<div className='our-story'>
								<span>OUR STORY</span>
								<h2 className='title'>What we do <span className='text-primary'>since 2016</span></h2>
                                <div className='section-about'>
                                    <p>Techsource Specialized Services LLC is a firm fully dedicated to the personalized installation and maintenance of industrial machinery as well as outsourcing of a wide range of industrial and construction services like power generation, specialty welding, industrial insulation, industrial construction, maintenance, heat exchangers services, tower and vessels, project management, boilers, and refining.</p>
                                    <p>We provide fabrication services for industrial facilities: Pipes, sheet metal, turnaround planning and execution, insulation, customized pressure vessels, structural steel, modular fabrication and material processing.</p>
                                    <p>Consulting and SME services are also provided by Techsource in fields like cranes and rigging, developing C and R procedures, engineered lift flans and on-site third party liaison. Inspection and testing of a wide range of cranes, lifts and other logistics related equipment.</p>
                                </div>
                                {/* <Link to='/industries' className='site-button'>Read More</Link> */}
							</div>
						</div>
						<div className='col-lg-5 col-md-12 p-a0'>
							<img src='images/about/techsource.jpg' className='radius-sm' alt=''/>
						</div>
					</div>
				</div>
			</div>
            <div className='section-full content-inner about-progress' style={{ paddingTop: '10px' }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7 col-md-12'>
                            <div className='max-w700  m-auto'>
                                <div className='section-head'>
                                    <h2 className='title'>What makes us different</h2>
                                    <p>We stand out thanks to our long standing, solid reputation as a world class industrial contractor with a focus on delivering personalized solutions to our clients. A benchmark for safety, integrity and reliability in industrial machinery and construction, offering a full fledged customer service and attention to detail in all of our projects.</p>
                                </div>
                                <div className='about-progress-box'>
                                    <h6 className='text-primary'>Internal Process<span className='progress-num pull-right'>85%</span></h6>
                                    <div className='about-progress-bar bg-gray-dark'>
                                        <div className='about-progress bg-primary wow slideInLeft' data-wow-delay='0.2s' data-wow-duration='2s' style={{ width: '85%' }} ></div>
                                    </div>
                                </div>
                                <div className='about-progress-box'>
                                    <h6 className='text-primary'> Continuous Improvement<span className='progress-num pull-right'>80%</span></h6>
                                    <div className='about-progress-bar bg-gray-dark'>
                                        <div className='about-progress bg-primary wow slideInLeft' data-wow-delay='0.4s' data-wow-duration='2s' style={{ width: '80%' }}></div>
                                    </div>
                                </div>
                                <div className='about-progress-box'>
                                    <h6 className='text-primary'>Performance<span className='progress-num pull-right'>95%</span></h6>
                                    <div className='about-progress-bar bg-gray-dark'>
                                        <div className='about-progress bg-primary wow slideInLeft' data-wow-delay='0.6s' data-wow-duration='2s' style={{ width: '95%' }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12 p-a0'>
                            <img src='images/about/aboutImg1.jpg' alt='' className='img-cover '/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Percentages
