import React from 'react'
import PageBanner from '../../common/PageBanner'
import Content from '../about/Content'
import OurCompanies from '../../mainContent/OurCompanies'
import MisionAndValues from './MisionAndValues'

const About = () => {
    return (
        <div className='bg-white'>
            <PageBanner title='About TECHSOURCE' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
            <Content></Content>
            <MisionAndValues></MisionAndValues>
            <OurCompanies></OurCompanies>
        </div>
    )
}

export default About