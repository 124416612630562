import React from 'react'
import DivFollowUs from './DivFollowUs'
import SubscribeNewsletter from './SubscribeNewsletter'
import UsefulLinks from './UsefulLinks'
import ContactUs from './ContactUs'
import Copyright from './Copyright'

const Footer = () => {

    const style = {
        // backgroundImage: 'url(images/background/footer.jpg)',
        backgroundColor: 'black'
    }

    return (
        <div className='site-footer bg-white p-t40'>
            <DivFollowUs></DivFollowUs>
            <div className='footer-top overlay-black-light' style={ style }>
                <div className='container'>
                    <div className='row'>
                        <SubscribeNewsletter></SubscribeNewsletter>
                        <UsefulLinks indexMenu='2' indexSubMenu='0' ></UsefulLinks>
                        <UsefulLinks indexMenu='2' indexSubMenu='1' ></UsefulLinks>
                        <ContactUs></ContactUs>
                    </div>
                </div>
            </div>
            <Copyright></Copyright>
        </div>
    )
}

export default Footer
