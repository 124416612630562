import React from 'react'
import Constants from './Constants'

const LeftButton = () => {
    return (
        <a id="callnowbutton" href={ 'tel:' + Constants().contactInformation.phone } className='bt-buy-now theme-btn'>
            <i className='fa fa-phone'></i><span>Call Us</span>
        </a>
    )
}

export default LeftButton
