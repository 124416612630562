import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import changeNavSelected from '../../../../redux/actions/changeNavSelected'
import ScrollTopFunction from '../../../common/ScrollTopFunction'
import PageBanner from '../../../common/PageBanner'
import { Link } from 'react-router-dom'

const Industries = props => {

	useEffect(() => {
		props.changeNavSelected(1)
	}, [])

	const handleClick = () => {
		ScrollTopFunction()
		props.changeIndexAccordion(0)
	}

    return (
        <section>
            <PageBanner title='Industrial Services' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
            <div className='section-full bg-white' style={{ paddingBottom: '8%' }}>
				<div className='row spno about-industry'>
					{/* Power Generation */}
					<div className='col-lg-8'>
						<img src='images/capabilities/industrial/powerGeneration1920x1080.jpg' alt='' className='img-cover'/>
					</div>
					<div className='col-lg-4 bg-white'>
						<div className='service-box style2'>
							<section>
								<h2 className='title text-black'><span>Power </span> <br/>Generation</h2>
								<p>Specializing in the construction and maintenance of Electric Transmission and Distribution systems.</p>
								<Link to='/power-generation' onClick={ handleClick } className='site-button outline outline-2'>VIEW</Link>
							</section>
						</div>
					</div>
					{/* Industrial Insulation */}
					<div className='col-lg-4'>
						<img src='images/capabilities/industrial/industrialInsulation900x550.jpg' alt='' className='img-cover'/>
					</div>
					<div className='col-lg-4 bg-secondry text-white'>
						<div className='service-box style2'>
							<section>
								<h2 className='title'><span>Industrial </span> <br/>Insulation.</h2>
								<p>These types of facilities take a company with the experience and expertise to perform under harsh conditions.</p>
								<Link to='/industrial-insulation' onClick={ handleClick } className='site-button outline white outline-2'>VIEW</Link>
							</section>
						</div>
					</div>
					<div className='col-lg-4'>
						<img src='images/capabilities/industrial/IndustriaInsulation900x550.jpg' alt='' className='img-cover'/>
					</div>
					{/* Specialty Welding */}
					<div className='col-lg-4 bg-white'>
						<div className='service-box style2'>
							<section>
								<h2 className='title text-black'><span>Specialty </span> <br/>Welding</h2>
								<p>TECHSOURCE provides premium specialty welding and field services. Our highly skilled specialty welders have the metallurgical expertise and field experience it takes to weld on a full range of materials and industrial equipment.</p>
								<Link to='/specialty-welding' onClick={ handleClick } className='site-button outline outline-2'>VIEW</Link>
							</section>
						</div>
					</div>
					<div className='col-lg-8'>
						<img src='images/capabilities/industrial/SpecialtyWelding1920x1080.jpg' alt='' className='img-cover'/>
					</div>
					{/* Constructuion */}
					<div className='col-lg-4'>
						<img src='images/capabilities/industrial/IndustrialConstruction900x550.jpg' alt='' className='img-cover'/>
					</div>
					<div className='col-lg-4 bg-primary text-white'>
						<div className='service-box style2'>
							<section>
								<h2 className='title text-black'><span>Construction  </span> <br/></h2>
								<p>TECHSOURCE specialises in tilt-up panel construction for commercial buildings, such as industrial factories, warehouses, offices, schools, churches and workshops.</p>
								<Link to='/construction' onClick={ handleClick } className='site-button outline white outline-2'>VIEW</Link>
							</section>
						</div>
					</div>
					<div className='col-lg-4'>
						<img src='images/capabilities/industrial/CommercialConstruction900x550.jpg' alt='' className='img-cover'/>
					</div>
					{/* Underground Construction */}
					<div className='col-lg-8'>
						<img src='images/capabilities/industrial/UndergroundConstruction1920x1080.jpg' alt='' className='img-cover'/>
					</div>
					<div className='col-lg-4 bg-secondry text-white'>
						<div className='service-box style2'>
							<section>
								<h2 className='title'><span>Underground </span> <br/>Construction.</h2>
								<p>As the excitement about fiber optic cables in residential and business applications continues to grow, it is easy to forget that this is not an entirely new development in communication technology.</p>
								<Link to='/companies' onClick={ handleClick } className='site-button outline white outline-2'>VIEW</Link>
							</section>
						</div>
					</div>
				</div>
			</div>
        </section>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected,changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(Industries)