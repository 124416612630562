import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeNavSelected from '../../../redux/actions/changeNavSelected'
import PageBanner from '../../common/PageBanner'
import ScrollTopFunction from '../../common/ScrollTopFunction'
import { Link } from 'react-router-dom'

const Capabilities = props => {

    useEffect(() => {
        props.changeNavSelected(1)
    }, [])

    const animatedCards = [{
        animation: 'fadeInLeft',
        card1: {
            img: 'images/capabilities/industrial/electricPower.jpg'
        },
        card2: {
            icon: 'flaticon-factory-1',
            title: 'Industrial Services',
            description: 'TECHSOURCE brings everything you need in terms of industrial.',
            path: '/industries'
        },
        card3: {
            img: 'images/capabilities/industrial/construction700x500.jpg'
        }
    }, {
        animation: 'fadeInRight',
        card1: {
            img: 'images/capabilities/fabrication/TowerAndVessel.jpg'
        },
        card2: {
            icon: 'flaticon-robot-arm',
            title: 'Fabrication Services',
            description: 'TECHSOURCE provides a personalized outsourcing service for industrial fabrication',
            path: '/fabrication'
        },
        card3: {
            img: 'images/capabilities/fabrication/Pipeline.jpg'
        }
    }, {
        animation: 'fadeInLeft',
        card1: {
            img: 'images/capabilities/consulting/Consulting&SMEServices700x500.jpg'
        },
        card2: {
            icon: 'flaticon-engineer-1',
            title: 'Consulting & SME Services',
            description: 'We can provide you with expert advice regarding all aspects of your industry production process.',
            path: '/consulting'
        },
        card3: {
            img: 'images/capabilities/consulting/EngineeredLiftPlans700x500.jpg'
        }
    }, {
        animation: 'fadeInRight',
        card1: {
            img: 'images/capabilities/inspection/FallProtection700x500.jpg'
        },
        card2: {
            icon: 'ti-ruler-pencil',
            title: 'Inspection & Testing',
            description: 'Because we know that failures in the fabrication process carry great risks',
            path: '/inspection-and-testing'
        },
        card3: {
            img: 'images/capabilities/inspection/OverheadCranes700x500.jpg'
        }
    }]

    const handleClick = () => {
		ScrollTopFunction()
	}

    return (
        <div className='bg-white'>
            <PageBanner title='Capabilities' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
            <div style={{ paddingTop:'3%' }}>
            {
                animatedCards.map((object,key) => {
                    return(
                        <div key={ key } className={ key === 3 ? 'row dzseth' : 'row dzseth m-b10'}>
                            <div className='col-md-4 col-lg-4 m-b30'>
                                <div className={ 'dlab-box img-hvr-content-style-1 no-hover wow ' + object.animation + ' fly-box-ho' } data-wow-delay='0.3s'>
                                    <div className='dlab-media dlab-img-overlay1 dlab-img-effect zoom'>
                                        <img src={ object.card1.img } className='img-cover' alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 col-lg-4 m-b30'>
                                <div className={ 'icon-bx-wraper bx-style-1 bg-white p-a30 center wow ' + object.animation + ' fly-box-ho seth' } data-wow-delay='0.6s'>
                                    <div className='icon-bx-sm radius bg-primary text-white m-b20 m-t20'>
                                        <Link to={ object.card2.path } onClick={ () => handleClick() } className='icon-cell'>
                                            <i className={ object.card2.icon }></i>
                                        </Link>
                                    </div>
                                    <div className='icon-content'>
                                        <h5 className='dlab-tilte text-uppercase'>{ object.card2.title }</h5>
                                        <p style={{ paddingBottom: '8px' }}>{ object.card2.description }</p>
                                    </div>
                                    <div className='icon-box-btn text-center'>
                                        <Link to={ object.card2.path } onClick={ () => handleClick() } className='site-button btn-block'>Read More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 col-lg-4 m-b30'>
                                <div className={ 'dlab-box img-hvr-content-style-1 no-hover wow ' + object.animation + ' fly-box-ho' } data-wow-delay='0.3s'>
                                    <div className='dlab-media dlab-img-overlay1 dlab-img-effect zoom'>
                                        <img src={ object.card3.img } className='img-cover' alt=''/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(Capabilities)