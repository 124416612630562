import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import OurCompanies from '../../mainContent/OurCompanies'
import changeNavSelected from '../../../redux/actions/changeNavSelected'

const Content = (props) => {

  useEffect(() => {
    props.changeNavSelected(3)
  }, [])

  return (
    <div className='content-block content-center bg-white' style={{ paddingBottom: '6%' }}>
      <div className='section-full content-inner bg-white'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-md-12'>
              <div className='section-head text-black'>
                {/*  <h4 className='text-gray-dark font-weight-300 m-b10'>We are</h4>  */}
                <h2 className='title'>Distribution for Antenna Systems</h2>
                <p>Whith over a decade of combined experience in providing technology-based solutions and exceeding our customer expectations. Our company stands alone by not only providing high value services but also quialify relationships.</p>
              </div>
              <div className='dlab-info m-t30 '>
              <h4>Capabilities</h4>
                <ul className='list-star red list-box customPaddinglist'>
                  <li>Distributed Antenna System Design</li>
                  <li>Distributed Antenna System Installation</li>
                  <li>Distributed Antenna System Integration and Testing</li>
                  <li>Public Safety Systems</li>
                  <li>Small Cell Installation Services</li>
                  <li>Ericsson Integration Services</li>
                  <li>Nokia Small Cell Installation Services</li>
                  <li>License Electrical Contractor</li>
                </ul>
              </div>
            </div>
            <div className='col-lg-5 col-md-12'>
              <div className='col-lg-12 m-b30'>
                <img alt='' src='images/about/network.jpg' />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-5 col-md-12'>
              <div className='col-lg-12 m-b30 m-t40'>
                <img alt='' src='images/daf/daf-02.png' />
              </div>
            </div>
            <div className='col-lg-7 col-md-12'>
              <div className='dlab-info m-t30 '>
                <ul className='list-star red list-box customPaddinglist'>
                  <li>Power Plant and Battery Installation Services</li>
                  <li>Structured Cable Installation Services</li>
                  <li>Wi-Fi Design and Installation Services</li>
                  <li>System Maintenance and Monitoring</li>
                  <li>Project Management</li>
                  <li>Civil Construction Services</li>
                  <li>Voltserver</li>
                </ul>
              </div>
            </div>
          </div>
          <h3>Attributes</h3>
          <div className='row'>
          <div className='col-lg-7 col-md-12'>
              <div className='dlab-info m-t30 '>
                <ul className='list-star red list-box customPaddinglist'>
                  <li>Anritsu Site Master Sweep Certification</li>
                  <li>JMA(PPC) Certified</li>
                  <li>Anritsu PIM Certified</li>
                  <li>Commscope Certified (Includes ION-U)</li>
                  <li>ADRF Certified</li>
                  <li>Corning Mobile Access Certified</li>
                  <li>Corning Fiber Certified</li>
                  <li>JMA Teko</li>
                  <li>Westell</li>
                  <li>GE/Emerson Power Plant</li>
                  <li>IBwave Level III</li>
                  <li>OSHA 10 or Higher</li>
                  <li>Project Management</li>
                  <li>JDSU RAN Advisor</li>
                  <li>Solid</li>
                  <li>EXFO</li>
                </ul>
              </div>
            </div>
            <div className='col-lg-5 col-md-12'>
              <div className='col-lg-12 m-b30 m-t50'>
                <img alt='' src='images/daf/daf-13.png' />
              </div>
            </div>
          </div>
          <h3>Portfolio</h3>
          <div className='row'>
            <div className='col-lg-5 col-md-12'>
              <div className='dlab-info m-t30 '>
              <h4>Verizon</h4>
                <ul className='list-star red list-box customPaddinglist'>
                  <li>Bank of America - Raleigh NC</li>
                  <li>Hilton Head Hospital – Hilton Head, SC</li>
                  <li>Three Alliance Building – Atlanta, GA</li>
                  <li>Carolina Place Mall – Pineville, NC</li>
                  <li>Jacksonville Intl. Airport – Jacksonville, FL</li>
                  <li>Venice Regional Bayfront Health – Petersburg, FL</li>
                  <li>Cape Fear Medical Center – Charleston, SC</li>
                </ul>
              </div>
            </div>
            <div className='col-lg-7 col-md-12'>
              <div className='col-lg-12 m-b30 m-t50'>
                <img alt='' src='images/daf/daf-11.png' />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-7 col-md-12'>
              <div className='col-lg-12 m-b30 m-t40'>
                <img alt='' src='images/daf/daf-10.png' />
              </div>
            </div>
            <div className='col-lg-5 col-md-12'>
              <div className='dlab-info m-t30 '>
              <h4>Mobilitie</h4>
                <ul className='list-star red list-box customPaddinglist'>
                  <li>PNC Amphitheater – Charlotte, NC</li>
                  <li>Spectrum Arena – Charlotte, NC</li>
                  <li>Diplomat Resort – Hollywood, FL</li>
                  <li>Oakwood Mall – Gretna, LA</li>
                  <li>Churchill Downs – Louisville, KY</li>
                  <li>Audi Field Stadium – Washington, D.C.</li>
                  <li>MGM Casino – Springfield, MA</li>
                  <li>Clemson Little John Coliseum – Clemson, SC</li>
                  <li>Sheraton Galleria Hotel – Atlanta, GA</li>
                  <li>Deerbrook Mall – Humble, TX</li>
                  <li>Greenwood Mall – Bowling Green, KY</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OurCompanies></OurCompanies>
    </div>
  )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(Content)