import React from 'react'
import { Link } from 'react-router-dom';
import ScrollTopFunction from '../common/ScrollTopFunction'

const Slide = ({ slide }) => {

    const handleClick = () => {
		ScrollTopFunction()
	}

    const getlayerVideo = slide => {
        if(slide.type === 'video') {
            let mediaPath = 'media/video/' + slide.imgName + '.' + slide.format;
            return(
                <div className="rs-background-video-layer"
                    data-forcerewind="on"
                    data-volume="mute"
                    data-videowidth="100%"
                    data-videoheight="100%"
                    data-videomp4={ mediaPath }
                    data-videopreload="auto"
                    data-videoloop="loop"
                    data-aspectratio="16:9"
                    data-autoplay="true"
                    data-autoplayonlyfirsttime="false">
                </div>
            )
        } else {
            return('');
        }
    };

    const getlayer = () => {
        return(
            <div className="tp-caption tp-shape tp-shapewrapper"
                id={ 'slide-' + slide.index + '-layer' }
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['0','0','0','0']"
                data-width="full"
                data-height="full"
                data-whitespace="nowrap"
                data-type="shape"
                data-basealign="slide"
                data-responsive_offset="off"
                data-responsive="off"
                data-frames='[
                    {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                    {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                ]'
                data-textalign="['left','left','left','left']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={ { zIndex: '2', backgroundColor:'rgba(0, 0, 0, 0.2)', borderColor:'rgba(0, 0, 0, 0)', borderWidth:'0px' } }>
            </div>
        )
    };

    const getlayer1 = () => {
        return(
            <div className="tp-caption rev_group"
                id={ 'slide-' + slide.index + '-layer-8' }
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['0','0','0','0']"
                data-width="['1018','1018','690','690']"
                data-height="['555','555','457','457']"
                data-whitespace="nowrap"
                data-type="group"
                data-responsive_offset="on"
                data-frames='[
                    {"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
                    {"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}
                ]'
                data-margintop="[0,0,0,0]"
                data-marginright="[0,0,0,0]"
                data-marginbottom="[0,0,0,0]"
                data-marginleft="[0,0,0,0]"
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={ { zIndex: '7', minWidth: '1018px', maxWidth: '1018px', maxWidth: '555px', maxWidth: '555px', whiteSpace: 'nowrap', fontSize: '20px', lineHeight: '22px', fontWeight: '400', color: '#ffffff', letterSpacing: '0px' } }>

                {/* <!-- LAYER NR. 2  [ TITLE ]--> */}
                { getlayer2() }

                {/* <!-- [ for video ] --> */}
                { getlayerVideo(slide) }

                {/* <!-- LAYER NR. 3 --> */}
                { getlayer3() }

                {/* <!-- LAYER NR. 4 --> */}
                { getlayer4() }

                {/* <!-- LAYER NR. 5 --> */}
                { getlayer5() }

                {/* <!-- LAYER NR. 6 --> */}
                {/* { getlayer6() } */}

            </div>
        )
    };

    const getlayer2 = () => {
        return(
            <div className="tp-caption tp-resizeme rs-parallaxlevel-1"
                id="slide-100-layer-5"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
				data-voffset="['160','148','130','117']"
                data-fontsize="['80','80','80','50']"
                data-lineheight="['94','94','94','64']"
                data-width="['936','936','500','500']"
                data-height="none"
                data-whitespace="normal"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[
                    {"from":"y:20px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":400,"ease":"Power4.easeOut"},
                    {"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}
                ]'
                data-margintop="[0,0,0,0]"
                data-marginright="[0,0,0,0]"
                data-marginbottom="[0,0,0,0]"
                data-marginleft="[0,0,0,0]"
                data-textalign="['center','center','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                    zIndex: '8',
                    minWidth: '936px',
                    maxWidth: '936px',
                    whiteSpace: 'normal',
                    fontSize: '80px',
                    lineHeight: '94px',
                    fontWeight: '700',
                    color: '#ffffff',
                    letterSpacing: '0px',
                    fontFamily:'Roboto'
                }}>
                { slide.title }<br/>{ slide.titlePart2 }
            </div>
        )
    };

    const getlayer3 = () => {
        return(
            <div className="tp-caption tp-resizeme rs-parallaxlevel-1"
            id="slide-100-layer-3"
            data-x="['center','center','center','center']"
            data-hoffset="['0','0','0','0']"
            data-y="['top','top','top','top']"
            data-voffset="['100','95','70','64']"
            data-width="none"
            data-height="none"
            data-whitespace="nowrap"
            data-type="text"
            data-responsive_offset="on"
            data-frames='[
                {"delay":"+1360","speed":570,"frame":"0","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
                {"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}
            ]'
            data-margintop="[0,0,0,0]"
            data-marginright="[0,0,0,0]"
            data-marginbottom="[0,0,0,0]"
            data-marginleft="[0,0,0,0]"
            data-textalign="['inherit','inherit','inherit','inherit']"
            data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[2,2,2,2]"
            data-paddingleft="[0,0,0,0]"
            data-fontsize="[20, 20, 20, 20]"
            style={{
                zIndex: '9',
                whiteSpace: 'nowrap',
                fontFamily: "'Roboto Condensed', sans-serif",
                fontSize: '12px',
                lineHeight: '22px',
                fontWeight: '700',
                color: '#ffffff',
                letterSpacing: '2px',
                fontFamily:'Roboto',
                textTransform:'uppercase',
                borderColor:'rgb(246,115,46)',
                borderStyle:'solid',
                borderWidth:'0px 0px 2px 0px'
            }}>
            { slide.topTitle }
        </div>
        )
    };

    const getlayer4 = () => {
        return(
            <div className="tp-caption tp-resizeme rs-parallaxlevel-1"
                id="slide-100-layer-6"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['373','373','350','378']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[
                    {"delay":"+1360","speed":560,"frame":"0","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
                    {"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}
                ]'
                data-margintop="[0,0,0,0]"
                data-marginright="[0,0,0,0]"
                data-marginbottom="[0,0,0,0]"
                data-marginleft="[0,0,0,0]"
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                data-fontsize="[25, 25, 25, 25]"
                style={{
                    zIndex: '10',
                    whiteSpace: 'nowrap',
                    fontSize: '18px',
                    lineHeight: '22px',
                    fontWeight: '400',
                    color: '#ffffff',
                    letterSpacing: '0px',
                    fontFamily:'Open Sans'
                }}>
                { slide.subTitle }
            </div>
        )
    };

    const getlayer5 = () => {
        return(
            <Link to='/capabilities' onClick={ () => handleClick() } className="tp-caption TM-Button-flat-01 rev-btn bg-primary rs-parallaxlevel-1"
                target="_self"
                id="slide-100-layer-7"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['440','451','420','460']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="button"
                data-actions=''
                data-responsive_offset="on"
                data-frames='[
                    {"delay":"+2090","speed":630,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},
                    {"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},
                    {"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(34,34,34);bg:rgb(255,255,255);bs:solid;bw:0 0 0 0;"}
                ]'
                data-margintop="[0,0,0,0]"
                data-marginright="[0,0,0,0]"
                data-marginbottom="[0,0,0,0]"
                data-marginleft="[0,0,0,0]"
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[12,12,12,12]"
                data-paddingright="[30,30,30,30]"
                data-paddingbottom="[12,12,12,12]"
                data-paddingleft="[30,30,30,30]"
                data-fontsize="[18, 18, 18, 18]"
                style={{
                    zIndex: '11',
                    whiteSpace: 'nowrap',
                    color: '#ffffff',
                    letterSpacing: '0.5px',
                    textTransform:'uppercase',
                    borderColor:'rgba(0,0,0,1)',
                    outline:'none',
                    boxShadow:'none',
                    boxSizing:'border-box',
                    MozBoxSizing:'border-box',
                    WebkitBoxSizing:'border-box',
                    textDecoration: 'none'
                }}>
                Our Capabilities
            </Link>
        )
    };

    return(
        <section>
            {/* <!-- LAYER --> */}
            { getlayer() }

            {/* <!-- LAYER NR. 1 --> */}
            { getlayer1() }
        </section>
    )
}

export default Slide