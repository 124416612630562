import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeNavSelected from '../../../../redux/actions/changeNavSelected'
import PageBanner from '../../../common/PageBanner'

const PowerGeneration = props => {

	useEffect(() => {
		props.changeNavSelected(1)
	}, [])

    return (
		<section>
			<PageBanner title='Power Generation' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
			<div className='content-block'>
				<div className='section-full content-inner bg-white'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-6 m-b30'>
								<div className='ol-lg-12 col-sm-12 col-md-12 m-b30  mostrar-img-cell'c>
									<img alt='' src='images\capabilities\industrial\Power_Generation_1.jpg'></img>
								</div>
								<h4>Industry Overview</h4>
								<p style={{ textAlign:'justify'}}>
									In TECHSOURCE, we can provide you with a vast array of personalized industrial services geared towards power generation installations of all sizes: from small facilities, to full fledged power plants.
								</p>

								<h4>Power Plant Construction Services</h4>
								<p style={{ textAlign:'justify'}}>
									Because we offer a highly educated and trained staff, as well as equipment featuring the latest technology, we can say with absolute certainty that we are qualified as an industrial service provider on the market to satisfy, in a personalized manner, all the power plant construction services that you may need. 
								</p>
								<p style={{ textAlign:'justify'}}>
									We have a well researched system developed to offer solutions and answers for all your problems and questions. We strive to be highly useful, so that you obtain high yields for our time (and yours). We focus on the truly problematic issues, after that, we take care of the easiest.
								</p>

								<h4>Maintenance Services</h4>
								<p style={{ textAlign:'justify'}}>
									At your request, on matters of maintenance services, we offer you a list of all the ones we offer. Our goal is to minimize the risks of possible –serious or minor– injuries in your workforce, examining in great detail the condition of all the machinery and parts in close contact to operators, workspaces, schedules that might increase safety risks arising from human error, and so on, one by one.
								</p>
								<p style={{ textAlign:'justify'}}>
									We are driven to embody the professionalism and seriousness of our brand. Once you hire TECHSOURCE, we can execute surprise inspections, wherever you may think there is trouble hiding. This way, we can provide you with all our advice about improvements on workers, machines, workplace and environments.
								</p>

								<h4>Operations and Management Consulting</h4>
								<p style={{ textAlign:'justify'}}>
									TECHSOURCE offers you a system that works for three areas of power generation:
								</p>

								<ul className='list-star red list-box' style={{ padding:'5px'}}>
									<li className="justificar">Operation: To ensure the correct functioning of the machines and harmony between workers, we have a very convenient operations consulting service with strict supervision standards that you can use to increase the efficiency and profitability of your plants organization.</li>
									<li className="justificar">Management: For human resources, we put forward a list of management consulting and support services so workers and your bottom line are protected.</li>
								</ul>

								{/* <div className='row'>
									<div className='col-lg-2 col-md-2 noWidth'>
										<div className='icon-bx-wraper m-b30'>
											<div className='icon-md'>
												<i className='flaticon-settings-2 icon-cell text-primary'></i>
											</div>
										</div>
									</div>
									<div className='col-lg-10 col-md-2 noWidth'>
										<p>Operation: To ensure the correct functioning of the machines and harmony between workers, we have a very convenient operations consulting service with strict supervision standards that you can use to increase the efficiency and profitability of your plants organization.</p>
									</div>

									<div className='col-lg-2 col-md-2 noWidth'>
										<div className='icon-bx-wraper m-b30'>
											<div className='icon-md'>
												<i className='flaticon-settings-2 icon-cell text-primary'></i>												
											</div>
										</div>
									</div>
									<div className='col-lg-10 col-md-2 noWidth'>
										<p>Management: For human resources, we put forward a list of management consulting and support services so workers and your bottom line are protected.</p>
									</div>
								</div> */}

								{/* <h2 className='text-black font-weight-600 m-b15'>Fabrication</h2>
								<p>TECHSOURCE features multiple state-of-the-art fabrication facilities located across the United States. These technologically advanced shops come with hundreds of thousands of square feet of workspace and are staffed by NCCER certified welders, craftsmen, and laborers. TECHSOURCE has also earned ASME’s “U” and “S” stamps because of our unwavering commitment to the highest level of engineering and construction standards. We fabricate a full range of components for power plants:</p>
								<div className='row'>
									<div className='col-lg-2 col-md-2 noWidth'>
										<div className='icon-bx-wraper m-b30'>
											<div className='icon-md'>
												<i className='flaticon-settings-2 icon-cell text-primary'></i>
												<h5 className='dlab-tilte'>Boilers</h5>
											</div>
										</div>
									</div>
									<div className='col-lg-2 col-md-2 noWidth'>
										<div className='icon-bx-wraper m-b30'>
											<div className='icon-md'>
												<i className='flaticon-settings-2 icon-cell text-primary'></i>
												<h5 className='dlab-tilte'>Turbines</h5>
											</div>
										</div>
									</div>
									<div className='col-lg-2 col-md-2 noWidth'>
										<div className='icon-bx-wraper m-b30'>
											<div className='icon-md'>
												<i className='flaticon-settings-2 icon-cell text-primary'></i>
												<h5 className='dlab-tilte'>Piping</h5>
											</div>
										</div>
									</div>
									<div className='col-lg-2 col-md-2 noWidth'>
										<div className='icon-bx-wraper m-b30'>
											<div className='icon-md'>
												<i className='flaticon-settings-2 icon-cell text-primary'></i>
												<h5 className='dlab-tilte'>Lagging</h5>
											</div>
										</div>
									</div>
									<div className='col-lg-2 col-md-2 noWidth'>
										<div className='icon-bx-wraper m-b30'>
											<div className='icon-md'>
												<i className='flaticon-settings-2 icon-cell text-primary'></i>
												<h5 className='dlab-tilte'>Stacks</h5>
											</div>
										</div>
									</div>
									<div className='col-lg-2 col-md-2 noWidth'>
										<div className='icon-bx-wraper m-b30'>
											<div className='icon-md'>
												<i className='flaticon-settings-2 icon-cell text-primary'></i>
												<h5 className='dlab-tilte'>Ducting</h5>
											</div>
										</div>
									</div>
								</div> */}

								<div className='col-lg-12 m-b30 mostrar-img-cell'>
									<img alt='' src='images\capabilities\industrial\Power_Generation_2.jpg'></img>
								</div>
							</div>
							<div className='col-lg-6'>
								<div className='row'>
									<div className='col-lg-12 m-b30 ocultar-img-pc'>
										<img alt='' src='images\capabilities\industrial\Power_Generation_1.jpg'></img>
									</div>

									<div className='col-lg-12 m-b30 ocultar-img-pc'>
										<img alt='' src='images\capabilities\industrial\Power_Generation_2.jpg'></img>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(PowerGeneration)