import React from 'react'
import PageBanner from '../../../common/PageBanner'

const HoistsAndComeLongs = () => {

    return (
                <div className="container max-w900">
                    <div className="blog-post blog-single">
                        <div className="dlab-post-text">
                            <img src="images\capabilities\inspection\hoists_&_come-a-longs.jpg" className="alignleft" width="300"></img>
                            <p style={{ textAlign:'justify'}}>If you seek a wide selection of chain pullers at affordable prices, then you are in the right place. At TECHSOURCE, we offer a large inventory of chain come alongs to help you tackle anything from that one-person at-home product to your most difficult auto shop task. Every great auto shop or garage needs some high-quality chain pullers that can be relied upon no matter what the situation, and we have the perfect items to help you stock your shop.</p>
                            <di>
                                <br></br>
                            </di>
                        </div>
                    </div>
                </div>
    )
}

export default HoistsAndComeLongs