import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect  } from 'react-router-dom'
import login from '../../redux/actions/login'
import { useHistory } from'react-router-dom'
import Constants from '../common/Constants'
import ScrollTopFunction from '../common/ScrollTopFunction'

const Login = props => {

    let [ userName, setUserName ] = useState('')
    let [ pass, setPass ] = useState('')
    let history = useHistory()
    let { Users } = Constants()

    const onSubmit = () => {
        if(validateUsers()) {
            props.login(true)
            history.push('/')
            ScrollTopFunction()
        } else {
            props.login(false)
            alert('User Or Password Invalid')
        }
        clearInputs()
    }

    const validateUsers = () => {
        return Users.find(user => user.name === userName && user.pass === pass)
    }

    const clearInputs = () => {
        setPass('')
        setUserName('')
    }

    if(props.state.loggedIn) {
        return <Redirect to="/" />
    }

    return (
        <div className='dlab-bnr-inr full-height overlay-black-dark' style={{ backgroundImage: 'url(images/login.jpg)' }}>
            <div className='container' style={{ display: 'flex',  alignItems: 'center', height: '550px' }}>
                <div className='col-lg-12'>
                    <div className='col-lg-4 mx-auto'>
                        <div className='baslik'>
                            <img alt='' src='images/LogoTSS1.png' className='baslikImg'></img>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 mx-auto'>
                        <form className='inquiry-form wow box-shadow bg-white' autoComplete='off' onSubmit={ e => { e.preventDefault() } }>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <div className='form-group'>
                                        <div className='input-group'>
                                            <span className='input-group-addon' style={{ width:'20px' }}><i className='ti-user text-primary'></i></span>
                                            <input type='text' value={ userName } onChange={ (e) => setUserName(e.target.value) } className='form-control' placeholder='User Name' required/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-12'>
                                    <div className='form-group'>
                                        <div className='input-group'>
                                            <span className='input-group-addon' style={{ width:'20px' }}><i className='ti-key text-primary'></i></span>
                                            <input type='password' value={ pass } onChange={ (e) => setPass(e.target.value) } className='form-control' placeholder='Password' required/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-12'>
                                    <button className='site-button button-md' onClick={ onSubmit } type='submit' style={{ width:'100%' }}>Enter Site</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { login }

export default connect(mapStateToProps, mapDispatchToProps)(Login)