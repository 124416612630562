import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import changeNavSelected from '../../../../redux/actions/changeNavSelected'
import PageBanner from '../../../common/PageBanner'
import Head from '../../../common/Head'
import LeftMenu from '../../../common/LeftMenu'

import CommercialConstruction from './CommercialConstruction'
import IndustrialConstruction from './IndustrialConstruction'

const Construction = props => {

    useEffect(() => {
        props.changeNavSelected(1)
    }, [])

    const menuConstruction = [{
        name:'Industrial Construction',
        display: true,
        items: [],
        displayItems: false,
        path: '/industrial-construction'
    }, {
        name:'Commercial Construction',
        display: true,
        items: [],
        displayItems: false,
        path: '/commercial-construction'
    }]

    return (
        <section>
            <PageBanner title='Construction' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
            <div className='content-block bg-white'>
                <div className='section-full content-inner'>
                    <div className='container'>
                        <div className='row' style={{ paddingBottom: '8%' }}>
                            <LeftMenu menuOptions={ menuConstruction }></LeftMenu>
                            <div id='contentFabrication' className='col-xl-9 col-lg-8 col-md-7 m-b30'>
                                <Switch>
                                    <Route exact path={['/industrial-construction','/Construction']}>
                                        <Head title='Industrial Construction' page='industrialConstruction'></Head>
                                        <IndustrialConstruction></IndustrialConstruction>
                                    </Route>
                                    <Route exact path={['/commercial-construction']}>
                                        <Head title='Commercial Construction' page='commercialConstruction'></Head>
                                        <CommercialConstruction></CommercialConstruction>
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(Construction)



