import React from 'react'
import Constants from '../common/Constants'
import ScrollTopFunction from '../common/ScrollTopFunction'
import { Link } from 'react-router-dom'

const AboutUs = () => {
	const { carrouselProjects } = Constants()
	const appearFrom = ['fadeInLeft','fadeInDown','fadeInRight','fadeInLeft','fadeInUp','fadeInRight',
						'fadeInLeft','fadeInDown','fadeInRight','fadeInLeft','fadeInUp','fadeInRight']
	const handleClick = () => {
		ScrollTopFunction()
	}
	return (
        <div className='section-full content-inner bg-white'>
			<div className='container'>
				<div className='section-head text-black text-center'>
					<h2 className='title'>Quality With Dedication</h2>
					<p>Techsource Specialized Services LLC provides a wide range of high quality services for industrial firms and facilities as well as industrial production and processing plants and construction sites with a focus on safety and efficiency.</p>
				</div>
				<div className='row'>{
					carrouselProjects.map((item,key) => {
						return(
							<div key={ key } className={ 'col-lg-4 col-md-6 col-sm-12 m-b30 wow ' + appearFrom[key] } data-wow-duration='2s' data-wow-delay='0.3s'>
								<div className='dlab-box service-box-5'>
									<div className='dlab-media radius-sm dlab-img-overlay1'>
										<Link to={ item.path } onClick={ () => handleClick() }><img src={ item.pathImg } alt=''/></Link>
									</div>
									<div className='dlab-info'>
										<div className='icon-bx-sm icon-bx bg-primary icon-up'>
											<Link to={ item.path } onClick={ () => handleClick() } className='icon-cell'><i className={ item.icon }></i></Link>
										</div>
										<h4 className='title'><Link to={ item.path } onClick={ () => handleClick() }>{ item.title }</Link></h4>
										<p>{ item.description }</p>
									</div>
								</div>
							</div>
						)
					})
				} </div>
			</div>
		</div>
    )
}

export default AboutUs
