import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Head from './components/common/Head'
import MainContent from './components/mainContent/MainContent'
import About from './components/pages/about/About'
import TechSourceCompanies from './components/pages/companies/TechSourceCompanies';
import Contact from './components/pages/contact/Contact'
import Training from './components/pages/training/Training';
import Recruiting from './components/pages/recruiting/Recruiting';
import Safety from './components/pages/safety/Safety';
import Capabilities from './components/pages/capabilities/Capabilities';
import Projects from './components/pages/projects/Projects';
import InspectionAndTesting from './components/pages/capabilities/inspectionAndTesting/InspectionAndTesting'
import Industries from './components/pages/capabilities/industries/Industries'
import PowerGeneration from './components/pages/capabilities/industries/PowerGeneration'
import InsulationAndSheetMetal from './components/pages/capabilities/industries/InsulationAndSheetMetal'
import Construction from './components/pages/capabilities/industries/Construction'
import SpecialtyWelding from './components/pages/capabilities/industries/SpecialtyWelding'
import UndergroundConstruction from './components/pages/capabilities/industries/UndergroundConstruction'
import Refining from './components/pages/capabilities/fabrication/Refining'
import Fabrication from './components/pages/capabilities/fabrication/Fabrication'
import Consulting from './components/pages/capabilities/consulting/Consulting'
import Services from './components/pages/capabilities/fabrication/Services';
import SearchResults from './components/pages/searchResults/SearchResults'
import DAS from './components/pages/das'

const Content = props => {

  if (!props.state.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <Switch>
      <Route exact path='/'>
        <Head title='Home' page='home'></Head>
        <MainContent></MainContent>
      </Route>

      <Route exact path='/about'>
        <Head title='About Us' page='about'></Head>
        <About></About>
      </Route>

      <Route exact path='/companies'>
        <Head title='TechSource Companies' page='companies'></Head>
        <TechSourceCompanies></TechSourceCompanies>
      </Route>

      <Route exact path='/training'>
        <Head title='Training' page='Training'></Head>
        <Training></Training>
      </Route>

      <Route exact path='/recruiting'>
        <Head title='Recruiting' page='recruiting'></Head>
        <Recruiting></Recruiting>
      </Route>

      <Route exact path='/safety'>
        <Head title='Safety' page='Safety'></Head>
        <Safety></Safety>
      </Route>

      <Route exact path='/projects'>
        <Head title='Projects' page='projects'></Head>
        <Projects></Projects>
      </Route>

      <Route exact path='/capabilities'>
        <Head title='Capabilities' page='capabilities'></Head>
        <Capabilities></Capabilities>
      </Route>

      <Route exact path='/inspection-and-testing'>
        <Head title='Inspection & Testing' page='inspectionandtesting'></Head>
        <InspectionAndTesting></InspectionAndTesting>
      </Route>

      <Route exact path='/industries'>
        <Head title='Industrial Services' page='industries'></Head>
        <Industries></Industries>
      </Route>

      <Route exact path='/power-generation'>
        <Head title='Power Generation' page='powerGeneration'></Head>
        <PowerGeneration></PowerGeneration>
      </Route>

      <Route exact path='/refining'>
        <Head title='Refining' page='refining'></Head>
        <Refining></Refining>
      </Route>

      <Route exact path='/industrial-insulation'>
        <Head title='Industrial Insulation' page='industrialInsulation'></Head>
        <InsulationAndSheetMetal></InsulationAndSheetMetal>
      </Route>

      <Route exact path={['/construction', '/commercial-construction', '/industrial-construction']}>
        <Construction></Construction>
      </Route>

      <Route exact path='/specialty-welding'>
        <Head title='Specialty Welding' page='specialtyWelding'></Head>
        <SpecialtyWelding></SpecialtyWelding>
      </Route>

      <Route exact path='/underground-construction'>
        <Head title='Underground Construction' page='undergroundConstruction'></Head>
        <UndergroundConstruction></UndergroundConstruction>
      </Route>


      <Route exact path='/fabrication'>
        <Head title='Fabrication Services' page='fabrication'></Head>
        <Services></Services>
      </Route>

      <Route exact path={['/pipe-fabrication', '/mechanical',
        '/full-spectrum-of-sheet-metal-services', '/turnaround-planning-and-execution', '/special-insulation',
        '/custom-pressure-vessels', '/structural-steel', '/process-skid–modular-fabrication',
        '/material-processing', '/project-management', '/strong-budgeting-and-costMonitoring',
        '/tower-And-Vessels', '/heat-exchangers-services', '/maintenance', '/boiler-services', '/refining']}>
        <Head title='All Services' page='fabrication'></Head>
        <Fabrication></Fabrication>
      </Route>

      <Route exact path='/consulting'>
        <Head title='Consulting' page='consulting'></Head>
        <Consulting></Consulting>
      </Route>

      <Route exact path='/contact'>
        <Head title='Contact' page='contact'></Head>
        <Contact></Contact>
      </Route>

      <Route exact path='/search-results'>
        <Head title='Search Result' page='search'></Head>
        <SearchResults></SearchResults>
      </Route>
      <Route exact path='/das'>
        <Head title='Distribution for Antenna Systems' page='das'></Head>
        <DAS />
      </Route>
    </Switch>
  )
}

const mapStateToProps = state => { return state }

export default connect(mapStateToProps)(Content)