import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import FabricationContext from './FabricationContext'

const ProjectManagement = props => {

    let context = useContext(FabricationContext)

    useEffect(() => {
        props.changeIndexAccordion(12)
        context.setBanner({ ...context.banner, title: 'Industrial Services' })
    }, [])

    return (
        <div className='row bg-white' >
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                        <img className='alignright' src='images\capabilities\fabrication\Project_Management.jpg' className='radius-sm' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4 className='title'>Project Management</h4>
                        <p style={{ textAlign:'justify'}}>Industrial Project Management | TECHSOURCE  GroupProject management is one of the most fundamental aspects of any major project in the petrochemical, refinery, and construction industries. Strong leadership and clearly communicated objectives provide the foundation for a successful project. This includes a vision for delivering the expected results in the pre-determined timeline with precisely calculated execution. A few of the integrated Project Management services we provide with include:</p>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='dlab-box'>
                    <div className='dlab-media'>

                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4>Scheduling</h4>
                        <p style={{ textAlign:'justify'}}>One of the most demanding aspects of a large project is often the scheduling of the work itself. Shifts may need to be adjusted due to unexpected delays, extra workers may need to be found, or priorities may abruptly change. TECHSOURCE will oversee the scheduling of workers and employees to handle the project’s scope and monitor the progress throughout completion.</p>
                        <h4>Logistics and Execution</h4>
                        <p style={{ textAlign:'justify'}}>When there are many different phases of a project and large groups of people working on it, it is very easy for the logistics to take on far-reaching proportions. TECHSOURCE will handle these logistics for you as well as oversee the execution of each of the various aspects of the project. We will ensure that each interrelated job is completed as needed for each successive phase to begin.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(ProjectManagement)