import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import FabricationContext from './FabricationContext'

const BoilerServices = props => {

    let context = useContext(FabricationContext)

    useEffect(() => {
        props.changeIndexAccordion(13)
        context.setBanner({ ...context.banner, title: 'Industrial Services' })
    }, [])

    return (
        <div className='row bg-white' >
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                        <img className='alignright' src='images\capabilities\industrial\boiler_services_1.jpg' className='radius-sm' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4 className='title'>Boiler Services</h4>
                        <p style={{ textAlign:'justify'}}>Boiler ServicesWe are your total solutions general contractor for Industrial Boiler Repair, Maintenance and Emergency Services. TECHSOURCE is a boiler retrofitting, fabricating, installation, and shutdown specialist with 24/7 call out.</p>	
                        <p style={{ textAlign:'justify'}}>Our professional service team is dedicated to your specific needs from the moment you call. When you call, we respond! We have earned our valued reputation for safety, quality and production through consistent performance that has allowed us to acquire and retain many satisfied repeat customers.</p>
                        <p style={{ textAlign:'justify'}}>TECHSOURCE Boiler’s first order of business is to listen to your needs and understand the issues at hand. We develop our plan and work procedures according to your specific requirements.</p>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='dlab-box'>
                    <div className='dlab-media'>

                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4>Complete Boiler & Heater Services</h4>
                        <ul className='list-star red list-box customPaddinglist'>
                            <li>All Type Boiler-Heater Repair</li>
                            <li>Boiler Re-tubing and Repairs</li>
                            <li>Boiler tube crack repair</li>
                            <li>Tube replacements</li>
                            <li>Tube Sheet Repairs or Replacement</li>
                            <li>Boiler shell repair</li>
                            <li>Boiler-Heater Headers</li>
                            <li>Fire Tube or Water Tube Boilers</li>
                        </ul>
                        <h4>Complementing On & Off-Site Services Fabrication, Repair, Install of:</h4>
                        <ul className='list-star red list-box customPaddinglist'>
                            <li>Expansion Joints</li>
                            <li>Dust Collectors</li>
                            <li>Bag houses</li>
                            <li>Hangers</li>
                            <li>Scrubbers</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(BoilerServices)