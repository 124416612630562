import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../redux/actions/changeIndexAccordion'
import Constans from '../common/Constants'
import Search from './Search'

const TopBar = props => {

    const { navs, navIndustry } = Constans()

    const topMenu = [{
        ...navs[1]
    }, {
        ...navIndustry
    }, {
        ...navs[6], name: 'Companies'
    }, {
        ...navs[7]
    }, {
        ...navs[8]
    }]

    const handlerClick = (IndexAccordion = 0) => {
        window.$('.sub-menu').slideUp()
        window.$('.sub-menu').slideDown()
        props.changeIndexAccordion(IndexAccordion)
    }

    const getOption = (menuOption, key) => {
        if(menuOption.displayItems && menuOption.topBar) {
            return(
                <li key={ key } className='secondNav' onClick={ e => { e.stopPropagation(); handlerClick() }  }>
                    <Link to={ menuOption.path } className='menu-option' style={{ paddingBottom: '7px', height: '50px' }}> { menuOption.name } <i className='fa fa-chevron-down'></i> </Link>
                    <ul className='sub-menu'> {
                        menuOption.items.map((item,i) => {
                            return( getOption(item,i) )
                        })
                    } </ul>
                </li>
            )
        } else if(menuOption.topBar) {
            return(
                <li key={ key } className='secondNav' onClick={ e => { e.stopPropagation(); props.changeIndexAccordion(handlerClick(menuOption.keyAccordion)) }}>
                    <Link to={ menuOption.path } className='menu-option'> { menuOption.name } </Link>
                </li>
            )
        } else {
            return ''
        }
    }

    return (
        <div className='middle-bar secondNav'>
            <div className='container-fluid' style={{ height: '36px' }}>
                <div className='top-bar total-height'>
                    <div className='container-fluid total-height' style={{ padding: '0' }}>
                        <div className='main-bar-wraper navbar-expand-lg total-height'>
                            <div className='header-nav navbar-collapse total-height'>
                                <ul className='nav' style={{ width: '80%' }}>{
                                    topMenu.map((nav,key) => {
                                        return( getOption(nav,key) )
                                    })
                                }</ul>
                                <Search></Search>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(TopBar)