import React from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../redux/actions/changeIndexAccordion'

const Accordion = props => {
    const { panelsData } = props
    const { indexAccordion } = props.state

    const onClickHandler = key => {
        props.changeIndexAccordion(key)
    }

    return (
        <div className='section-full bg-white content-inner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        
                        <div className='dlab-accordion box-sort-in m-b30 primary space active-bg' id='accordion005'>
                            {
                                panelsData.map((panel,key) => {
                                    return(
                                        <div className='panel' key={ key }>
                                            <div className='acod-head'>
                                                <h6 className='acod-title'>
                                                    <a data-toggle='collapse' aria-expanded='true' data-target={ '#' + panel.id } className={ key !== indexAccordion ? 'collapsed' : '' } onClick={ () => onClickHandler(key) }>{ panel.title }</a>
                                                </h6>
                                            </div>
                                            <div id={ panel.id } className={ key === indexAccordion ? 'acod-body collapse show' : 'acod-body collapse' } data-parent='#accordion005'>
                                                <div className='acod-content'>
                                                    <panel.component></panel.component>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(Accordion)
