import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeNavSelected from '../../../../redux/actions/changeNavSelected'
import PageBanner from '../../../common/PageBanner'

const SpecialtyWelding = props => {

    useEffect(() => {
		props.changeNavSelected(1)
	}, [])

    return (
        <section>
            <PageBanner title='Specialty Welding' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
            <div className='content-block'>
                <div className='section-full content-inner bg-white'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6 m-b30'>

                            <div className='col-lg-6 mostrar-img-cell'>
                                <img className='alignright' src='images\capabilities\fabrication\specialty_welding_1.jpg' className='radius-sm' alt=''></img>
                            </div>

                            <h4>Outsourcing Welding Services</h4>
                            <p className="justificar">
                                In TECHSOURCE we listen carefully to your firm’s business needs as well as it’s technical welding requirements. This ensures that we make our best effort to satisfy all of them in every way possible. Be it welding services, or quality assessment inspections, our goal will always be to give you the most complete personalized service.
                            </p>
                            <p className="justificar">
                                If you are considering why your business should outsource their welding to TECHSOURCE, there are some things you would be interested in knowing: You may be looking for opportunities to reduce the overhead of your project, or to increase the simplicity of your plant setup operations. All of this may be achieved by simply outsourcing your welding services to a specialist contractor like TECHSOURCE.
                            </p>

                            <h4>Certified Welding Inspections</h4>
                            <p className="justificar">
                                TECHSOURCE applies the expertise of a highly qualified team of specialists that can be called upon to supervise all of the aspects of welding that you may need to have inspected, to ensure your safety or welding quality standards. 
                            </p>
                            <p className="justificar">
                                Before starting inspections, our clients can ask TECHSOURCE for advice in relation to the proper weld inspections that may be needed in their facilities. Our team of welding specialists will arrive at the site and conduct the inspections required, delivering a full report of findings and recommendations.
                            </p>


                            <h4>A Large Variety of Welding Techniques</h4>
                        <p className="justificar">
                            TECHSOURCE offers a wide variety of welding techniques to achieve to satisfy most of our client’s demands:
                        </p>

                        <ul className='list-star red list-box' style={{ padding:'5px'}}>
                            <li className="justificar">Gas Tungsten Arc Welding (GTAW): This process uses a tungsten electrode to produce the weld, and because the electrode is not consumed during the welding process, the weld area is protected from atmospheric contamination by an inert gas.</li>
                            <li className="justificar">Submerged Arc Welding (SAW): TECHSOURCE has specialized machinery that feeds immediately, and in a perfectly automatic way, a consumable electrode. This equipment sports a blanket made of granular flux that protects the welding areas by submersion. Materials may vary according to the particular welding requirements.</li>
                            <li className="justificar">Shielded Metal Arc Welding (SMAW): This technique uses an electrode coated in protectant flux. This electrode holds the shield in place and thanks to a direct, or an alternating current, an electric arc is created. </li> 
                            <li className="justificar">Flux-Cored Arc Welding (FCAW): TECHSOURCE makes use of this innovative technique that involves filling a special tubular wire with flux. This wire protects the welding puddle from pollution. </li>
                            <li className="justificar">Gas Metal Arc Welding: TECHSOURCE’s gas metal arc welding equipment feeds a consumable wire electrode through the welding gun. This protects the welding puddle from corrosion. An inert shielding gas, that it’s going to depend on the situation, will be sprayed over the welding puddle.</li> 
                        </ul>

                                    {/* <p style={{ textAlign:'justify'}}>TECHSOURCE provides premium specialty welding and field services. Our highly skilled specialty welders have the metallurgical expertise and field experience it takes to weld on a full range of materials and industrial equipment. While our rigorous quality practices result in weld-rejection rates that average less than 1 percent, Our meticulous planning and scheduling, project management also ensure a smooth, seamless experience for our customers with minimal disruption to the job site.</p>			  
                                    <h4>Specialty Welding On a Full Range of Alloys and Materials</h4>
                                    <p style={{ textAlign:'justify'}}>TECHSOURCE specialty welding services extend to a full range of different metal alloys and materials. We provide superior quality welding services for:</p>          
                                    <ul className='list-star red list-box customPaddinglist'>
                                        <li>Carbon</li>
                                        <li>Titanium</li>
                                        <li>Austenitic Stainless Steel</li>
                                        <li>Nickel Alloys</li>
                                        <li>Manuarite</li>
                                        <li>Ferritic Chromes</li>
                                        <li>Inconel/ Incoloy</li>
                                    </ul>

                                    <h4 >Certified and Quality Controlled</h4>
                                    <p style={{ textAlign:'justify'}}>TECHSOURCE emphasizes quality, safety, and service in everything we do in field construction. All welders are tested under ASME Section IX welding procedures and hold the certifications they need to consistently deliver safe, reliable, efficient results in the field at all different kinds of job sites</p>
                                    <p>Certifications:</p>
                                    <ul className='list-star red list-box customPaddinglist'>
                                        <li>ASME “U” & “S” Stamps</li>
                                        <li>NCCER</li>
                                        <li>NBIC “R” Stamp</li>
                                        <li>API</li>
                                        <li>AWS</li>
                                    </ul> */}
                            </div>

                            <div className='col-lg-6'>
								<div className='row'>
									<div className='col-lg-12 m-b30 ocultar-img-pc'>
										<img alt='' src='images\capabilities\fabrication\specialty_welding_1.jpg'></img>
									</div>

									<div className='col-lg-12 m-b30 ocultar-img-pc'>
										<img alt='' src='images\capabilities\fabrication\Specialty_Welding_2.jpg'></img>
									</div>
								</div>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(SpecialtyWelding)