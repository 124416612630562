import React from 'react'

const Cards = ({ cards }) => {
    return(
        cards.map((card,key) => {
            return(
                <div key={ key } className='col-lg-4 col-md-4 service-box style3'>
                    <div className='icon-bx-wraper' data-name={ card.name } style={ { height: '91%' } }>
                        <div className='icon-lg'>
                            <a className='icon-cell'><i className={ card.icon }></i></a>
                        </div>
                        <div className='icon-content'>
                            <h2 className='dlab-tilte'>{ card.tittle }</h2>
                            <p>{ card.description }</p>
                        </div>
                    </div>
                </div>
            )
        })
    )
}

export default Cards
