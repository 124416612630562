import React from 'react'

const MisionAndValues = () => {
    return (
        <section>
            <div className='section-full about-8-service bg-secondry p-t40 p-b40'>
                <div className='container'>
                    <div className='icon-bx-wraper'>
                        <div className='text-white text-center p-l10 p-r10 p-t10 p-b10'>
                            <div className=' clearfix'>
                                <h2 className='title'>Our Mission </h2>
                            </div>
                            <div className='dlab-separator-outer'>
                                <div className='dlab-separator bg-primary style-skew'></div>
                            </div>
                            <p>Our mission is to provide personalized industrial construction and maintenance services, attending to all requests from our clients regarding everything they might need. All this, while offering a wide range of capabilities: Industrial, fabrication, consulting and SME services, and inspection and testing.</p>
                        </div>
                        <div className='section-content'></div>
                    </div>
                </div>
            </div>
            <div className='section-full about-8-service bg-primary p-t40 p-b40'>
                <div className='container'>
                    <div className='icon-bx-wraper'>
                        <div className='text-white text-center p-l10 p-r10 p-t10 p-b10'>
                            <div className=' clearfix'>
                                <h2 className='title'>Our Values: Quality and reliability </h2>
                            </div>
                            <div className='dlab-separator-outer'>
                                <div className='dlab-separator style-skew' style={{ backgroundColor:'#f2ae2b' }}></div>
                            </div>
                            <p>We focus our marketing on quality. As a matter of fact, for example, we have industrial insulation facilities like refineries, power plants: natural gas, ethanol, chemical industries, tank farms, pharmaceutical facilities, pipe racks and aircraft production plants among our long standing clients because of our reliable delivery of high quality services across the board.</p>
                        </div>
                        <div className='section-content'></div>
                    </div>
                </div>
            </div>
            <div className='section-full about-8-service bg-secondry p-t40 p-b100'>
            <div className='container'>
                <div className='icon-bx-wraper'>
                    <div className='text-white text-center p-l10 p-r10 p-t10 p-b10'>
                        <div className=' clearfix'>
                            <h2 className='title'>Our Values: Safety </h2>
                        </div>
                        <div className='dlab-separator-outer'>
                            <div className='dlab-separator bg-primary style-skew'></div>
                        </div>
                        <p>TechSource is fully committed to providing a safe and healthy work environment for our employees, and stakeholders as well as the communities in which they operate.</p>
                        <p>We consider it our duty to provide this appropriate work environment, as well as maximum efficiency. Safety takes precedence, always above all other business matters. We always emphasize prevention, but we realize that mistakes will happen, so we make sure we have covered all the bases to minimize any potential damage. Safety is the most important element of who we are and is always at the top of our priorities.</p>
                    </div>
                    <div className='section-content'></div>
                </div>
            </div>
        </div>
        </section>
    )
}

export default MisionAndValues
