import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import FabricationContext from './FabricationContext'

const StructuralSteel = props => {

    let context = useContext(FabricationContext)

    useEffect(() => {
        props.changeIndexAccordion(6)
        context.setBanner({ ...context.banner, title: 'Fabrication Services' })
    }, [])

    return (
        <div className='row bg-white' >
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                        <img className='alignright' src='images\capabilities\fabrication\structuralsteel.jpg' className='radius-sm' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4 className='title'>Structural Steel</h4>
                        <p style={{ textAlign:'justify'}}>TECHSOURCE provides comprehensive structural steel and plate fabrication services. From our early beginnings as a sheet metal fabricator, grew the largest segment of our business model.</p>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='dlab-box'>
                    <div className='dlab-media'>

                    </div>
                    <div className='dlab-info m-t30 '>
                        <p style={{ textAlign:'justify'}}>We can handle any shape or size. This is one of our core specialties and uniquely qualifies and equips us to handle any job, large or small. With  indoor fabrication facilities exceeding 150,000 SF, we can tackle the large complex fabrication commonly associated with capital projects using our state of the art fabricating facilities. We have the in-house capacity and diverse industrial capabilities to execute our fabrication process like few construction companies can. With years of industrial expertise we offer you a comprehensive range of fabrication and manufacturing services, unrivaled in the industry, Qualified with ASME U, S and NBIC R stamps we perform your projects with national and world wide accepted credibility and certifications. We are also licensed with the coveted API 4F Monogram that certifies that we have passed rigorous auditing of processes and procedures that entitles us to produce API Certified masts, derricks and substructures. We manufacture, construct complete land based drillings rigs, including drilling rig mast, derrick, sub structure, mud systems, water tanks, engine skids, jacking systems, wind walls and all interconnection piping spool systems.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(StructuralSteel)

