import React from 'react'
import PageBanner from '../../../common/PageBanner'

const CraneAndRiggingSpecialist = () => {

    return (
        <div class="container max-w900">
            <div class="blog-post blog-single">
                <div class="dlab-post-text">
                    <img src="images\capabilities\consulting\Crane_Rigging_Specialist.jpg" class="alignleft" width="500"></img>
                    <p style={{ textAlign:'justify'}}>We operate a modern fleet of cranes with a highly experienced rigging team on hand to complete the most demanding of lifting projects.</p>
                    <p style={{ textAlign:'justify'}}>TECHSOURCE Services offer a specialist crane and rigging division supported by a modern fleet of cranes with lifting capacities ranging from 25 tonnes – 250 tonnes. Our highly experienced team of crane operators and riggers specialise in all types of crane lifts, working with structural engineering teams around Australia to plan and execute high risk, critical lifts. We can mobilise our cranes and personnel to any location in Australia including remote areas.</p>
                    <div className="row">
                        <div className=" col-md-6">
                            <h4>Our Capability</h4>
                            <ul class="list-circle primary m-a0">
                                <li>Deconstruction and dismantling</li>
                                <li>Heritage lifting</li>
                                <li>Asbestos roof removal and roof reinstatement</li>
                                <li>Static Line System installation</li>
                                <li>Specialist lifting and rigging services including; jacking and skating boilers and air handling units</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <h4>Our cranes</h4>
                            <ul className="list-circle primary m-a0 ">
                                <li>25 tonne Franna</li>
                                <li>60 tonne Tadano</li>
                                <li>100 tonne Grove</li>
                                <li>200 tonne Liebherr</li>
                                <li>250 tonne Liebherr</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CraneAndRiggingSpecialist