import React, { useEffect } from "react";
import { connect } from "react-redux";
import changeNavSelected from "../../../redux/actions/changeNavSelected";
import PageBanner from "../../common/PageBanner";
import Cards from "../../common/Cards";

const Training = (props) => {
  useEffect(() => {
    props.changeNavSelected(5);
  }, []);

  const trainings = [
    {
      name: "NCCCO (Certification of Crane Operators)",
      icon: "",
    },
    {
      name: "NCCER Rigger/Signal Person Training/Certification – Plus over 70 Craft Training & Certifications",
      icon: "",
    },
    {
      name: "Mobile Crane Operator, OSHA Compliance Inspector, & Preventative Maintenance ",
      icon: "",
    },
    {
      name: "Overhead Crane Operator, OSHA Compliance Inspector, & Preventative Maintenance  ",
      icon: "",
    },
    {
      name: "Rigging (Basic Rigging to 40hr Master Rigging), OSHA Compliance Inspector, & Preventative Maintenanc",
      icon: "",
    },
    {
      name: "Forklift & Aerial Lift ",
      icon: "",
    },
    {
      name: "Earth Moving Equipment ",
      icon: "",
    },
    {
      name: "OSHA Compliance Specialty Training i.e.",
      icon: "",
    },
    {
      name: "Custom Training Courses Available upon request",
      icon: "",
    },
  ];

  const cards = [
    {
      nameCard: "01",
      title: "Investing in Human Resources and Workforce Education",
      description: "In Techsource we made a strong commitment to workplace education, with courses and certifications, and continuous improvement being a cornerstone of our human resource management philosophy and policies.",
      icon: "flaticon-robot-arm",
    },
    {
      nameCard: "02",
      title: "The importance of training at work",
      description: "A constant investment in human resource acquisition is part of the mix, but it is workforce education that breeds the loyalty of your employees. Every so often, it depends on the area that they work, and as soon as they join the company, it is paramount to update their knowledge on current technology and business practices. A leading business in any field needs to stay ahead of the curve when it comes to the technological advances that appear day by day and disrupts the status quo affecting the way we work.",
      icon: "flaticon-factory",
    },
    {
      nameCard: "03",
      title: "Competitiveness through training and education",
      description: "We know that workers that do not receive proper training and education in the workplace become outdated in their abilities and lax in their work habits. Techsource can be your, and your employee’s most valuable ally in keeping them up-to date with current standards and ensuring the competitiveness of your human resources.",
      icon: "flaticon-settings",
    },
  ];

  return (
    <section>
      <PageBanner title="Recruiging" img={{ name: "bannerJobs", type: "jpg" }}></PageBanner>
      <div className="content-block">
        <div className="section-full content-inner bg-white">
          <div className="container">
            <div className="row align-items">
              <div className="col-lg-9 col-md-12 mb-5">
                <div>
                  <h4>Quality Practices</h4>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 bg-primary">
                <div className="max-w700 m-auto p-tb30 p-lr20">
                  <div className='text-white'>
                    <h4>Featured Opportunities</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = { changeNavSelected };

export default connect(mapStateToProps, mapDispatchToProps)(Training);
