import React from 'react'
import Constants from '../common/Constants'

const ContactHeader = () => {
    const { contactInformation } = Constants();
    return (
        <div className='service-list'>
            <ul>
                <li>
                    <i className='la la-phone'></i>
                    <h4 className='title'>{ contactInformation.phone }</h4>
                    <span>Call Us</span>
                </li>
                <li>
                    <i className='la la-clock-o'></i>
                    <h4 className='title'>{ contactInformation.schedule }</h4>
                    <span>Monday - Friday</span>
                </li>
                <li>
                    <i className='la la-map'></i>
                    <h4 className='title'>{ contactInformation.addressPart1 }</h4>
                    <span>{ contactInformation.addressPart2 }</span>
                </li>
            </ul>
        </div>
    )
}

export default ContactHeader
