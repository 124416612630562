import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import FabricationContext from './FabricationContext'

const MaterialProcessing = props => {

    let context = useContext(FabricationContext)

    useEffect(() => {
        props.changeIndexAccordion(8)
        context.setBanner({ ...context.banner, title: 'Fabrication Services' })
    }, [])

    return (
        <div className='row bg-white'>
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                        <img className='alignright' src='images\capabilities\fabrication\materialprocessing.jpg' className='radius-sm' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4 className='title'>Material Processing</h4>
                        <p style={{ textAlign:'justify'}}>We provide complete contract manufacturing services, specializing in small to large run production work. Plasma cutting is often a cost -effective alternative for high volume production jobs. Whatever your project scope we can promptly complete your product and have it shipped to you for much less than conventional tooling costs.</p>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='dlab-box'>
                    <div className='dlab-media'>

                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4>Processing Capabilities</h4>
                        <p style={{ textAlign:'justify'}}>TECHSOURCE offers  Material Processing that includes steel and alloy base materials. Our shop can provide services of plate burning, beveling, shearing, drilling, rolling, bending, forming, plasma burning, split T, CNC drilling, coping, cutting, blanking & slitting.</p>
                        <p style={{ textAlign:'justify'}}>We utilize modernized state-of-the-art equipment to be able to offer services with the latest automated technology in pre-notching, pre-punching, cut-to-length, and post-fabricating combined with the cut-to-length operation. Serving manufacturing, machine shops, fabricators, steel service centers, welding shops, contractors. No minimum order is required.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(MaterialProcessing)