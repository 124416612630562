import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import FabricationContext from './FabricationContext'

const TurnaroundPlanningAndExecution = props => {

    let context = useContext(FabricationContext)

    useEffect(() => {
        props.changeIndexAccordion(3)
        context.setBanner({ ...context.banner, title: 'Fabrication Services' })
    }, [])

    return (
        <div className='row bg-white' >
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                        <img className='alignright' src='images\capabilities\fabrication\TurnaroundPlanningAndExecution.jpg' className='radius-sm' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4 className='title'>Turnaround Planning & Execution</h4>
                        <p style={{ textAlign:'justify'}}>Delivery times are complex and difficult to manage, require large capital outlays and years of planning, and even a small failure can put operators at risk. An hour or a day lost during a closure can cost millions of dollars, so it is essential to execute it effectively in all phases of a change.</p>	
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                        <img className='alignright' src='images\capabilities\fabrication\TurnaroundPlanningAndExecution2.jpg' className='radius-sm' alt=''></img>
                    </div>
                    {/* <div className='dlab-media'> </div> */}
                    <div className='dlab-info m-t30 '>
                        <p style={{ textAlign:'justify'}}>TECHSOURCE, you can offer an intuitive application that streamlines response tasks and provides a central platform for project intelligence.</p>
                        <ul className='list-star red list-box customPaddinglist'>
                            <li>Automate response tasks to reduce non-productive time and finalize paper tracking</li>
                            <li>Integrate existing systems and data to reduce complexity.</li>
                            <li>Increase collaboration and show a complete audit history of who is doing what, when and why</li>
                            <li>Integrate with SAP, Oracle Primavera, Microsoft and more</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(TurnaroundPlanningAndExecution)