import React from 'react'
import Constants from '../common/Constants'
const { socialNetworks } = Constants()

const SubscribeNewsletter = () => {
    return (
        <div className='col-md-12 col-xl-5 col-lg-4 col-sm-8 footer-col-4'>
            <div className='widget'>
            <h5 className='m-b30 text-white'>Subscribe To Our Newsletter</h5>
            <p className='text-capitalize m-b20'>TECHSOURCE SPECIALIZED SERVICES has a proven history of providing high-quality maintenance and installation services.</p>
            <div className='subscribe-form m-b20'>
                <form className='dzSubscribe' action='script/mailchamp.php' method='post'>
                <div className='dzSubscribeMsg'></div>
                <div className='input-group'>
                    <input name='dzEmail' required='required' type='email' className='form-control' placeholder='Your Email'/>
                    <span className='input-group-btn'>
                    <button name='submit' value='Submit' type='submit' className='site-button radius-xl'>Subscribe</button>
                    </span>
                </div>
                </form>
            </div>
            <ul className='list-inline m-a0'> {
                socialNetworks.map((item,key) => {
                    if(item.active) {
                        return <li key={ key }><a href={ item.path } className={ 'site-button ' + item.classNameButton + ' circle' }><i className={ item.classNameIcon }></i></a></li>
                    }
                })
            } </ul>
            </div>
        </div>
    )
}

export default SubscribeNewsletter
