import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import FabricationContext from './FabricationContext'

const ProcessSkidModularFabrication = props => {

    let context = useContext(FabricationContext)

    useEffect(() => {
        props.changeIndexAccordion(7)
        context.setBanner({ ...context.banner, title: 'Fabrication Services' })
    }, [])

    return (
        <div className='row bg-white' >
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                        <img className='alignright' src='images\capabilities\fabrication\processskid.jpg' className='radius-sm' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4 className='title'>Process Skid Modular Fabrication</h4>
                        <h6>Providing Process Equipment Skid & Modular Fabrication Internationally</h6>
                        <p style={{ textAlign:'justify'}}>TECHSOURCE can deliver turnkey services including design, engineering, modeling, fabrication, assembly, painting, insulation, heat tracing, electrical and instrumentation. With industry trends leaning to more “shop” oriented and less “field” oriented project execution, it only made sense for TECHSOURCE to mesh our core competencies to provide modular and skid services for our customers. We are only limited to overall module dimensions allowed to freight by rail and truck.</p>	
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='dlab-box'>
                    <div className='dlab-media'>

                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4>TECHSOURCE -Fabrication serves the following industries:</h4>
                        <ul className='list-star red list-box customPaddinglist'>
                            <li>Petrochemica;</li>
                            <li>Chemical</li>
                            <li>Refining</li>
                            <li>Oil & Gas Production</li>
                            <li>Drilling</li>
                            <li>Energy</li>
                            <li>Power Generation</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(ProcessSkidModularFabrication)

