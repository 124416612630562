import React from 'react'
import PageBanner from '../../../common/PageBanner'

const OnSitePartyLiaison = () => {

    return (
        <div className="container max-w900">
            <div className="blog-post blog-single">
                <div className="dlab-post-text">
                <h2>What is Third Party Management</h2>
                    <img src="images\capabilities\fabrication\TurnaroundPlanningExecution700x500.jpg" className="alignleft" width="500"></img>
                    <p className="justificar">Third Party Risk Management is the process of analysing and controlling risks presented to your company, your data, your operations and your finances by parties OTHER than your own company.</p>
                    <p className="justificar">TECHSOURCE can assist in managing supplier / vendor relationships and third-party risks to help mitigate undue risk and excessive costs associated with cyber risks. Vendor policy management starts at the very beginning of the relationship by making sure that businesses and management build security as a fundamental pillar in having a healthy relationship. We also assist in the decision making for new software to be integrated into the systems as some software is not compatible with certain environments and/or software packages.</p>
                    <p className="justificar">Too many times we have seen third parties vendors and suppliers having open access to the network and making changes without anyone knowing what was happening and therefore disrupting the core business of the client. We assist in managing access and access to what parts of the network, while ensuring the access granted is secure and if agreed upon, access is only granted on a need basis.</p>
                    <p className="justificar">We work well with all 3rd party suppliers to every client. That relationship is key for our client to have trust in their security, uptime, performance and issue resolution should an issue arise, being from software issues, internet issues, phone issues or any other issues that relate to your IT environment.</p>
                    <div className="row">
                        <div className=" col-md-6">
                            <h4>Some issues we have assisted in resolving is:</h4>
                            <ul class="list-circle primary m-a0">
                                <li>Phone / Mobile plans</li>
                                <li>Internet plans</li>
                                <li>Website and hosting issues</li>
                                <li>Email issues</li>
                                <li>Software user licenses</li>
                                <li>Microsoft license audit resolutions</li>
                                <li>General disputes with third parties</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnSitePartyLiaison