import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import FabricationContext from './FabricationContext'

const TowerAndVessels = props => {

    let context = useContext(FabricationContext)

    useEffect(() => {
        props.changeIndexAccordion(11)
        context.setBanner({ ...context.banner, title: 'Industrial Services' })
    }, [])

    return (
        <div className='row bg-white' >
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                        <img className='alignright' src='images\capabilities\fabrication\processskid.jpg' className='radius-sm' alt=''></img>
                    </div>
                    
                    <div className='dlab-info m-t30 '>
                        <h4>Tower and Vessel Fabrication</h4>
                        <p style={{ textAlign:'justify'}}>
                            TECHSOURCE could be your outsourcing partner when it comes to tower and vessel fabrication services. Outsourcing Tower and Vessel fabrication, provides a swift alternative to on-site fabrication that reduces costs as well as the complexity of plant setup and outfitting projects.
                            Making use of TECHSOURCE services may allow your company to access new and higher quality fabrication technology as well, which might provide further benefits in relation to speed and efficiency.
                        </p>

                        <h4>Storage Tanks</h4>
                        <p style={{ textAlign:'justify'}}>
                            TECHSOURCE provides fabrication and maintenance services for tanks and vessels used in the storage of organic, and inorganic, corrosive and non-corrosive liquids and vapors. We listen to your requests closely,
                             in order to understand exactly what type of storage tank you may need, the size, the thickness of the container walls, the diameter of input and output ports, and so on. 
                        </p>
                        
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                    <div className='dlab-media'>
                        <img className='alignright' src='images\capabilities\fabrication\TowerAndVessel.jpg' className='radius-sm' alt=''></img>
                    </div>
                <div className='dlab-box'>
                    <div className='dlab-info m-t30 '>
                    <p style={{ textAlign:'justify'}}>
                            Our storage tanks might sport  fully integrated spill and leakage protection as well as insulation and temperature control systems. 
                        </p>
                        <p style={{ textAlign:'justify'}}>
                            In fact, TECHSOURCE offers eight types of tanks to meet most of our client’s requirements: Fixed roofs. External floating roofs, internal floating roofs, domed external floating roofs, horizontal, pressure, variable vapor space, LNG (liquified natural gas).
                        </p>
                    
                    
                    <h4>Towers for Wind Power Generation</h4>
                        <p style={{ textAlign:'justify'}}>
                            Wind power generation converts the kinetic energy of wind into electric energy without putting the environment in danger. This eco-friendly alternative to more traditional power generation plants, 
                            has enjoyed an exponential boom in recent years, requiring fast and efficient tower fabrication and setup services as it will require maintenance services to keep this power plants in operation for years to come. The wind turbine towers produced by TECHSOURCE are a benchmark for strength and reliability.
                        </p>

                        <h4>High Pressure Vessels</h4>
                        <p style={{ textAlign:'justify'}}>
                            TECHSOURCE produces high pressure vessels employing different materials like carbon steel, stainless steel, nickel-based alloys, and other weldable alloys, thanks to our expertise in the latest tower and vessel fabrication technology.
                        </p>

                        {/* <h4>Our Tower and Vessel Service Advantage</h4>
                        <p >TECHSOURCE routinely performs the following for towers and vessels:</p>
                        <ul className='list-star red list-box customPaddinglist'>
                            <li> Retraying Entire Towers</li>
                            <li>Vessel Fabrication</li>
                            <li>General Repairs</li>
                            <li>Upgrades</li>
                            <li>Modifications</li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(TowerAndVessels)