import React from 'react'
import PageBanner from '../../../common/PageBanner'

const RiggingHardware = () => {

    return (
        <div class="container max-w900">
            <div class="blog-post blog-single">
                <div class="dlab-post-text">
                    <img src="images\capabilities\inspection\RiggingHardware.jpg" class="alignleft" width="300"></img>
                    <p style={{ textAlign:'justify'}}>Your Rigging Hardware Solution is Here. We know the importance of quality when it comes to rigging hardware. Whether you're in the construction, logging, excavating, marine, or dredging industry, trust our rigging hardware to get the job done safely and correctly.</p>
                    <p style={{ textAlign:'justify'}}>With teams of dedicated product and industry experts, TECHSOURCE has the knowledge and resources to help you and your business grow and succeed.</p>
                    <br></br>
                    <br></br>
                    <h4>Rigging Hardware Material</h4>
                    <p style={{ textAlign:'justify'}}>Material options for rigging hardware includes galvanized steel, cast-iron, and more. For marine rigging applications, stainless steel hardware will better resist corrosion and severe weather.</p>
                    <h4>Rigging Hardware Options</h4>
                    <p style={{ textAlign:'justify'}}>Connect lifting slings, wire rope, and chain with shackles. Adjust the tension in steel cables, tie rods, and more with quality turnbuckles. Reduce required lifting force with lifting blocks and pulleys. Easily splice and seal wire rope with rigging sleeves and stops. Anchor your rigging assembly with eye bolts in a variety of styles and finishes.</p>
                </div>
            </div>
        </div>
    )
}

export default RiggingHardware