import React from 'react'
import PageBanner from '../../../common/PageBanner'

const OverheadCranes = () => {

    return (
        <div class="container max-w900">
            <div class="blog-post blog-single">
                <div class="dlab-post-text">
                    <img src="images\capabilities\inspection\overhead_cranes.jpg" class="alignleft" width="300"></img>
                    <p style={{ textAlign:'justify'}}>Spans up to 150 feet & capacities up to 200 Tons
                    Our specialty and passion is for overhead cranes. One of the most practical ways to update or streamline your facility and material handling is through the introduction of an overhead crane system. These systems are reliable, economical, and safe.
                    </p>
                    <div className="row">
                        <div className=" col-md-6">
                            <h4>Single Girder</h4>
                            <ul class="list-circle primary m-a0">
                                <li>Capacities typically up to 10 tons</li>
                                <li>Economical for smaller applications</li>
                                <li>Spans up to 60ft with rolled steel</li>
                                <li>Spans up to 100ft with box girders</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                        <h4>Double Girder</h4>
                        <ul class="list-circle primary m-a0">
                                <li>Higher capacity</li>
                                <li>More lift height for available clear height</li>
                                <li>Capacities of 5 tons and up</li>
                                <li>Spans up to 150ft</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        /*
        <div class="">
            <div class="container max-w900">
                <div class="blog-post blog-single">
                    <div class="dlab-post-text">
                        <img src="images\capabilities\inspection\overhead_cranes.jpg" class="alignleft" width="300"></img>
                        <p style={{ textAlign:'justify'}}>Spans up to 150 feet & capacities up to 200 Tons
                        Our specialty and passion is for overhead cranes. One of the most practical ways to update or streamline your facility and material handling is through the introduction of an overhead crane system. These systems are reliable, economical, and safe.
                        </p>
                        <div className="row">
                            <div className=" col-md-6">
                                <h4>Single Girder</h4>
                                <ul class="list-circle primary m-a0">
                                    <li>Capacities typically up to 10 tons</li>
                                    <li>Economical for smaller applications</li>
                                    <li>Spans up to 60ft with rolled steel</li>
                                    <li>Spans up to 100ft with box girders</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                            <h4>Double Girder</h4>
                            <ul class="list-circle primary m-a0">
                                    <li>Higher capacity</li>
                                    <li>More lift height for available clear height</li>
                                    <li>Capacities of 5 tons and up</li>
                                    <li>Spans up to 150ft</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */
    )
}

export default OverheadCranes