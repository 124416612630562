import React, { useState } from 'react'
import { connect } from 'react-redux'
import searchText from '../../redux/actions/searchText'
import { useHistory } from'react-router-dom'

const Search = props => {
    const history = useHistory()
    let [ textSearh, setTextSearh  ] = useState('')

    const onChange = e => {
        setTextSearh(e.target.value)
    }

    const search = () => {
        props.searchText(textSearh)
        setTextSearh('')
        history.push("/search-results")
    }

    const searchWithEnter = e => {
        if(e.keyCode == 13){
            search()
        }
    }

    return (
        <div className="wrap">
            <div align="right" className='search'>
                <input name='textSearh' value={ textSearh } onChange={ onChange } onKeyDown={ searchWithEnter } type='text' className='searchTerm' placeholder='What are you looking for?'/>
                <button onClick={ search } className="searchButton"><i className="fa fa-search"></i></button>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { searchText }

export default connect(mapStateToProps,mapDispatchToProps)(Search)
