import React from 'react'
import Constants from '../../common/Constants'

const ContactInfo = () => {
    const { contactInformation } = Constants()

    return (
        <div className='col-lg-4 col-md-12 text-white'>
            <div className='row'>
                <div className='col-lg-12 col-md-12 m-b30'>
                    <div className='icon-bx-wraper bx-style-1 p-a20 radius-sm'>
                        <div className='icon-content'>
                            <h5 className='dlab-tilte'>
                                <span className='icon-sm text-primary'><i className='ti-location-pin'></i></span> 
                                Company Address
                            </h5>
                            <p>{ contactInformation.address }</p>
                            <h6 className='m-b15 text-black font-weight-400'><i className='ti-alarm-clock'></i> Office Hours</h6>
                            <p className='m-b0'>{ 'Mon To Sat - ' + contactInformation.schedule }</p>
                            <p>Sunday - Close</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 col-md-6 m-b30'>
                    <div className='icon-bx-wraper bx-style-1 p-a20 radius-sm'>
                        <div className='icon-content'>
                            <h5 className='dlab-tilte'>
                                <span className='icon-sm text-primary'><i className='ti-email'></i></span> 
                                E-mail
                            </h5>
                            <p className='m-b0'>{ contactInformation.email }</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 col-md-6 m-b30'>
                    <div className='icon-bx-wraper bx-style-1 p-a20 radius-sm'>
                        <div className='icon-content'>
                            <h5 className='dlab-tilte'>
                                <span className='icon-sm text-primary'><i className='ti-mobile'></i></span>
                                Phone Numbers
                            </h5>
                            <p className='m-b0'>{ contactInformation.phone }</p>
                            <p className='m-b0'>{ contactInformation.cel }</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo
