import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'
import FabricationContext from './FabricationContext'

const FullSpectrumOfSheetMetalServices = props => {
    let context = useContext(FabricationContext)

    useEffect(() => {
        props.changeIndexAccordion(2)
        context.setBanner({ ...context.banner, title: 'Fabrication Services' })
    }, [])

    return (
        <div className='row bg-white' >
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                    <img className='alignright' src='images\capabilities\fabrication\FullSpectrumofSheetMetalServices.jpg' className='radius-sm' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4>Sheet Metal Fabrication</h4>
                        <p style={{ textAlign:'justify'}}>
                            TECHSOURCE fabricates different kinds of sheet metal parts and devices, applying a wide range of fabrication techniques. According to the needs of an industrial plant, power generation facility or architectural project,  we customize the production process. 
                        </p>	
                        <p style={{ textAlign:'justify'}}>
                        We have an extensive experience in all the techniques that we use in sheet metal fabrication like: bending, punching, pressing and stretching. This turns TECHSOURCE into an ideally flexible provider for sheet metal fabrication outsourcing services, as well as a dependable partner that can take care of servicing for sheet metal bending, punching, pressing and stretching machinery.
                        </p>		  
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                    <img className='alignright' src='images\capabilities\fabrication\FullSpectrumofSheetMetalServices2.jpg' className='radius-sm' alt=''></img>
                    </div>
                    <div className='dlab-info m-t30 '>
                        <h4>Sheet Metal Fabrication Outsourcing Services</h4>
                            <p style={{ textAlign:'justify'}}>
                                TECHSOURCE has some of the best sheet metal fabrication machinery in use today. This provides us with a competitive advantage that makes outsourcing of sheet metal fabrication a convenient, reliable and cost-effective alternative to the often cumbersome on-site fabrication of the sheet metal parts and devices required for all kinds of industrial facilities or construction sites.
                            </p>	
                            <p style={{ textAlign:'justify'}}>
                                Our friendly staff will make sure that your instructions are closely followed in the fabrication process, to ensure a quality product that fulfills our client’s expectations. 
                            </p>

                        <h4>Sheet Metal Fabrication Machinery Servicing</h4>
                            <p style={{ textAlign:'justify'}}>
                                TECHSOURCE can provide your company with servicing services for a diverse array of sheet metal fabrication machines. Our clients may even use our deep knowledge of this machinery, obtained through years of experience in servicing this equipment, to request adjustments or customization of said machinery, to attend to the specific, constantly changing needs, of a dynamic production facility. This is integrated with TECHSOURCE’s benchmark personalized service, with the goal to be at your disposition at all times.
                            </p>	
                        {/* <h4>Industrial Custom Sheet Metal Fabrication Includes The Following Products</h4>
                        <ul className='list-star red list-box customPaddinglist'>
                            <li>Carbon steel, Stainless steel, Aluminum Fabrication</li>
                            <li>Base units to support equipment</li>
                            <li>Baskets</li>
                            <li>Belt guards</li>
                            <li>Bins</li>
                            <li>Blower housings</li>
                            <li>Cabinets for tools and storage</li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(FullSpectrumOfSheetMetalServices)