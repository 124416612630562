import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import './App.css';
import store from './redux/store'

import Header from './components/header/Header'
import Content from './Content'
import Footer from './components/footer/Footer'
import LoadingArea from './components/common/LoandingArea'
import Scroltop from './components/common/Scroltop'
import LeftButton from './components/common/LeftButton';
import NotFound from './components/common/NoFound'
import Login from './components/login/Login'
import Constants from './components/common/Constants'
const { navs } = Constants()

// const Constants = React.createContext({Constants});
const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path={getArrayPaths(navs)}>
            <LoadingArea></LoadingArea>
            <Header></Header>
            <Content></Content>
            <LeftButton></LeftButton>
            <Footer></Footer>
            <Scroltop></Scroltop>
          </Route>
          <Route exact path='/login'>
            <LoadingArea></LoadingArea>
            <Login></Login>
          </Route>
          <Route path='*'>
            <LoadingArea></LoadingArea>
            <NotFound></NotFound>
          </Route>
        </Switch>
      </Router>
    </Provider>
  )
}

const getArrayPaths = (navs) => {
  let arrayPaths = navs.reduce((acc, curr) => {
    if (curr.items && curr.items.length > 0) {
      return [...acc, curr.path, ...getArrayPaths(curr.items)]
    }
    return [...acc, curr.path]
  }, [])
  return [...arrayPaths, '/construction', '/commercial-construction', '/search-results']
}

export default App