import React from 'react'
import PageBanner from '../../common/PageBanner'
import Content from './Content'

const About = (props) => {
    return (
        <div className='bg-white'>
            <PageBanner title='Distribution for Antenna Systems' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
            <Content {...props}></Content>
        </div>
    )
}

export default About