import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../../../redux/actions/changeIndexAccordion'

const IndustrialConstruction = props => {

    useEffect(() => {
        props.changeIndexAccordion(0)
    }, [])

    return (
        <div className='row bg-white' >
            <div className='col-lg-6 col-md-12 m-b30'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                        <div className="col-md-12 ">
                            <img className="alignright radius-sm" src="images\capabilities\industrial\industrial_construction_1.jpg"  width="300" alt=""></img>
                        </div>
                    </div>
                    <div className='dlab-info m-t30 '>
                        {/* <h4 className='title'>Industry Overview:</h4> */}
                        <h4>Industry Overview:</h4>
                        <p className="justificar">
                            TECHSOURCE brings everything you need in terms of industrial construction services. From it’s beginning to its conclusion, we provide all manner of support services for the construction of industrial facilities. 
                            Personalized services, delivered by a highly trained crew that makes use of the latest technologies, to help you in building from small production plants to whole factories.
                        </p>
                        <h4>Industrial Construction Services:</h4>
                        <p className="justificar">
                            We offer:
                        </p>

                        <ul className='list-star red list-box' style={{ padding:'5px'}}>
                            <li className="justificar">Personalized services: Because our goal is to adapt to the different needs of the diverse areas of our company. Instead of having a rigid structure of services, we are able to provide the specific solution for exactly what your business needs.</li>
                            <li className="justificar">Latest technology: Thanks to our knowledge and experience in different industries in the global market, we make sure to provide you with experience on the servicing and maintenance of the latest industrial and fabrication technology.
                                You should not be left behind, by the wave of technological innovations, you should ride it, and we are here to help.</li>
                            <li className="justificar">Highly skilled staff: Our crew is divided in different areas: management, maintenance, installation, consulting, and others. In each of our areas, we demand, from the job applicants, certain studies and the commitment to take seriously all the incoming courses that we will bring them and their place in our company.</li>  
                        </ul>
                        {/* <h4>With TECHSOURCE as your construction partner you can expect:</h4>
                        <ul className="list-star red list-box customPaddinglist">
                            <li>Experienced project leadership for dependable execution</li>
                            <li>Industry-leading quality, health, safety and environment management systems for incident-free and high-quality project execution</li>
                            <li>Proven, effective tools and systems for project management and reporting</li>
                            <li>Proactive personnel recruitment, training, and retention programs that ensure top quality personnel.</li>
                            <li>Full Service General Contractor</li>
                        </ul> */}
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12'>
                <div className='dlab-box'>
                    <div className='dlab-media'>
                    <div className="col-md-12 ">
                            <img className="alignright radius-sm" src="images\capabilities\industrial\industrial_construction_2.jpg"  width="300" alt=""></img>
                        </div>
                    </div>

                    <div className='dlab-info m-t30 '>
                        <h4>Maintenance Services for Industrial Machinery:</h4>
                        <p style={{ textAlign:'justify'}}>
                            In TECHSOURCE, we offer a comprehensive maintenance service to ensure the correct functioning of all your industrial machines, tools, piping, storage vessels, and all sorts of equipment.  
                        </p>
                        <p style={{ textAlign:'justify'}}>
                            Our maintenance services are customized to fit your industrial installation needs. According to the kinds and intensity of the servicing that your machines and equipment require to meet performance and safety specifications,
                            we plan in advance a maintenance schedule. This maximizes your equipment’s uptime, and minimizes the risk of malfunction and the need for often pricy emergency maintenance.
                        </p>
                        <p style={{ textAlign:'justify'}}>
                            Careful maintenance is a safe investment. It prevents future accidents and malfunctions that can derive from serious injuries for workers, and high expenses for the bottom line.
                        </p>
                        <p style={{ textAlign:'justify'}}>
                            In order to design the maintenance plan for your installation, we may conduct interviews with managers. We will also need to make a tour through the plant to identify all the maintenance costs, estimate the difficulty of work, and if you want, provide you with suggestions regarding how to improve your maintenance schedule keeping safety, performance and profit in mind. 
                        </p>

                        {/* <h4>Full Service General Contractor:</h4>
                        <ul className="list-star red list-box customPaddinglist">
                            <li>EPC</li>
                            <li>General Construction Services</li>
                            <li>Construction Management</li>
                            <li>Self-Perform Capabilities</li>
                            <li>Flexible Commercial Approach</li>
                            <li>Licensed in 27 States</li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(IndustrialConstruction)